<div class="standard-input" [ngClass]="{'error': markAsInvalid}">
  <input [ngClass]="{'has-value': !!value}" [(ngModel)]="value" [type]="type" [id]="fakeId"
         [maxlength]="maxlength"
         [disabled]="isDisabled"
         [attr.aria-invalid]="markAsInvalid"
         [attr.aria-errormessage]="errorMessageId ?? fakeErrorMessageId"
         [attr.tabindex]="tabIndex"
         [attr.autocomplete]="autoComplete"
         [attr.aria-disabled]="isDisabled"
         (ngModelChange)="onChange($event)" (blur)="onTouch()"/>

  <label [for]="fakeId">{{fieldName}}
    <span [id]="!errorMessageId ? fakeErrorMessageId : errorMessageId">
      {{(markAsInvalid && errorText && value) || (markAsInvalid && errorText && forceToShowErrorMessageWithNoValue) ? '/ ' + errorText : ''}}
    </span>
  </label>
</div>
