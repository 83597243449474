import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {firstValueFrom, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RestService {
  private apiBaseUrl: string = environment.apiUrl;
  private httpClient = inject(HttpClient);

  public async post<T>(apiPath: string, requestObject?: Object): Promise<T> {
    const options = {withCredentials: true};

    return firstValueFrom(this.httpClient.post<T>(this.apiBaseUrl + apiPath, requestObject, options));
  }

  public postObs<T>(apiPath: string, requestObject?: Object): Observable<T> {
    const options = {withCredentials: true};

    return this.httpClient.post<T>(this.apiBaseUrl + apiPath, requestObject, options);
  }

  // public async delete<T>(apiPath: string, requestObject?: Object): Promise<T> {
  //   return this._changeRequest<T>(apiPath, requestObject, 'delete');
  // }
  //
  // public async put<T>(apiPath: string, requestObject?: Object): Promise<T> {
  //   return this._changeRequest<T>(apiPath, requestObject, 'put');
  // }
  //
  // public async patch<T>(apiPath: string, requestObject?: Object): Promise<T> {
  //   return this._changeRequest<T>(apiPath, requestObject, 'patch');
  // }
  //
  // public async get<T>(apiPath: string, params?: HttpParams): Promise<T> {
  //   const options = {withCredentials: true, params: params};
  //   return <T> await this.httpClient.get(this.apiBaseUrl + apiPath, options).toPromise();
  // }

  public externalGet<T>(apiPath: string, params?: HttpParams): Observable<T> {
    // const options = {withCredentials: true, params: params};
    return this.httpClient.get<T>(apiPath);
  }

  // private async _changeRequest<T>(apiPath: string, requestObject: Object, requestType: string): Promise<T> {
  //   const options = {withCredentials: true};
  //   return <T>await this.httpClient[requestType](this.apiBaseUrl + apiPath, requestObject, options).toPromise();
  // }
}
