import {NgModule} from "@angular/core";
import {CarouselDirective} from "@directives/carousel/carousel.directive";
import {CarouselEventsService} from "@directives/carousel/carousel-events.service";
import {CarouselSlideDirective} from "@directives/carousel/carousel-slide.directive";

@NgModule({
  declarations: [CarouselDirective, CarouselSlideDirective],
  exports: [CarouselDirective, CarouselSlideDirective],
  providers: [CarouselEventsService]
})
export class CarouselModule {
}
