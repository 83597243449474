<div id="gdpr__email__capture" class="modal-container" *ngrxLet="vm$ as vm">
  <div class="modal-content">
    <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

    <ng-container *ngIf="vm.stage === 'ASK'">
      <h2 class="modal-header-title">Where would you like your data sent?</h2>

      <form class="form-container" (ngSubmit)="handleSubmit(emailInput, vm)">
        <st-input [formControl]="emailInput" fieldName="ENTER EMAIL"></st-input>

        <div class="buttons-container">
          <button class="btn red-button confirm" type="submit" [disabled]="vm.requestInProgress">
            SUBMIT
          </button>
        </div>
      </form>
    </ng-container>

    <div class="confirmed" *ngIf="vm.stage === 'CONFIRMED'">
      <h2>Confirmed!</h2>

      <p>
        Great news we have received your request, expect to hear from us within 72 hours.
      </p>
    </div>
  </div>

  <emusic-loader-spinner *ngIf="vm.requestInProgress" [enableOpacity]="true" [height]="'100%'"></emusic-loader-spinner>
</div>
