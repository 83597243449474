import {DeviceType} from "../../../device/protocol/DeviceType";
import {Platform} from "../../../device/protocol/Platform";
import { GeneralResponse } from '../../protocol/GeneralResponse';

export class GetDeviceListResponse extends GeneralResponse {
  currentDevice: Device;
  deviceList: Device[];
}

export interface Device {
  id: number;
  deviceId: string;
  name: string;
  deviceType: DeviceType;
  platform: Platform;
  creationDate: Date;
  version: string;
}
