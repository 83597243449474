<mat-dialog-content>
  <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

  <ng-container *ngIf="!fullScreen">
    <div class="nft_details_modal">
      <div class="title">{{nftItem.collectible.name}}</div>

      <div class="divider"></div>

      <div class="nft_image_wrapper">
        <div class="fullscreen_hover_wrapper" (click)="showFullscreen()"></div>

        <img class="nft_details_modal_image" imageLoadAnimation
             [ngSrc]="nftItem.collectible.imageList[0].imageUrl | customImgUrl: {format:'GIF'}"
             width="500"
             height="500"
             [alt]="'NFT name: ' + nftItem.collectible.name">
      </div>

      <button class="btn red-button nft_details_modal_button"
              type="button"
              (click)="downloadNft()">
        DOWNLOAD YOUR NFT
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="fullScreen">
    <div class="fullscreen_nft_modal">
      <img imageLoadAnimation
           [ngSrc]="nftItem.collectible.imageList[0].imageUrl | customImgUrl: {width:500, format:'GIF'}"
           width="500"
           height="500"
           [alt]="'NFT name: ' + nftItem.collectible.name">
    </div>
  </ng-container>
</mat-dialog-content>
