import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable()
export class CarouselEventsService {
  private sliders = new Subject<number>();

  listenForSliders(): Observable<number> {
    return this.sliders.asObservable();
  }

  changeSlider(index: number): void {
    this.sliders.next(index);
  }
}
