import {Injectable} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {SnackBarComponent} from "./snack-bar.component";

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private snackBar: MatSnackBar) {
  }

  dismissCurrentSnackbar(): void {
    this.snackBar.dismiss();
  }

  showNotificationBanner(message: string, type?: string[] | string, stickyNote?: boolean, timeout: number = 7000, item?: any, action?: Function) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      panelClass: type,
      duration: stickyNote ? 0 : timeout,
      data: {
        body: message,
        type: type,
        item: item,
        action: action
      }
    });
  }

  showBlueNotificationBanner(message: string, stickyNote?: boolean, timeout?: number, item?: any, action?: Function) {
    this.showNotificationBanner(message, 'blue', stickyNote, timeout, item, action);
  }

  showErrorNotificationBanner(message: string, stickyNote?: boolean, timeout?: number) {
    this.showNotificationBanner(message, 'error', stickyNote, timeout);
  }
}
