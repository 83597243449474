<label [for]="id" class="check-button" [style.flex-direction]="labelOrder === 'INVERSE' ? 'row-reverse' : 'normal'"
       fakeButton overrideRole="checkbox" [attr.aria-checked]="!!finalValue"
       [style.justify-content]="spaceBetween ? 'space-between' : 'normal'" [style.column-gap.px]="marginBetween">
  <span class="check-container" [style.height.px]="size" [style.width.px]="size"
        [attr.aria-labelledby]="'checkbox-label-' + id">
    <input type="checkbox" [id]="id" [(ngModel)]="finalValue" (ngModelChange)="onChange($event)" tabindex="-1"/>

    <span class="outer-circle" [style.border-radius.px]="borderRadius" [ngClass]="[borderColor]"
          [style.border-width.px]="borderWidth">
      <span class="inner-circle" [ngClass]="[checkBoxFill]" [style.--customHeight.px]="size" [style.--customWidth.px]="size"></span>
    </span>
  </span>

  <span class="check-label" [id]="'checkbox-label-' + id">
    <ng-content></ng-content>
  </span>
</label>
