import { createFeatureSelector, createSelector } from "@ngrx/store";
import { Web3State } from "@files-ngrx/states/web3.state";

const feature = createFeatureSelector<Web3State>('web3');

export const selectWeb3 = createSelector(feature, state => state);
export const selectWalletList = createSelector(feature, ({walletList}) => walletList);
export const selectNotConnectedWallet = createSelector(feature, ({notConnectedWalletAddress}) => notConnectedWalletAddress);
export const selectWalletError = createSelector(feature, ({walletError}) => walletError);
export const selectWallet = createSelector(feature, ({wallet}) => wallet);
export const selectHasConnectedWallet = createSelector(feature, ({hasConnectedWallet}) => hasConnectedWallet);
export const selectHasExposedAccount = createSelector(feature, ({hasExposedWeb3Account}) => hasExposedWeb3Account);
