import { ComponentStore } from '@ngrx/component-store';
import { inject, Injectable } from '@angular/core';
import {
  NftPurchaseModalState, NftPurchaseModalViewModel
} from '@public-pages/nft/nft-details/modals/nft-purchase-modal/store/nft-purchase-modal.state';
import { exhaustMap, switchMap, tap } from 'rxjs/operators';
import { PurchaseStep, PurchaseType } from '@public-pages/nft/protocol/PurchaseFlow';
import { catchError, combineLatestWith, EMPTY, interval, Observable, of } from 'rxjs';
import {
  selectHasExposedAccount,
  selectNotConnectedWallet,
  selectWallet,
  selectWalletError
} from '@files-ngrx/selectors/web3.selectors';
import { selectIsSignedInUser, selectUserLocation } from '@files-ngrx/selectors/user.selectors';
import { Store } from '@ngrx/store';
import { Web3Service } from '@services/web3.service';
import { SnackBarService } from '@components/snack-bar/snack-bar.service';
import { BillingService } from '@services/billing.service';
import { NftService } from '@public-pages/nft/nft.service';
import { GetCollectibleLiteDetailsResponse } from '@public-pages/nft/protocol/GetCollectibleDetailsResponse';
import {
  selectNoPaymentMethodSaved,
  selectSavedPaymentDetails
} from '@files-ngrx/selectors/billing.selectors';
import { MatDialogRef } from '@angular/material/dialog';
import { GetPurchaseAddressResponse } from '@services/protocol/GetPurchaseAddressResponse';
import { TransactionConfig } from 'web3-core';

interface SelectCurrentStep {
  purchaseStep: PurchaseStep | undefined;
  nftDetails?: GetCollectibleLiteDetailsResponse;
  purchaseType?: PurchaseType;
}

interface InitializeModel {
  nftDetails: GetCollectibleLiteDetailsResponse;
  purchaseStep?: PurchaseStep;
  purchaseType?: PurchaseType;
  purchasePending: boolean;
}

@Injectable()
export class NftPurchaseModalStore extends ComponentStore<NftPurchaseModalState> {
  private readonly billingService = inject(BillingService);
  private readonly nftService = inject(NftService);
  private readonly store = inject(Store);
  private readonly web3Service = inject(Web3Service);
  private readonly snackBarService = inject(SnackBarService);
  private dialogRef: MatDialogRef<any>;

  constructor() {
    super();

    this.listenForWalletErrorsFx();
  }

  public initializeStore(payload: InitializeModel, dialogRef: MatDialogRef<any>): void {
    this.dialogRef = dialogRef;

    this.setState({
      purchaseAddressDetails: null,
      requestInProgress: false,
      currentPurchaseStep: payload.purchaseStep ?? PurchaseStep.SELECTION,
      countdown: {m: '', s: ''},
      cryptoCountdownZeroed: false,
      purchasePending: false,
      finalNftCryptoPrice: '',
      purchaseType: payload.purchaseType ?? PurchaseType.SIMPLE,
      errorMessagesArray: [],
    });
  }

  readonly listenForWalletErrorsFx = this.effect((empty$: Observable<void>) => empty$.pipe(
    exhaustMap(() => this.store.select(selectWalletError).pipe(
      tap((walletError) => {
        let errorMessagesArray: string[] = [];

        if (!walletError) {
          return;
        }

        const errorMessage = typeof walletError === 'string' ?
          this.web3Service.getWalletError(walletError)
          : this.web3Service.getWalletError(walletError.reasonCode ?? '', walletError.networkName);

        errorMessagesArray.push(errorMessage);

        this.patchState({errorMessagesArray});
      })
    )),
  ));

  readonly selectCurrentStepFx = this.effect((payload$: Observable<SelectCurrentStep>) => payload$.pipe(
    switchMap(({purchaseStep, purchaseType, nftDetails}) => {

      return this.store.select(selectHasExposedAccount).pipe(
        combineLatestWith(
          this.store.select(selectIsSignedInUser),
          this.store.select(selectNoPaymentMethodSaved),
        ),
        tap(([hasExposedWeb3Account, isSignedIn, noPaymentMethodSaved]) => {
          if (!isSignedIn) {
            this.dialogRef.close();

            return;
          }

          switch (purchaseStep) {
            case PurchaseStep.SELECTION:
              this.patchState({currentPurchaseStep: PurchaseStep.SELECTION});
              break;

            case PurchaseStep.CRYPTO_PURCHASE:
              if (!this.web3Service.hasMetaMaskInstalled()) {
                this.web3Service.showGetDappBrowserModal();
              } else {
                if (hasExposedWeb3Account) {
                  this.patchState({currentPurchaseStep: PurchaseStep.CRYPTO_PURCHASE});

                  if (nftDetails) {
                    this.refreshPurchaseAddressFx(nftDetails);
                  }

                  return;
                }

                this.snackBarService.showErrorNotificationBanner('Please sign in to your Metamask account');
              }

              break;
            case PurchaseStep.FIAT_PURCHASE:
              if (noPaymentMethodSaved) {
                this.handleNoPaymentMethodSaved({nftDetails, purchaseStep, purchaseType});
                this.dialogRef.close();
              }

              this.patchState({currentPurchaseStep: PurchaseStep.FIAT_PURCHASE});
              break;
          }
        })
      );
    }),
  ));

  readonly handleNoPaymentMethodSaved = this.effect((empty$: Observable<SelectCurrentStep>) => empty$.pipe(
    switchMap(({purchaseStep, purchaseType, nftDetails}) => this.billingService.openUpdatePaymentModal().pipe(
      tap(result => {
        if (!result || !nftDetails) {
          return;
        }

        this.nftService.openCollectiblePurchaseModal(nftDetails, purchaseStep, purchaseType);
      })
    )),
  ));

  readonly refreshPurchaseAddressFx = this.effect((payload$: Observable<GetCollectibleLiteDetailsResponse>) => payload$.pipe(
    switchMap(({collectible}) => {
      this.patchState({requestInProgress: true});

      return this.nftService.getPurchaseAddress(collectible.id, 'ETH').pipe(
        tap(response => {
          if (!response.successful) {
            return;
          }

          let finalNftCryptoPrice = '';

          if (response.payableAmount) {
            finalNftCryptoPrice = response.payableAmount.toFixed(6);
          }

          this.patchState({
            requestInProgress: false,
            purchaseAddressDetails: response,
            finalNftCryptoPrice,
          });

          this.startCryptoPurchaseCountdownFx(response);
        })
      );
    })
  ));

  readonly startCryptoPurchaseCountdownFx = this.effect((payload$: Observable<GetPurchaseAddressResponse>) => payload$.pipe(
    switchMap(({expirationDate, currentTime}) => {
      const countDownDate = expirationDate;
      let now = currentTime;

      return interval(1000).pipe(
        switchMap((value) => {
          now = now + 1000;

          if (now > countDownDate) {
            this.patchState({
              cryptoCountdownZeroed: true,
              requestInProgress: false,
              countdown: {
                m: '00',
                s: '00'
              }
            });

            return EMPTY;
          }

          let distance = countDownDate - now;
          let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          let seconds = Math.floor((distance % (1000 * 60)) / 1000);

          this.patchState({
            cryptoCountdownZeroed: false,
            requestInProgress: false,
            countdown: {
              m: ('0' + minutes).slice(-2),
              s: ('0' + seconds).slice(-2),
            }
          });

          return of(value);
        }),
      );
    })
  ));

  readonly purchaseCollectibleFx = this.effect((payload$: Observable<{
    vm: NftPurchaseModalViewModel,
    nftDetails: GetCollectibleLiteDetailsResponse
  }>) => payload$.pipe(
    switchMap(({vm, nftDetails}) => {
      const {requestInProgress} = vm;


      if (requestInProgress) {
        return EMPTY;
      }

      this.patchState({requestInProgress: true, errorMessagesArray: []});

      return this.nftService.purchaseCollectibleRequest(nftDetails.collectible.id).pipe(
        tap((response) => {
          if (!response.successful) {
            const errorMessagesArray = [];

            errorMessagesArray.push(
              !response.billingError ?
                this.billingService.getPurchaseError(response.responseText)
                : this.billingService.getPaymentError(response.billingError));

            this.patchState({errorMessagesArray, requestInProgress: false});

            return;
          } else {
            this.patchState({requestInProgress: false});
            this.nftService.notifyRefreshCollectibleDetails();
            this.nftService.openCollectiblePurchaseSuccessModal(nftDetails.collectible);
            this.dialogRef.close();
          }
        })
      );
    })
  ));

  readonly sendEthTransactionFx = this.effect((payload$: Observable<{
    vm: NftPurchaseModalViewModel,
    nftDetails: GetCollectibleLiteDetailsResponse
  }>) => payload$.pipe(
    switchMap(({vm, nftDetails}) => {
      const {purchasePending, purchaseAddressDetails, wallet} = vm;

      if (purchasePending) {
        return EMPTY;
      }

      return this.web3Service.checkWeb3ConnectionObs(true).pipe(
        switchMap(web3Connection => {
          if (!(web3Connection !== undefined && web3Connection.message === 'WALLET_READY')) {
            return EMPTY;
          }

          return this.web3Service.getGasPriceInGWEI().pipe(
            switchMap((gasPriceInGWEI: any) => {
              const payload: TransactionConfig = {
                from: wallet?.address,
                to: purchaseAddressDetails?.address,
                value: this.web3Service.toWei(purchaseAddressDetails?.payableAmount + ''),
                gasPrice: gasPriceInGWEI.standard * 1000000000,
              };

              return this.web3Service.sendTransaction(payload);
            }),
            tap(response => {
              if (response) {
                this.nftService.setPendingNftPurchaseMap(nftDetails.collectible.id, true);
                this.setPendingPurchase(true);
                this.nftService.openNftPurchaseInProgressModal([purchaseAddressDetails?.address || '']);
                this.nftService.notifyRefreshCollectibleDetails();
                this.dialogRef.close();
              }
            })
          );
        }),
      );
    }),
    catchError(() => {
      this.snackBarService.showErrorNotificationBanner(`Something went wrong!`);

      return EMPTY;
    })
  ));

  public setPendingPurchase(purchasePending: boolean): void {
    this.patchState({purchasePending});
  }

  readonly selectViewModel: Observable<NftPurchaseModalViewModel> = this.select(
    this.store.select(selectWallet),
    this.store.select(selectIsSignedInUser),
    this.store.select(selectHasExposedAccount),
    this.store.select(selectNotConnectedWallet),
    this.store.select(selectUserLocation),
    this.store.select(selectSavedPaymentDetails),
    this.select(state => state),
    ((wallet, isSignedIn, hasExposedAccount, notConnectedWallet, location, paymentDetails, state) => {
      return {
        ...state,
        hasExposedAccount,
        location,
        isSignedIn,
        notConnectedWallet,
        wallet,
        paymentDetails
      };
    })
  );
}
