import { inject, Injectable } from '@angular/core';
import { RestService } from '../core/ws/rest.service';
import { HandleSignInResponse, OnboardingModalProps, SignInResponse } from './protocol/SignInResponse';
import { environment } from '../../environments/environment';
import { Data, Router } from '@angular/router';
import { GetUserDetailsResponse, UpdateUserEmailPreferencesProps } from './protocol/GetUserDetailsResponse';
import { UtmService } from './utm.service';
import { DeviceService } from '../device/device.service';
import { OnboardingModalComponent } from './onboarding/onboarding-modal/onboarding-modal.component';
import { OnboardingStage } from './onboarding/protocol/OnboardingStage';
import { concatMap, take, tap } from 'rxjs/operators';
import { UserMessageType } from '../message-queue/protocol/UserMessageType';
import { GeneralResponse } from '../core/protocol/GeneralResponse';
import { LocationResponse } from './protocol/location-response';
import { first, map, Observable, of, Subject, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserActions } from '@files-ngrx/actions/user.actions';
import { GetAvailableRewardResponse } from '../core/protocol/GetAvailableRewardResponse';
import { GetDeviceListResponse } from '@services/protocol/GetDeviceListResponse';
import { Dialog } from '@angular/cdk/dialog';
import { ChangePasswordModalComponent } from '@components/dialog/change-password-modal/change-password-modal.component';
import { selectRouteData } from '@files-ngrx/selectors/router.selectors';
import { EmailCaptureModalComponent } from '@components/dialog/email-capture-modal/email-capture-modal.component';
import { DeleteAccountComponent } from '@public-pages/privacy-center-page/delete-account/delete-account.component';
import { selectIsSignedInUser } from '@files-ngrx/selectors/user.selectors';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private restService = inject(RestService);
  private router = inject(Router);
  private utmService = inject(UtmService);
  private cdkDialog = inject(Dialog);
  private store = inject(Store);

  private onSignOut = new Subject<void>();
  private onSignIn = new Subject<void>();

  public checkSignInIfNotShowModalFlow(stage: OnboardingStage): Observable<boolean> {
    return this.store.select(selectIsSignedInUser).pipe(
      first(),
      switchMap((isSignedIn) => {

        return isSignedIn ? of(isSignedIn) : this.openOnboardingModal({stage}).pipe(first());
      }),
    );
  }

  public observeSignInAction(): Observable<void> {
    return this.onSignIn.asObservable();
  }

  public observeSignOutAction(): Observable<void> {
    return this.onSignOut.asObservable();
  }

  public notifySignIn(): void {
    this.onSignIn.next();
  }

  public notifySignOut(): void {
    this.onSignOut.next();
  }

  public getUserDetails(): Observable<GetUserDetailsResponse> {
    return this.restService.postObs<GetUserDetailsResponse>('account/getUserDetails');
  }

  public getUserLocation(): Observable<LocationResponse> {
    return this.restService.postObs<LocationResponse>('account/getLocation', {});
  }

  public getAvailableRewardList(): Observable<GetAvailableRewardResponse> {
    return this.restService.postObs<GetAvailableRewardResponse>('rewards/getAvailableRewardList', {});
  }

  public getDeviceList(): Observable<GetDeviceListResponse> {
    return this.restService.postObs<GetDeviceListResponse>('device/getDeviceList');
  }

  public disconnectDevice(deviceId: string): Observable<GeneralResponse> {
    return this.restService.postObs<GeneralResponse>('device/disconnectDevice', {deviceId});
  }

  public updatePassword(currentPassword: string, newPassword: string): Observable<GeneralResponse> {
    return this.restService.postObs<GeneralResponse>('account/updatePassword', {currentPassword, newPassword});
  }

  public updateUserEmailPreferences(preferences: UpdateUserEmailPreferencesProps): Observable<GeneralResponse> {
    return this.restService.postObs<GeneralResponse>('account/updateUserEmailPreferences', preferences);
  }

  public signInWithFacebookRequest(rememberMe: boolean): Promise<SignInResponse> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      window['FB'].getLoginStatus((r) => {
        if (r.status !== 'connected') {
          // @ts-ignore
          return window['FB'].login((r) => {
            if (!r.authResponse) {
              return;
            }

            this.handleFBLogin(r, reject, rememberMe, resolve);
          }, {scope: 'public_profile, email'});
        } else {
          this.handleFBLogin(r, reject, rememberMe, resolve);
        }
      });
    });
  }

  private handleFBLogin(response: any, reject: (reason?: any) => void, rememberMe: boolean, resolve: (value: (PromiseLike<SignInResponse> | SignInResponse)) => void) {
    if (!response.authResponse) {
      reject();
    }

    const requestObject = {
      accessToken: response.authResponse.accessToken,
      device: DeviceService.getDevice(),
      rememberMe: rememberMe,
      originPath: this.router.url,
    };

    if (this.utmService.getUtmQueryParams()) {
      let utmRequestObj = this.utmService.getUtmQueryParams();
      Object.assign(requestObject, utmRequestObj);
    }

    this.restService.post<SignInResponse>('/account/signInWithFacebook', requestObject).then(serverResponse => {
      resolve(serverResponse);
    });
  }

  async signInWithGoogleRequest(rememberMe: boolean): Promise<SignInResponse> {
    let googleResponse;
    try {
      // @ts-ignore
      googleResponse = await window['auth2'].signIn({
        'app_package_name': environment['googleAppPackageId'],
        // @ts-ignore
        'scope': environment['googleScope']
      });
    } catch (e) {
      throw Error('Please make sure you have cookies enabled');
    }

    const authResponse = googleResponse.getAuthResponse();

    const requestObject = {
      token: authResponse.access_token,
      tokenType: 'ACCESS_TOKEN',
      device: DeviceService.getDevice(),
      originPath: this.router.url,
      rememberMe: rememberMe,
    };

    if (this.utmService.getUtmQueryParams()) {
      let utmRequestObj = this.utmService.getUtmQueryParams();
      Object.assign(requestObject, utmRequestObj);
    }

    return this.restService.post<SignInResponse>('/account/signInWithGoogle', requestObject);
  }

  async signInWithAppleRequest(rememberMe: boolean): Promise<SignInResponse> {
    const host = window.location.host;
    const REDIRECT_API_URL = `https://${host}/appSignIn`;

    // @ts-ignore
    window['AppleID'].auth.init({
      clientId: environment['appleClientId'],
      scope: environment['appleScopes'],
      redirectURI: REDIRECT_API_URL,
      usePopup: true
    });

    // @ts-ignore
    const data = await window['AppleID'].auth.signIn();
    let code = data.authorization.code;
    let firstName;
    let lastName;

    if (data.user) {
      firstName = data.user.name.firstName;
      lastName = data.user.name.lastName;
    }

    const requestObject = {
      code: code,
      clientId: environment['appleClientId'],
      firstName: firstName,
      lastName: lastName,
      device: DeviceService.getDevice(),
      rememberMe: rememberMe
    };

    if (this.utmService.getUtmQueryParams()) {
      let utmRequestObj = this.utmService.getUtmQueryParams();
      Object.assign(requestObject, utmRequestObj);
    }

    return this.restService.post<SignInResponse>('/account/signInWithApple', requestObject);
  }

  public openOnboardingModal(props: OnboardingModalProps): Observable<boolean> {
    const {panelClass, title, stage, passwordUpdated} = props;

    return this.cdkDialog.open(OnboardingModalComponent, {
      width: '100%',
      closeOnNavigation: true,
      closeOnDestroy: true,
      maxWidth: '396px',
      panelClass: panelClass ? ['modal', ...panelClass] : 'modal',
      data: {stage, title, passwordUpdated}
    }).closed as Observable<boolean>;
  }

  public beginFreemiumSignUp(): Observable<boolean> {
    this.store.dispatch(UserActions.setFreemiumFlow({isFreemiumSignUp: true}));
    return this.openOnboardingModal({stage: OnboardingStage.SIGN_UP, title: 'SIGN UP TO EMUSIC'});
  }

  public openChangePasswordModal(): void {
    this.cdkDialog.open(ChangePasswordModalComponent);
  }

  public getSignInErrorMessage(message: string, socialPlatform?: string): string {
    let errorMessage = '';

    if (message.startsWith('CAPTCHA_')) {
      return this.getCaptchaErrorMessage(message);
    }

    switch (message) {
      case 'INTERNAL_ERROR':
        errorMessage = 'Unable to login due to an internal error. We apologize for the inconvenience, please try again.';
        break;
      case 'INVALID_EMAIL':
        errorMessage = 'Incorrect Email. The email you entered doesn\'t appear to belong to an account.  Please try again.';
        break;
      case 'USER_NOT_FOUND':
        errorMessage = 'Password does not match username. Please try again.';
        break;
      case 'INCORRECT_PASSWORD':
        errorMessage = 'Incorrect Password. The password you entered does not match our records.' +
          ' Please try again or reset password.';
        break;
      case 'ACCOUNT_DELETED':
        errorMessage = 'Your account has been suspended. Please contact support at support@emusic.com for more information';
        break;
      case 'DEVICE_LIMIT_EXCEEDED':
        errorMessage = 'You have exceeded the limit on the number of devices that can access this account.';
        break;
      case 'INCORRECT_ACCESS_TOKEN':
        if (socialPlatform) {
          errorMessage = 'Unable to connect ' + socialPlatform + ' account. Please try again.';
        }
        break;
      default:
        errorMessage = message ? message : 'Internal Error';
        break;
    }

    return errorMessage;
  }

  public getCaptchaErrorMessage(responseText: string): string {
    let errorMessage;

    switch (responseText) {
      case 'CAPTCHA_INVALID_TOKEN':
      case 'CAPTCHA_TIMEOUT_OR_DUPLICATE':
      case 'CAPTCHA_INTERNAL_ERROR':
      default:
        errorMessage = 'Something went wrong while authenticating user.';
    }

    return errorMessage;
  }

  public getSignUpErrorMessage(_responseText: string): string {
    let errorMessage = '';

    if (_responseText.startsWith('CAPTCHA_')) {
      return this.getCaptchaErrorMessage(_responseText);
    }

    switch (_responseText) {
      case 'INTERNAL_ERROR' :
        errorMessage = 'We are unable to complete your sign up due to an internal error.' +
          ' We apologize for the inconvenience, please try again.';
        break;
      case 'INVALID_EMAIL':
        errorMessage = 'Please enter a valid email address.';
        break;
      case 'INVALID_PASSWORD' :
        errorMessage = 'Minimum 6 characters is required!';
        break;
      case 'INVALID_FULL_NAME':
        errorMessage = '‘Please enter a valid name';
        break;
      case 'INVALID_COUNTRY_CODE':
        errorMessage = 'Invalid country code, please try again.';
        break;
      case 'USER_ALREADY_EXISTS':
        errorMessage = 'This email is in use on an existing account. Please sign in or use a different email';
        break;
    }

    return errorMessage;
  };

  requestSignOut(): Observable<SignInResponse> {
    return this.restService.postObs<SignInResponse>('/account/signOut').pipe(
      concatMap((response) => {
        return this.store.select(selectRouteData).pipe(
          tap((routeData: Data) => {
            const isPageLocked: boolean = routeData['locked'] ?? false;

            if (isPageLocked) {
              this.router.navigate(['/']);
            }

            this.notifySignOut();

            // this.messageQueueService.stopListening();
            this.store.dispatch(UserActions.resetUserdetailsstate());
            this.store.dispatch(UserActions.getUserDetails());
            this.store.dispatch(UserActions.setEligibleForMembershipBonus({eligibleForMembershipBonus: true}));

          }),
          map(() => response),
          take(1),
        );
      }),
    );
  }

  getMembershipBonusEligibility(): Observable<GeneralResponse> {
    return this.restService.postObs('account/checkMembershipBonusEligibility');
  }

  agreeToNewTerms(): Observable<GeneralResponse> {
    const request = {
      agreedToTerms: {
        value: true
      }
    };

    return this.restService.postObs('account/updateUserDetails', request);
  }

  /**
   * @description With Email
   */
  public requestSignIn(email: string, password: string, rememberMe: boolean, captchaToken: string | null): Observable<SignInResponse> {
    const requestObject = {
      email,
      password,
      rememberMe,
      captchaToken,
      originPath: this.router.url,
      device: DeviceService.getDevice(),
    };

    if (this.utmService.getUtmQueryParams()) {
      let utmRequestObj = this.utmService.getUtmQueryParams();
      Object.assign(requestObject, utmRequestObj);
    }

    return this.restService.postObs<SignInResponse>('account/signIn', requestObject);
  }

  public requestSignUp(email: string, fullName: string, password: string, marketingCommunicationOptIn: boolean, confirmEmail: string | null, captchaToken: string | null,): Observable<GeneralResponse> {
    const requestObject = {
      email,
      fullName,
      password,
      device: DeviceService.getDevice(),
      marketingCommunicationOptIn,
      confirmEmail,
      captchaToken,
      autoSignIn: true,
    };

    if (this.utmService.getUtmQueryParams()) {
      let utmRequestObj = this.utmService.getUtmQueryParams();
      Object.assign(requestObject, utmRequestObj);
    }

    return this.restService.postObs<GeneralResponse>('account/signUp', requestObject);
  }

  public handleSignInResponse(payload: HandleSignInResponse): void {
    const {redirectAfter, promoDetails, accessState} = payload;

    this.notifySignIn();

    if (!redirectAfter) {
      return;
    }

    /*Freemium Go to Get Started Step*/
    if (accessState.isFreemiumSignUp) {
      if (!promoDetails.activePromo) {
        this.router.navigate(['/plans']);
      }
    }

    /*Check if path intent happened before signIn , sign Up*/
    // TODO This is when the user is in a protected route(?) then signs in and then redirects to last protected route
    // if (OnboardingModel.pathIntent) {
    //   $location.path(OnboardingModel.pathIntent);
    //   OnboardingModel.pathIntent = null;
    // }

    // TODO
    const cryptoRelatedMessageTypes = [UserMessageType.CRYPTO_PURCHASE_COMPLETED,
      UserMessageType.CRYPTO_PURCHASE_COMPLETED_PARTIALLY,
      UserMessageType.CRYPTO_PURCHASE_EXPIRED];

    // this.messageQueueService.onMessageReceived
    //   .pipe(filter((message: UserMessage) => cryptoRelatedMessageTypes.includes(message.type)))
    //   .subscribe((message: UserMessage) => this.snackBar.open(message.message, 'Dismiss', {}));

    // We listen when the server commands a sign-out action
    // this.messageQueueService.onSignedOut.subscribe(() => {
    //   this.store.dispatch(UserActions.signOut());
    //   this.notifySignOut();
    // });

    // this.messageQueueService.startListening();
  }

  public contactSupport(subject: string, body: string): Observable<GeneralResponse> {
    return this.restService.postObs<GeneralResponse>('account/contactSupport', {subject, body});
  }

  public sendPasswordResetRequest(email: string): Observable<GeneralResponse> {
    return this.restService.postObs<GeneralResponse>('account/sendResetPassword', {email});
  }

  public resetPasswordRequest(newPassword: string, token: string): Observable<GeneralResponse> {
    return this.restService.postObs('account/resetPassword', {token, password: newPassword});
  }

  openUserDataEmailRequestModal(): void {
    this.cdkDialog.open(EmailCaptureModalComponent);
  }

  openDeleteAccountModal(): void {
    this.cdkDialog.open(DeleteAccountComponent);
  }
}
