import { Injectable } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private structuredData$: Subject<any> = new Subject<any>();
  isLinkury = window.location.href.indexOf('musicdiscoverytab') !== -1;

  eligibleRegionsArray = [{"@type": "Country", "name": "AD"}, {
    "@type": "Country",
    "name": "AE"
  }, {"@type": "Country", "name": "AR"}, {"@type": "Country", "name": "AT"}, {
    "@type": "Country",
    "name": "AU"
  }, {"@type": "Country", "name": "BE"}, {"@type": "Country", "name": "BG"}, {
    "@type": "Country",
    "name": "BH"
  }, {"@type": "Country", "name": "BO"}, {"@type": "Country", "name": "BR"}, {
    "@type": "Country",
    "name": "CA"
  }, {"@type": "Country", "name": "CH"}, {"@type": "Country", "name": "CL"}, {
    "@type": "Country",
    "name": "CO"
  }, {"@type": "Country", "name": "CR"}, {"@type": "Country", "name": "CY"}, {
    "@type": "Country",
    "name": "CZ"
  }, {"@type": "Country", "name": "DE"}, {"@type": "Country", "name": "DK"}, {
    "@type": "Country",
    "name": "DO"
  }, {"@type": "Country", "name": "DZ"}, {"@type": "Country", "name": "EC"}, {
    "@type": "Country",
    "name": "EE"
  }, {"@type": "Country", "name": "EG"}, {"@type": "Country", "name": "ES"}, {
    "@type": "Country",
    "name": "FI"
  }, {"@type": "Country", "name": "FR"}, {"@type": "Country", "name": "GB"}, {
    "@type": "Country",
    "name": "GR"
  }, {"@type": "Country", "name": "GT"}, {"@type": "Country", "name": "HK"}, {
    "@type": "Country",
    "name": "HN"
  }, {"@type": "Country", "name": "HU"}, {"@type": "Country", "name": "ID"}, {
    "@type": "Country",
    "name": "IE"
  }, {"@type": "Country", "name": "IL"}, {"@type": "Country", "name": "IS"}, {
    "@type": "Country",
    "name": "IT"
  }, {"@type": "Country", "name": "JO"}, {"@type": "Country", "name": "JP"}, {
    "@type": "Country",
    "name": "KW"
  }, {"@type": "Country", "name": "LB"}, {"@type": "Country", "name": "LI"}, {
    "@type": "Country",
    "name": "LT"
  }, {"@type": "Country", "name": "LU"}, {"@type": "Country", "name": "LV"}, {
    "@type": "Country",
    "name": "MA"
  }, {"@type": "Country", "name": "MC"}, {"@type": "Country", "name": "MT"}, {
    "@type": "Country",
    "name": "MX"
  }, {"@type": "Country", "name": "MY"}, {"@type": "Country", "name": "NI"}, {
    "@type": "Country",
    "name": "NL"
  }, {"@type": "Country", "name": "NO"}, {"@type": "Country", "name": "NZ"}, {
    "@type": "Country",
    "name": "OM"
  }, {"@type": "Country", "name": "PA"}, {"@type": "Country", "name": "PE"}, {
    "@type": "Country",
    "name": "PH"
  }, {"@type": "Country", "name": "PL"}, {"@type": "Country", "name": "PS"}, {
    "@type": "Country",
    "name": "PT"
  }, {"@type": "Country", "name": "PY"}, {"@type": "Country", "name": "QA"}, {
    "@type": "Country",
    "name": "RO"
  }, {"@type": "Country", "name": "SA"}, {"@type": "Country", "name": "SE"}, {
    "@type": "Country",
    "name": "SG"
  }, {"@type": "Country", "name": "SK"}, {"@type": "Country", "name": "SV"}, {
    "@type": "Country",
    "name": "TH"
  }, {"@type": "Country", "name": "TN"}, {"@type": "Country", "name": "TR"}, {
    "@type": "Country",
    "name": "TW"
  }, {"@type": "Country", "name": "US"}, {"@type": "Country", "name": "UY"}, {
    "@type": "Country",
    "name": "VN"
  }, {"@type": "Country", "name": "ZA"}];

  constructor(private title: Title, private meta: Meta) {
  }

  applyExtraTags(imageContentValue = 'https://d1ewwfbdm371xj.cloudfront.net/assets/og_image/media_default_emusic@2x.jpg', imageSize = 500): void {
    this.meta.updateTag({property: 'og:image', content: imageContentValue});
    this.meta.updateTag({property: 'twitter:image', content: imageContentValue});
    this.meta.updateTag({property: 'og:image:width', content: imageSize + ''});
    this.meta.updateTag({property: 'og:image:height', content: imageSize + ''});
    this.meta.updateTag({property: 'og:url', content: location.href});
  }

  setDefaultMetaTags(pageName: string): void {
    const suffix = this.isLinkury ? 'Music Discovery Tab' : 'eMusic';
    const titleValue = `${pageName ? (pageName + ' |') : ''} Discover and Download Music | ${suffix}`;
    this.title.setTitle(titleValue);

    const titleContentValue = `Discover and Download Music | ${suffix}`;
    this.meta.updateTag({name: 'title', content: titleContentValue});
    this.meta.updateTag({property: 'og:title', content: titleContentValue});

    const descriptionContentValue = 'Discover and download music with our free personalized discovery tools. Read reviews, listen to samples, and buy tracks or albums from your favorite artists.';
    this.meta.updateTag({name: 'description', content: descriptionContentValue});
    this.meta.updateTag({property: 'og:description', content: descriptionContentValue});

    const ogTypeValue = 'website';
    this.meta.updateTag({property: 'og:type', content: ogTypeValue});

    const keywordsContentValue = 'music, downloads, eMusic';
    this.meta.updateTag({name: 'keywords', content: keywordsContentValue});

    this.applyExtraTags();
  }

  setMetaTagsForAlbum(albumName: string, artistName: string, albumCoverUrl: string, price: string, rating: number): void {
    const suffix = this.isLinkury ? 'Music Discovery Tab' : 'eMusic';

    const titleValue = `Download ${albumName} by ${artistName} | ${suffix}`;
    this.title.setTitle(titleValue);

    const titleContentValue = `Download ${albumName} by ${artistName} | ${suffix}`;
    this.meta.updateTag({name: 'title', content: titleContentValue});
    this.meta.updateTag({property: 'og:title', content: titleContentValue});

    const twitterTitleContentValue = `Check out ${albumName} by ${artistName}`;
    this.meta.updateTag({property: 'twitter:title', content: twitterTitleContentValue});

    const twitterDescriptionContentValue = `Sample and download ${albumName} by ${artistName} on eMusic. Friends get their first track free!`;
    this.meta.updateTag({property: 'twitter:description', content: twitterDescriptionContentValue});

    const descriptionContentValue = `Download ${albumName} by ${artistName} | ${suffix}`;
    this.meta.updateTag({name: 'description', content: descriptionContentValue});
    this.meta.updateTag({property: 'og:description', content: descriptionContentValue});

    //Meta tag updates for Open Graph
    const ogTypeValue = 'music.album';
    this.meta.updateTag({property: 'og:type', content: ogTypeValue});

    const keywordsContentValue = `music, downloads, ${albumName}, ${suffix}, ${artistName}`;
    this.meta.updateTag({name: 'keywords', content: keywordsContentValue});

    // structured data
    const dataContent =
      {
        "@context": "http://schema.googleapis.com/",
        "@type": "MusicAlbum",
        "@id": location.href,
        "url": location.href,
        "name": albumName,
        "description":
          `${albumName}, an album by ${artistName} on eMusic. Price: ${price}. Rating: ${rating > 0 ? rating + " stars" : "No ratings yet"}.`,
        "potentialAction":
          {
            "@type":
              "ListenAction",
            "target":
              [{
                "@type": "EntryPoint",
                "urlTemplate": location.href,
                "actionPlatform": ["http://schema.org/DesktopWebPlatform",
                  "http://schema.org/IOSPlatform",
                  "http://schema.googleapis.com/GoogleAudioCast",
                  "http://schema.googleapis.com/GoogleVideoCast"]
              }],
            "expectsAcceptanceOf":
              {
                "@type":
                  "Offer", "category":
                  "free", "eligibleRegion": this.eligibleRegionsArray
              }
          }

      };
    // const imageContentValue = $filter('imageByWidth')(albumCoverUrl, 400);
    this.sendStructuredData(dataContent);
    const imageContentValue = albumCoverUrl + '&width=' + 400;
    this.applyExtraTags(imageContentValue, 400);
  }

  setMetaTagsForLabel(labelName: string): void {
    // let suffix = $rootScope.isLinkury ? 'Music Discovery Tab' : 'eMusic'; TODO
    const suffix = 'eMusic';
    const titleValue = `${labelName} | Browse Albums | Download Music | ${suffix}`;
    this.title.setTitle(titleValue);

    const titleContentValue = `${labelName} | Browse Albums | Download Music | ${suffix}`;
    this.meta.updateTag({name: 'title', content: titleContentValue});
    this.meta.updateTag({property: 'og:title', content: titleContentValue});

    //Meta tag updates for Open Graph
    const ogTypeValue = 'website';
    this.meta.updateTag({property: 'og:type', content: ogTypeValue});

    const keywordsContentValue = `music, downloads, ${suffix}, ${labelName}`;
    this.meta.updateTag({name: 'keywords', content: keywordsContentValue});
    this.applyExtraTags();
  }

  setMetaTagsForArtist(artistName: string, artistImageUrl: string, latestReleases: any[], artistBio: string): void {
    const titlesOfLatestReleases = latestReleases.map(r => r.title).join(', ');

    // let suffix = $rootScope.isLinkury ? 'Music Discovery Tab' : 'eMusic';
    const suffix = 'eMusic';
    const titleValue = `${artistName} | Download Music, Tour Dates & Video | ${suffix}`;
    this.title.setTitle(titleValue);

    const titleContentValue = `${artistName} | Download Music, Tour Dates & Video | ${suffix}`;
    this.meta.updateTag({name: 'title', content: titleContentValue});
    this.meta.updateTag({property: 'og:title', content: titleContentValue});

    // if (!$rootScope.isLinkury) { TODO
    //   $rootScope.descriptionContentValue = `Download legal MP3 albums from ${artistName} at eMusic. Albums starting at $5.99 and songs starting at $0.89`;
    // }

    const descriptionContentValue = `Download legal MP3 albums from ${artistName} at eMusic. Albums starting at $5.99 and songs starting at $0.89`;
    this.meta.updateTag({name: 'description', content: descriptionContentValue});
    this.meta.updateTag({property: 'og:description', content: descriptionContentValue});

    const twitterTitleContentValue = `Check out music from ${artistName}`;
    this.meta.updateTag({property: 'twitter:title', content: twitterTitleContentValue});

    const twitterDescriptionContentValue = `Sample and download music from ${artistName} on eMusic. Friends get their first track free!`;
    this.meta.updateTag({property: 'twitter:description', content: twitterDescriptionContentValue});

    //Meta tag updates for Open Graph
    const ogTypeValue = 'profile';
    this.meta.updateTag({property: 'og:type', content: ogTypeValue});

    const keywordsContentValue = `music, downloads, ${suffix}, ${artistName}`;
    this.meta.updateTag({name: 'keywords', content: keywordsContentValue});

    //    structured data
    const dataContent = {
      "@context":
        "http://schema.googleapis.com/",
      "@type":
        "MusicGroup",
      "@id":
      location.href,
      "url":
      location.href,
      "name":
      artistName,
      "description":
        `${artistName}, Category: Artist, Biography: ${artistBio ?
          artistBio.substring(0, 350) : twitterDescriptionContentValue}, Albums: ${titlesOfLatestReleases}`,
      "potentialAction":
        {
          "@type":
            "ListenAction",
          "target":
            [{
              "@type": "EntryPoint",
              "urlTemplate": location.href,
              "actionPlatform": ["http://schema.org/DesktopWebPlatform",
                "http://schema.org/IOSPlatform",
                "http://schema.googleapis.com/GoogleAudioCast",
                "http://schema.googleapis.com/GoogleVideoCast"]
            }],
          "expectsAcceptanceOf":
            {
              "@type":
                "Offer", "category":
                "free", "eligibleRegion": this.eligibleRegionsArray
            }
        }

    };

    this.sendStructuredData(dataContent);
    const imageContentValue = artistImageUrl + '&width=' + 400;

    this.applyExtraTags(imageContentValue, 400);
  }

  setMetaTagsForBrowse(): void {
    // let suffix = $rootScope.isLinkury ? 'Music Discovery Tab' : 'eMusic';
    const suffix = 'eMusic';
    const titleValue = `Browse Music by Genre, Decade, Rating, & More  | ${suffix}`;
    this.title.setTitle(titleValue);

    const titleContentValue = `Browse Music by Genres, Decade, and More  | ${suffix}`;
    this.meta.updateTag({name: 'title', content: titleContentValue});
    this.meta.updateTag({property: 'og:title', content: titleContentValue});

    const descriptionContentValue = 'Discover and download music with our free personalized discovery tools. Read reviews, listen to samples, and buy tracks or albums from your favorite artists.';
    this.meta.updateTag({name: 'description', content: descriptionContentValue});
    this.meta.updateTag({property: 'og:description', content: descriptionContentValue});

    const ogTypeValue = 'website';
    this.meta.updateTag({property: 'og:type', content: ogTypeValue});

    const keywordsContentValue = 'music, browse, free, downloads, eMusic';
    this.meta.updateTag({name: 'keywords', content: keywordsContentValue});
  }

  setMetaTagsForCollectible(collectibleName: string, artistName: string, titleContent: string, collectibleImageUrl: string): void {
    let suffix = 'eMusic';
    this.title.setTitle(titleContent);

    this.meta.updateTag({name: 'title', content: titleContent});
    this.meta.updateTag({property: 'twitter:title', content: titleContent});
    this.meta.updateTag({property: 'twitter:description', content: titleContent});

    const descriptionContentValue = collectibleName + ' by ' + artistName + ' | ' + suffix;
    this.meta.updateTag({property: 'description', content: descriptionContentValue});

    //Meta tag updates for Open Graph
    const ogTypeValue = 'music.album';
    this.meta.updateTag({property: 'og:type', content: ogTypeValue});

    const keywordsContentValue = 'music, downloads, ' + collectibleName + ", " + suffix + ", " + artistName;
    this.meta.updateTag({name: 'keywords', content: keywordsContentValue});


    const dataContent = {
      "@context":
        "http://schema.googleapis.com/",
      "@type":
        "MusicAlbum",
      "@id":
      location.href,
      "url":
      location.href,
      "name":
      collectibleName,
      "description":
        `${collectibleName}, a collectible by ${artistName} on eMusic.`,
      "potentialAction":
        {
          "@type":
            "ListenAction",
          "target":
            [{
              "@type": "EntryPoint",
              "urlTemplate": location.href,
              "actionPlatform": ["http://schema.org/DesktopWebPlatform",
                "http://schema.org/IOSPlatform",
                "http://schema.googleapis.com/GoogleAudioCast",
                "http://schema.googleapis.com/GoogleVideoCast"]
            }],
          "expectsAcceptanceOf":
            {
              "@type":
                "Offer", "category":
                "free", "eligibleRegion": this.eligibleRegionsArray
            }
        }

    };

    this.sendStructuredData(dataContent);

    const imageContentValue = collectibleImageUrl + '&width=' + 400;
    this.applyExtraTags(imageContentValue, 400);
  }

  sendStructuredData(dataContent: any): void {
    this.structuredData$.next(dataContent);
  }

  getStructuredDataObs(): Observable<any> {
    return this.structuredData$.asObservable();
  }
}
