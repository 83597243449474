<div class="payment-form">
  <form (ngSubmit)="submitPayment()">
    <div class="cc_details" [hidden]="paypalChosen">
      <div id="stripe_container">
        <!-- A Stripe Element will be inserted here. -->
      </div>
    </div>

    <div class="paypal_details" [ngClass]="{'no__margin__top': paypalChosen, 'no-margin-bottom': hideConfirmButton}">
      <div class="paypal_details_wrapper" [hidden]="!paypalButtonInitialized" [ngClass]="{'paypal__chosen': paypalChosen}">
        <div class="or_use" *ngIf="!paypalChosen && paypalButtonInitialized">Or use</div>

        <div id="braintree_paypal_button" #paypalContainer>
          <!-- A Paypal Element will be inserted here. -->
        </div>
      </div>
    </div>

    <ngx-turnstile [siteKey]="turnstileSiteKey"
                   (resolved)="handleCaptchaToken($event)"
                   theme="light"></ngx-turnstile>

    <button type="submit" class="btn red-button" *ngIf="!hideConfirmButton">
      {{confirmTextButton}}
    </button>
  </form>
</div>
