import {GeneralResponse} from "./GeneralResponse";

export class GetAvailableRewardResponse extends GeneralResponse {
  availableRewardList: AvailableRewards[];
}

export interface AvailableRewards {
  id: number;
  name: string;
  startDate: Date;
  endDate: Date;
  promotedBehavior: PromotedBehavior;
  rewardPerTimePerformed: number;
  maxTimesClaimableInPeriod: number;
  timesClaimedAlready: number;
  rewardType: RewardType;
}

export enum PromotedBehavior {
  LEAVE_REVIEW,
  REVIEW_UPVOTED,
  CONNECT_A_TOKEN_WALLET
}

export enum RewardType {
  EMU
}
