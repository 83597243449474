<div id="reactivate" class="modal-container">
  <div class="modal-content">
    <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

    <h2 class="modal-header-title">
      Your Membership Is On Hold
    </h2>

    <div class="modal-body">
      <div class="body">
        <p>If you would like to start enjoying your membership again, you can reactivate it below. Once reactivated you
          can update your plan level at any time.</p>
      </div>

      <div class="buttons-container mobile-horizontal">
        <button type="button" (click)="cancelMemberShip()" class="btn new-secondary cancel">
          Cancel Membership
        </button>

        <a type="button" target="_blank" class="btn red-button confirm"
           href="https://emusic.zendesk.com/hc/en-us/articles/360022862873-How-do-I-take-my-account-off-hold-">
          Reactivate
        </a>
      </div>
    </div>
  </div>
</div>
