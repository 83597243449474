import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DialogCloseButtonComponent } from '@components/dialog/dialog-close-button/dialog-close-button.component';
import { DialogRef } from '@angular/cdk/dialog';
import { StandardInputComponent } from '@components/forms/regular-input/standard-input.component';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { EmailCaptureModalStore } from '@components/dialog/email-capture-modal/store/email-capture-modal.store';
import { Observable } from 'rxjs';
import { LetModule } from '@ngrx/component';
import { EmailCaptureModalState } from '@components/dialog/email-capture-modal/store/email-capture-modal.state';
import { EmusicLoaderSpinnerComponent } from '@components/emusic-loader-spinner/emusic-loader-spinner.component';
import { NgIf } from '@angular/common';
import { Store } from '@ngrx/store';
import { selectUserEmail } from '@files-ngrx/selectors/user.selectors';
import { first } from 'rxjs/operators';

@Component({
  selector: 'email-capture-modal',
  standalone: true,
  providers: [EmailCaptureModalStore],
  imports: [DialogCloseButtonComponent, StandardInputComponent, ReactiveFormsModule, LetModule, FormsModule, EmusicLoaderSpinnerComponent, NgIf],
  templateUrl: './email-capture-modal.component.html',
  styleUrls: ['./email-capture-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailCaptureModalComponent {
  private localStore = inject(EmailCaptureModalStore);
  private store = inject(Store);
  dialogRef = inject(DialogRef);
  vm$: Observable<EmailCaptureModalState> = this.localStore.selectViewModel;

  emailInput: FormControl<string> = new FormControl<string>('', {
    nonNullable: true,
    validators: [Validators.email],
  });

  constructor() {
    this.store.select(selectUserEmail).pipe(first()).subscribe(email => {
      this.emailInput.setValue(email ?? '');
    });
  }

  handleSubmit(input: FormControl<string>, vm: EmailCaptureModalState): void {
    if (!input.value || vm.requestInProgress || input.invalid) {
      return;
    }

    this.localStore.submitRequestFx({email: input.value, dialogRef: this.dialogRef});
  }
}
