<mat-dialog-content [ngSwitch]="withdrawStage" *ngrxLet="vm$ as vm">
  <div class="nft_modal">
    <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

    <ng-container *ngSwitchCase="WithdrawStage.PRE_WITHDRAW">
      <h2 class="title">WITHDRAW NFT</h2>

      <div class="divider"></div>

      <h3 class="nft_name dotted">{{nftItem.collectible.name}}</h3>

      <nft-seller [artistName]="nftItem.artist.name"
                  [artistImage]="nftItem.artist.imageUrl">
      </nft-seller>

      <div class="nft_image_wrapper">
        <img class="nft_withdraw_modal_image"
             imageLoadAnimation
             [ngSrc]="nftItem.collectible.imageList[0].imageUrl | customImgUrl: {width: 217, format: 'GIF'}"
             width="217"
             height="217"
             [alt]="nftItem.collectible.name">
      </div>

      <p class="withdraw_text">Your NFT will be minted to the wallet below</p>

      <hr class="divider"/>

      <!--    No Connected Wallet on server State-->
      <token-wallet-state [notConnectedWalletAddress]="vm.notConnectedWallet"
                          [removeBorders]="true"
                          template="NOT-CONNECTED"
                          *ngIf="vm.notConnectedWallet">
      </token-wallet-state>

      <!--    Connected Wallet State-->
      <token-wallet-state *ngIf="vm.wallet"
                          [tokenWallet]="vm.wallet"
                          [removeBorders]="true"
                          template="WALLET"></token-wallet-state>

      <hr class="divider"/>

      <button class="btn red-button nft_withdraw_button"
              [ngClass]="{'no_wallet': !vm.wallet}"
              [disabled]="!vm.wallet"
              (click)="withdrawNft()">
        Withdraw NFT
      </button>

      <div class="nft_details_modal_faq">Not sure what to do? Head over to our
        <a href="https://emusic.zendesk.com/hc/en-us/sections/1500002519862-eMusic-NFTs" target="_blank">FAQs</a>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="WithdrawStage.IN_PROGRESS">
      <h2 class="title">WITHDRAWING IN PROGRESS</h2>

      <hr class="divider"/>

      <p class="in_progress_text">
        Your withdraw is being processed. Please allow a few minutes for the transaction to be confirmed. We will
        notify you when your NFT is minted to your wallet.
      </p>

      <button class="btn red-button"
              type="button"
              (click)="dialogRef.close()">
        OK, GOT IT!
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="WithdrawStage.SUCCESS">
      <h2 class="title">CONGRATS!</h2>

      <hr class="divider"/>

      <p>
        <span class="nft_name dotted">{{nftItem.collectible.name}}</span>

        <span class="withdraw_subtitle">
          was minted to your wallet
        </span>
      </p>


      <div class="nft_image_wrapper">
        <img class="nft_withdraw_modal_image"
             [ngSrc]="nftItem.collectible.imageList[0].imageUrl | customImgUrl: {width: 217, format: 'GIF'}"
             width="217"
             height="217"
             [alt]="nftItem.collectible.name">
      </div>

      <p class="helpful_description">
        Click the "Add" icon next to the contract address to make the NFT visible in your wallet. This will require
        approval in your Metamask wallet. If this is your first time withdrawing eMusic Tokens into this wallet you
        would also need to <span (click)="addEthereumChain()">Set Metamask</span>
        to use {{nftItem.collectible.chainDisplayName}}
      </p>

      <hr class="divider"/>

      <div class="contract_address">
        <span class="contract_address_title">Contract Address</span>

        <span class="contract_address_link">
                <a href="{{nftItem.collectible.etherscanUrl}}"
                   target="_blank">{{nftItem.collectible.contractAddress}}</a>
                <span class="contract_address_copy"
                      title="Copy contact address to clipboard"
                      (click)="copyContractAddressToClipboard()"></span>
            </span>

        <span class="contract_address_add" (click)="addContractToWallet()"></span>
      </div>

      <hr class="divider"/>

      <p class="nft_details_modal_faq" style="margin-top: 40px">
        For more information Head over to our
        <a
          href="https://emusic.zendesk.com/hc/en-us/articles/4402149698963-How-can-I-see-my-newly-acquired-eMusic-NFT-in-my-wallet-"
          target="_blank">FAQs</a>.
      </p>
    </ng-container>
  </div>
</mat-dialog-content>
