import {GeneralResponse} from "../../../../core/protocol/GeneralResponse";
import {GetCollectibleLiteDetailsResponse} from "./GetCollectibleDetailsResponse";
import {Currency} from "@services/protocol/Subscription";

export class CollectibleLite {
  id: number;
  sortKey: number;
  name: string;
  symbol: string;
  description: string;
  creationDate: Date;
  type: CollectibleType;
  amountLimit: number;
  auctionState?: AuctionState;
  amountAvailable: number;
  amountClaimed: number;
  ownedByMeCount: number;
  etherscanUrl: string;
  chainDisplayName: string;
  contractAddress: string;
  owner: boolean;
  displayTag: string;
  metamaskConnectionDetails: MetamaskConnectionDetails;
  startDate: number;
  endDate: number;
  fileDescription: string;
  fileStorageId: number;
  fileName: string;
  fileType: any;
  fileDownloadUrl: string;
  serverCurrentDate: number;
  highlightList: Array<NftHighLight>;
  imageList: Array<CollectibleImage>;
  pricingMap: Partial<Record<Currency, number>>;
  verificationPricingMap: Map<string, number>;
}

export enum CollectibleType {
  SIMPLE = 'SIMPLE',
  RELEASE = 'RELEASE',
  MERCH = 'MERCH',
  AUCTION = 'AUCTION',
  EMUSICLIVE_TICKET_BUNDLE = 'EMUSICLIVE_TICKET_BUNDLE'
}

export interface CollectibleImage {
  altText: string;
  collectibleId: number;
  creationDate: Date;
  id: number;
  imageFormat: string;
  imageUrl: string;
  sortKey: number;
}

export interface NftHighLight {
  collectibleId: number;
  creationDate: number;
  description: string;
  id: number;
  imageUrl: string;
  sortKey: number;
  title: string;
}

export class AuctionState {
  auctionBidId: number;
  fiatPrice: number;
  ethPrice: number;
  existingBidByMe: boolean;
  nextBidMinimumFiatPrice: number;
  nextBidMinimumEthPrice: number;
  isFiatPrimary: boolean;
  isHighestBidStartingPrice: boolean;
  isReservePriceMet: boolean;
  isHighestBidderMe: boolean;
  isExistingBidByMe: boolean;
  fiatCurrency: string;
  bidCount: number;
  reservePriceMet: boolean;
  highestBidderMe: boolean;
  highestBidPrimaryPrice: number;
  highestBidPrimaryCurrency: string;
  highestBidSecondaryPrice: number;
  highestBidSecondaryCurrency: string;
  highestBidderUserId: number;
  bidderUserIdList: Array<number>;
  auctionStatus: AuctionStatus;
  fiatPrimary: true
}

export interface AuctionStateResponse extends GeneralResponse {
  auctionState: AuctionState;
}

export enum AuctionStage {
  PRE_BIDDING= 'PRE_BIDDING',
  BIDDING = 'BIDDING',
  BIDDING_ABOUT_TO_END = 'BIDDING_ABOUT_TO_END',
  POST_BIDDING = 'POST_BIDDING',
}

export enum SaleStage {
  PRE_SALE = 'PRE_SALE',
  IN_PROGRESS = 'IN_PROGRESS',
  POST_SALE = 'POST_SALE',
  ON_SALE_FOREVER = 'ON_SALE_FOREVER',
}

export enum AuctionStatus {
  NOT_YET_ENDED = 'NOT_YET_ENDED',
  ENDED_RESERVE_PRICE_NOT_MET = 'ENDED_RESERVE_PRICE_NOT_MET',
  ENDED_WINNER_NOTIFIED = 'ENDED_WINNER_NOTIFIED',
  ENDED_WINNER_NEEDS_TO_PAY_ONLINE = 'ENDED_WINNER_NEEDS_TO_PAY_ONLINE',
  ENDED_WINNER_PAID = 'ENDED_WINNER_PAID',
  ENDED_WINNER_PROCESSING_ERROR = 'ENDED_WINNER_PROCESSING_ERROR',
  ENDED_NO_BIDS = 'ENDED_NO_BIDS'
}

export class MetamaskConnectionDetails {
  defaultDecimals: number;
  chainId: string;
  chainName: string;
  nativeCurrency: MetamaskNativeCurrency;
  rpcUrls: Array<string>;
  blockExplorerUrls: Array<string>;
}

export class MetamaskNativeCurrency {
  name: string;
  symbol: string;
  decimals: number;
}

export interface BiddingStatus {
  auctionStage?: AuctionStage | undefined | null;
  collectibleDetails?: GetCollectibleLiteDetailsResponse;
  auctionState?: AuctionState,
  timerDetails?: {
    daysLeft?: number,
    hoursLeft?: number,
    minutesLeft?: number,
    secondsLeft?: number,
  }
}

export interface SaleStatus {
  saleStage?: SaleStage;
}

export interface PurchaseCollectibleResponse extends GeneralResponse {
  billingError: string;
}
