import {Injectable} from '@angular/core';
import {RestService} from "../ws/rest.service";
import {
  FreezeSubscriptionResponse,
  GetRightListResponse,
  GetSubscriptionDetailsResponse,
  RightList
} from "./protocol/Subscription";
import {map, Observable} from "rxjs";
import {Dialog} from "@angular/cdk/dialog";
import {
  ChangeOrCancelModalComponent
} from "@components/dialog/change-or-cancel-supscription-flow/change-or-cancel-modal/change-or-cancel-modal.component";
import {
  CanWeHelpModalComponent
} from "@components/dialog/change-or-cancel-supscription-flow/can-we-help-modal/can-we-help-modal.component";
import {
  ReadyToHelpModalComponent
} from "@components/dialog/change-or-cancel-supscription-flow/ready-to-help-modal/ready-to-help-modal.component";
import {
  SupportFormSubmittedModalComponent
} from "@components/dialog/change-or-cancel-supscription-flow/support-form-submitted-modal/support-form-submitted-modal.component";
import {
  HoldAccountModalComponent
} from "@components/dialog/change-or-cancel-supscription-flow/hold-account-modal/hold-account-modal.component";
import {GeneralResponse} from "../protocol/GeneralResponse";
import {
  CancelConfirmationModalComponent
} from "@components/dialog/change-or-cancel-supscription-flow/cancel-confirmation-modal/cancel-confirmation-modal.component";
import {
  HoldSubmittedModalComponent
} from "@components/dialog/change-or-cancel-supscription-flow/hold-submitted-modal/hold-submitted-modal.component";
import {
  LitePlanModalComponent
} from "@components/dialog/change-or-cancel-supscription-flow/lite-plan-modal/lite-plan-modal.component";
import {
  ReactivateModalComponent
} from "@components/dialog/change-or-cancel-supscription-flow/reactivate-modal/reactivate-modal.component";
import {TokenUpsellModalComponent} from "@components/dialog/token-upsell-modal/token-upsell-modal.component";
import {
  LoseCreditNotificationModalComponent
} from "@components/dialog/lose-credit-notification-modal/lose-credit-notification-modal.component";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  constructor(private restService: RestService,
              private dialog: Dialog) {
  }

  public getSubscriptionDetailsObs(): Observable<GetSubscriptionDetailsResponse> {
    return this.restService.postObs<GetSubscriptionDetailsResponse>('subscription/getSubscriptionDetails');
  }

  getRightList(): Observable<RightList> {
    return this.restService.postObs<GetRightListResponse>('subscription/getRightList').pipe(map((response: GetRightListResponse) => {
      if (response && response.responseStatus === 'SUCCESS') {
        const rightList = response.rightList;
        const finalRightArray = [];
        const finalSubscriptionRightArray = [];

        for (let i = 0; i < rightList.length; i++) {

          if (rightList[i].origin === 'SUBSCRIPTION') {
            finalSubscriptionRightArray.push(rightList[i]);
          } else {
            finalRightArray.push(rightList[i]);
          }
        }

        return {subscriptionRights: finalSubscriptionRightArray, rights: finalRightArray};
      }

      return {subscriptionRights: null, rights: null};
    }));
  }

  public cancelSubscriptionLater(subscriptionId: number, reason: string, reasonDescription = ''): Observable<GeneralResponse>{
    return this.restService.postObs<GeneralResponse>('subscription/cancelSubscriptionLater', {subscriptionId, reason, reasonDescription});
  }

  public freezeSubscription(subscriptionId: number, days: number): Observable<FreezeSubscriptionResponse> {
    return this.restService.postObs<FreezeSubscriptionResponse>('subscription/freezeSubscription', {subscriptionId, days});
  }

  public openChangePlanModal(): void {
    this.dialog.open(ChangeOrCancelModalComponent, {
      closeOnNavigation: true,
      closeOnDestroy: true,
    });
  }

  public openHoldSubmittedModal(pendingHoldDays: number): void {
    this.dialog.open(HoldSubmittedModalComponent, {
      closeOnNavigation: true,
      closeOnDestroy: true,
      data: {
        pendingHoldDays,
      }
    });
  }

  public openCancelConfirmationModal(): void {
    this.dialog.open(CancelConfirmationModalComponent, {
      closeOnNavigation: true,
      closeOnDestroy: true,
    });
  }

  public openLitePlanModal(): void {
    this.dialog.open(LitePlanModalComponent, {
      closeOnNavigation: true,
      closeOnDestroy: true,
    });
  }

  public openCanWeHelpModal(): void {
    this.dialog.open(CanWeHelpModalComponent, {
      closeOnNavigation: true,
      closeOnDestroy: true,
    });
  }

  public openReadyToHelpModal(): void {
    this.dialog.open(ReadyToHelpModalComponent, {
      closeOnNavigation: true,
      closeOnDestroy: true,
    });
  }

  public openReactivateModal(): void {
    this.dialog.open(ReactivateModalComponent, {
      closeOnNavigation: true,
      closeOnDestroy: true,
    });
  }

  public openTokenUpsellModal(): void {
    this.dialog.open(TokenUpsellModalComponent, {
      closeOnNavigation: true,
      closeOnDestroy: true,
    });
  }

  public openSupportFormSubmittedModal(): void {
    this.dialog.open(SupportFormSubmittedModalComponent, {
      closeOnNavigation: true,
      closeOnDestroy: true,
    });
  }

  public openHoldAccountModal(): void {
    this.dialog.open(HoldAccountModalComponent, {
      closeOnNavigation: true,
      closeOnDestroy: true,
    });
  }

  public openLoseCreditNotificationModal(planPricingId: number): void {
    this.dialog.open(LoseCreditNotificationModalComponent, {
      closeOnNavigation: true,
      closeOnDestroy: true,
      data: {
        planPricingId,
      }
    });
  }
}
