import {getSelectors} from '@ngrx/router-store';
import {createSelector} from "@ngrx/store";
import {Data, Params} from "@angular/router";

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
  selectTitle, // select the title if available
} = getSelectors();

/**
 * selectUrl, selectQueryParams, selectRouteParams
 */
export const selectRouteDetails = createSelector(selectUrl, selectQueryParams, selectRouteParams, selectRouteData, (currentUrl, queryParams, routeParams, routeData) => ({
  currentUrl,
  queryParams,
  routeParams,
  routeData,
}));

export interface RouteDetails {
  currentUrl: string;
  queryParams: Params;
  routeParams: Params;
  routeData: Data;
}
