import { inject, Injectable, LOCALE_ID } from '@angular/core';
import { RestService } from '../ws/rest.service';
import {
  GetSavedPaymentDetailsResponse,
  PaymentClientToken,
  PurchasePlanResponse,
  SavePaymentDetailsResponse
} from './protocol/GetSavedPaymentDetailsResponse';
import { UpdatePaymentModalComponent } from '@components/update-payment-modal/update-payment-modal.component';
import { take, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';
import {
  GetBoosterListResponse,
  GetPlanDetailsResponse,
  GetPlanDetailsResponseProps, Plan
} from '../protocol/GetPlanDetailsResponse';
import { PurchaseBoosterResponse } from '../protocol/PurchaseBoosterResponse';
import { GetPlanCheckoutDetailsResponse } from '@services/protocol/GetPlanCheckoutDetailsResponse';
import { Store } from '@ngrx/store';
import { UserActions } from '@files-ngrx/actions/user.actions';
import { AuctionStage, CollectibleLite } from '@public-pages/nft/protocol/CollectibleLite';
import { CurrencyToSymbolPipe } from '@pipes/our-currency.pipe';
import { CurrencyPipe } from '@angular/common';
import { environment } from '../../../environments/environment';
import { BillingDetailsActions } from '@files-ngrx/actions/billing.actions';

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  private readonly restService = inject(RestService);
  private readonly store = inject(Store);
  private readonly dialog = inject(MatDialog);
  private readonly localId: string = inject(LOCALE_ID);
  private readonly isProduction = environment.production;

  updateSavedPaymentDetails(): void {
    this.store.dispatch(BillingDetailsActions.getSavedPaymentDetails());
  }

  getPlanList({planFamilyList, planName, promoCode}: GetPlanDetailsResponseProps): Observable<GetPlanDetailsResponse> {
    const requestObject: { planFamilyList: string[] | null, name?: string | null, promotionCode?: string | null } = {
      planFamilyList: planFamilyList,
    };

    if (planName) {
      requestObject.name = planName;
    }

    if (promoCode) {
      requestObject.promotionCode = promoCode;
    }

    return this.restService.postObs('billing/getPlanList', requestObject);
  }

  public getPlainPlanList(planFamilyList: string[], planName: string, promotionCode: string | null): Observable<GetPlanDetailsResponse> {
    return this.restService.postObs<GetPlanDetailsResponse>('billing/getPlanList', {
      planFamilyList,
      name: planName,
      promotionCode
    });
  }

  public getBoosterList(): Observable<GetBoosterListResponse> {
    return this.restService.postObs<GetBoosterListResponse>('billing/getBoosterList', {}).pipe(
      map(response => {
        const newResponse = response;
        newResponse.planList.map(plan => ({
          ...plan,
          _price: Number(plan.price),
          _creditAmount: Number(plan.creditAmount),
        }));

        return newResponse;
      })
    );
  }

  getSavedPaymentDetails(): Observable<GetSavedPaymentDetailsResponse> {
    return this.restService.postObs<GetSavedPaymentDetailsResponse>('billing/getSavedPaymentDetails');
  }

  getPlanCheckoutDetails(): Observable<GetPlanCheckoutDetailsResponse> {
    return this.restService.postObs<GetPlanCheckoutDetailsResponse>('billing/getPlanCheckoutDetails');
  }

  openUpdatePaymentModal(buttonText?: string, titleText?: string, additionalClass?: string): Observable<GetSavedPaymentDetailsResponse | undefined> {
    const dialog = this.dialog.open(UpdatePaymentModalComponent, {
      maxWidth: '528px',
      width: '100%',
      closeOnNavigation: true,
      panelClass: ['modal', 'nft_modal', additionalClass ?? ''],
      data: {
        titleText,
        buttonText
      }
    });

    return dialog.afterClosed().pipe(take(1));
  }

  purchaseBooster(booster: Plan, promoCode: string | null): Observable<PurchaseBoosterResponse> {
    const request = {
      planPricingId: booster.planPricingId,
      promoCode
    };

    return this.restService.postObs<PurchaseBoosterResponse>('billing/purchaseBooster', request);
  }

  async generateClientToken(captchaToken: string | null): Promise<PaymentClientToken> {
    return await this.restService.post<PaymentClientToken>('billing/generateClientToken', {captchaToken});
  }

  async savePaymentDetails(paymentMethod: string, paymentMethodNonce: string, origin: string): Promise<SavePaymentDetailsResponse> {
    const request = {
      paymentMethod,
      paymentMethodNonce,
      allowNonScaCards: true
    };

    const prefix = origin === 'auctionBid' ? 'collectible' : 'billing';
    return await this.restService.post<SavePaymentDetailsResponse>(`${prefix}/savePaymentDetails`, request);
  }

  getPaymentError(responseText: string) {
    switch (responseText) {
      case 'INTERNAL_ERROR':
      case 'NO_USER_OR_PAYMENT_METHOD_TO_STORE':
        return 'Please enter a payment method to continue';
      case 'CREDIT_CARD_EXPIRED':
        return 'This card has an expiration date in the past.  Please update to continue!';
      case 'TRANSACTION_FAILED':
        return 'Oh no! An error has occurred, please try again! If the problem continues, please contact us at support@emusic.com';
      case 'PROCESSOR_PROCESSOR_DECLINED':
      case 'PROCESSOR_DECLINED':
        return 'Unfortunately your payment request has been declined please contact your bank for more details or try a ' +
          'different payment method.';
      case 'CANNOT_CREATE_PAYMENT_METHOD':
      case 'INVALID_PAYMENT_METHOD':
      case 'NO_PAYMENT_METHOD_TOKEN_RETURNED':
      case 'NO_SESSION':
      case 'ACCESS_DENIED':
      case 'INVALID_PAYMENT_METHOD_NONCE':
        return 'Sorry, an error has occurred. Please try again! If the problem continues, please contact us at support@emusic.com';
    }

    return '';
  }

  getPurchaseError(responseText: string) {
    switch (responseText) {
      case 'OFFER_PRICING_NOT_FOUND':
      case 'OFFER_NOT_FOUND':
        return 'The server ran into an error and could not move forward.  If the problem continues, please contact us at support@emusic.com';
      case 'USER_NOT_FOUND':
        return 'Sorry, something went wrong - user was not found. If this issue continues, please contact us at support@emusic.com';
      case 'INVALID_USER_ID':
        return 'Sorry, something went wrong - this user ID was not found. If this issue continues, please contact us at support@emusic.com';
      case 'INVALID_PLAN_ID':
      case 'INVALID_PLAN_FAMILY':
      case 'PLAN_NOT_FOUND':
      case 'CREDIT_CARD_IS_SUBSCRIBED_FOR_TRIAL':
        return 'Sorry, something went wrong. If this issue continues, please contact us at support@emusic.com';
      case 'CANT_OVERRIDE_ONE_PLAN_WITH_ANOTHER':
        return 'You are already enrolled in a subscription. The ability to change your plan will be coming shortly. Contact us at support@emusic.com with questions.';
      case'DECLINED_VALIDATION_AUTH':
        return 'Uh oh, payment validation did not go through.  Please re-enter your credit card or select a different payment method';
      case 'BOOSTER_NOT_AVAILABLE':
        return 'Credit Packs not available';
      case 'PURCHASE_DISABLED':
        return 'You have been restricted from purchasing. Please contact support at support@emusic.com for more information';
      case 'COLLECTIBLE_SOLD_OUT':
      case 'INTERNAL_ERROR':
      case 'NO_SESSION':
      case 'ACCESS_DENIED':
      default:
        return 'Sorry, an error has occurred purchasing this item. Please try again! If the problem continues, please contact us at support@emusic.com';
    }
  }

  public purchasePlanRequest(planPricingId: number, promoCode: string | null, utmObj?: any): Observable<PurchasePlanResponse> {
    const requestPayload = {
      planPricingId,
      promoCode,
      utmObj
    };

    return this.restService.postObs<PurchasePlanResponse>('billing/purchasePlan', requestPayload).pipe(
      tap(({responseStatus}: PurchasePlanResponse) => {
        if (responseStatus === 'SUCCESS') {
          // $rootScope.$broadcast('stateChanged'); TODO ?
          this.store.dispatch(UserActions.getSubscriptionDetails());
        }
      })
    );
  }

  getStripePublicKey(currency: string): string {
    // Create a Stripe client.
    const keyMap: any = {
      PRODUCTION: {
        USD: 'pk_live_E9YltHaCK8XfTXIUvEHEHjar',
        CAD: 'pk_live_E9YltHaCK8XfTXIUvEHEHjar',
        EUR: 'pk_live_xqUkyC3i6LrEuVPmRzCiYao8',
        GBP: 'pk_live_KhLWxgvafGyb3rpsZDZoSsJj'
      },
      SANDBOX: {
        USD: 'pk_test_1G89atlC8Q2kmlxl7llUgJ7Q',
        CAD: 'pk_test_1G89atlC8Q2kmlxl7llUgJ7Q',
        EUR: 'pk_test_fFM7YySPNRVeK32pSzaEIfyh',
        GBP: 'pk_test_2a2FSn0VntrmQvLlpidgJKkU',
      }
    };

    return keyMap[this.isProduction ? 'PRODUCTION' : 'SANDBOX'][currency];
  }

  getAuctionStage(nft: CollectibleLite, warningTime: number): AuctionStage {
    if (nft.serverCurrentDate < nft.startDate) {
      return AuctionStage.PRE_BIDDING;
    }

    if (nft.serverCurrentDate > nft.startDate && nft.serverCurrentDate < nft.endDate) {
      let timeLeft = nft.endDate - nft.serverCurrentDate;

      if (warningTime > timeLeft) {
        return AuctionStage.BIDDING_ABOUT_TO_END;
      }

      return AuctionStage.BIDDING;
    }

    if (nft.serverCurrentDate >= nft.endDate) {
      return AuctionStage.POST_BIDDING;
    }

    return AuctionStage.PRE_BIDDING;
  }

  formatUnixTime(unixTime: number): { d: number, h: number, m: number, s: number } {
    const daysLeft = Math.floor((unixTime / (86400 * 1000)));
    const daysLeftMod = unixTime % (86400 * 1000);

    const hoursLeft = Math.floor(daysLeftMod / (3600 * 1000));
    const hoursLeftMod = unixTime % (3600 * 1000);

    const minutesLeft = Math.floor(hoursLeftMod / (60 * 1000));
    const minutesLeftMod = unixTime % (60 * 1000);

    const secondsLeft = Math.floor(minutesLeftMod / 1000);

    return {d: daysLeft, h: hoursLeft, m: minutesLeft, s: secondsLeft};
  }

  getHighestBidLabel(price: number, currency: string): string {
    const currencySymbol = new CurrencyToSymbolPipe().transform(currency);

    if (currencySymbol === '') {
      return price.toFixed(6) + ' ' + currency;
    } else {
      return String(new CurrencyPipe(this.localId).transform(price, currencySymbol));
    }
  }
}
