import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'creditCardIconClass',
  standalone: true,
})
export class CreditCardIconClassPipe implements PipeTransform {
  transform(cardType: string | null): string {
    if (cardType === null) {
      return 'noCard';
    }

    switch (cardType) {
      case 'visaelectron':
        return 'visa';
      case 'visa':
      case 'amex':
      case 'discover':
      case 'mastercard':
        return cardType;
      case 'american express':
        return 'amex';
      default:
        return 'unsupportedCard';
    }
  }
}
