import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CustomImgUrlPipe } from '@pipes/bg-image.pipe';
import { ImageLoadAnimationDirective } from '@directives/image-load-animation.directive';
import { NgOptimizedImage } from "@angular/common";

@Component({
  selector: 'artist-avatar',
  standalone: true,
  imports: [ImageLoadAnimationDirective, CustomImgUrlPipe, NgOptimizedImage],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './artist-avatar.component.html',
  styleUrls: ['./artist-avatar.component.scss']
})
export class ArtistAvatarComponent {
  @Input()
  avatarUrl: string;

  @Input()
  avatarSize: number = 24;

  @Input()
  imageSize: number;

  @Input()
  altText: string;
}
