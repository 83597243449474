import {Component, Inject} from '@angular/core';
import { CommonModule } from '@angular/common';
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";
import {DialogCloseButtonComponent} from "@components/dialog/dialog-close-button/dialog-close-button.component";
import {RouterModule} from "@angular/router";

@Component({
  selector: 'lose-credit-notification-modal',
  standalone: true,
  imports: [CommonModule, DialogCloseButtonComponent, RouterModule],
  templateUrl: './lose-credit-notification-modal.component.html',
  styleUrls: ['./lose-credit-notification-modal.component.scss']
})
export class LoseCreditNotificationModalComponent {
  constructor(@Inject(DIALOG_DATA) public data: {planPricingId: number},
  public dialogRef: DialogRef<LoseCreditNotificationModalComponent>) { }

  closeModal(): void {
    this.dialogRef.close();
  }
}
