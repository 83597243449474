<div id="email__verification" class="modal-container">
  <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

  <div class="modal-content">
    <h2 class="modal-header-title">THANK YOU</h2>
    <div class="body">
      <p>
        For verifying your email address. Upload your collection and discover new music now!
      </p>
    </div>

    <div class="buttons-container">
      <button class="btn red-button" (click)="closeModal()">
        DISCOVER MUSIC
      </button>
    </div>
  </div>
</div>
