import {GeneralResponse} from "../../core/protocol/GeneralResponse";
import {PricingType} from "@services/protocol/Subscription";

export class GetUserDetailsResponse extends GeneralResponse {
  email?: string;
  fullName?: string;
  snid?: string;
  creationDate?: number;
  displayDefaultAvatar?: boolean;
  avatarInitials?: string;
  agreedToTerms: boolean;
  avatarUrl: string;
  passwordSaved: boolean;
  cmsAdmin?: boolean;
  timezone?: string;
  availableCredit: number;
  currency?: string;
  hasCollectibles: boolean;
  freeTracksLeft: number;
  emuTokenPurchaseAllowed: boolean;
  subscriptionPurchaseAllowed: boolean;
  countryCode?: string;
  boosterPromotionEnabled: boolean;
  emuQualified?: boolean;
  purchaseDisabled: boolean;
  tracksStoredInCloud: number;
  verifiedCryptoBidder: boolean;
  userBenefits: UserBenefits;
}

export interface UserBenefits {
  cloudUnlimited: boolean;
  cloudTrackLimit: number;
  concurrentWebSessionLimit: number;
  deviceLimit: number;
  showAds: boolean;
  holdAllowed: boolean;
  pricingType: PricingType;
  boosterAllowed: boolean;
}

export const EmptyUserDetails = {
  agreedToTerms: false,
  availableCredit: 0,
  avatarInitials: null,
  avatarUrl: '/avatar/getAvatar?snid=null',
  balance: null,
  boosterPromotionEnabled: false,
  contentOptInEmails: false,
  countryCode: null,
  creationDate: null,
  currency: null,
  displayName: null,
  eligibleForTrial: false,
  email: null,
  emuQualified: false,
  emuTokenPurchaseAllowed: true,
  freeTracksLeft: 0,
  fullName: null,
  hasCollectibles: false,
  hasConcertTickets: false,
  lastSeen: null,
  message: "NO_SESSION",
  migratedFrom: null,
  offerOptInEmails: false,
  optInToMarketingEmails: false,
  passwordSaved: false,
  paymentMethodSaved: false,
  productOptInEmails: false,
  purchaseDisabled: false,
  responseStatus: "FAILED",
  responseText: "NO_SESSION",
  snid: null,
  subscriptionPurchaseAllowed: true,
  successful: false,
  tracksStoredInCloud: 0,
  userBenefits: null,
  verifiedCryptoBidder: false,
}

export interface UpdateUserEmailPreferencesProps {
  snid: string;
  contentOptInEmails: boolean;
  offerOptInEmails: boolean
  productOptInEmails: boolean
  optInToMarketingEmails: boolean
}
