<mat-dialog-content>
  <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

  <div class="disconnect_modal">
    <h2 class="title">DISCONNECT WALLET</h2>

    <hr class="divider"/>

    <p class="disconnect_text">
      Are you sure you'd like to disconnect your wallet? A connected wallet is required to receive benefits exclusive to
      eMU token holders.
    </p>

    <hr class="divider"/>

    <div class="button_container">
      <div class="left_button" (click)="disconnectWallet()">
        <button type="button" class="btn full-width transparent-button">DISCONNECT</button>
      </div>

      <div class="right_button">
        <button type="button" class="btn red-button full-width" (click)="dialogRef.close()">CANCEL</button>
      </div>
    </div>
  </div>
</mat-dialog-content>
