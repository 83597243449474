<div id="welcome__back__modal" class="modal-container">
  <div class="modal-content">
    <h2 class="modal-header-title">PLEASE NOTE</h2>

    <p class="body">
      We’ve made a few updates to our Terms of Service and wanted to let you know.
      We encourage you to <a class="mobile-and-tablet" target="_blank" href="/terms/mobile">review</a>
      <a class="desktop-only" target="_blank" href="/terms">review</a> the updates and thank you for being a part of
      the eMusic Community.
    </p>

    <div class="buttons-container">
      <button type="button"
              (click)="agreeToTerms()"
              class="btn red-button">
        Accept Terms
      </button>
    </div>
  </div>
</div>
