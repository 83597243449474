import { Injectable } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { NoServiceModalComponent } from '@components/dialog/no-service-modal/no-service-modal.component';
import {
  PaidOnboardingModalComponent
} from '@account/onboarding/paid-onboarding-modal/paid-onboarding-modal.component';
import {
  EmailConfirmationModalComponent
} from '@components/dialog/email-confirmation-modal/email-confirmation-modal.component';
import { GetAppsModalComponent } from '@components/dialog/get-apps-modal/get-apps-modal.component';
import { ReleaseDetailsTrack } from './protocol/Track';
import { WelcomeBackModalComponent } from '@components/dialog/welcome-back-modal/welcome-back-modal.component';

export interface OnboardingSlide {
  backgroundColor?: string;
  title: string;
  subTitle?: string;
  showAppLinks: boolean;
  template: string;
  backgroundUrl?: string;
  list?: string[];
}

@Injectable({
  providedIn: 'root'
})
export class OnBoardingService {
  onboardingSignUpSlides: readonly OnboardingSlide[] = [
    {
      backgroundColor: '#054760',
      title: 'Get this track for free now!',
      subTitle: 'Sign up to eMusic for free, no credit card required.',
      showAppLinks: false,
      template: 'FREE_TRACK'
    },
    {
      backgroundUrl: '../../../../assets/images/paid-on-boarding/img-slide-02@2x.jpg',
      title: 'Free eMusic Benefits',
      list: [
        'Free music storage',
        'Free daily music download',
        `app for iOS and Android - <br> Take your entire collection with you`
      ],
      showAppLinks: false,
      template: 'MINIMAL'
    },
    {
      backgroundUrl: '../../../../assets/images/paid-on-boarding/img-slide-03@2x.jpg',
      title: 'Upload your Collection',
      subTitle: 'Store, manage and play your entire collection from any device, for free.',
      showAppLinks: false,
      template: 'MINIMAL'
    },
    {
      backgroundUrl: '../../../../assets/images/paid-on-boarding/img-slide-04@2x.jpg',
      title: 'Find your Soundtrack',
      subTitle: 'Your entire collection is always at your fingertips with the free eMusic app for iOS or Android ',
      showAppLinks: true,
      template: 'MINIMAL'
    },
  ];

  constructor(private dialog: Dialog) {
  }

  openGetMobileApps(): void {
    this.dialog.open(GetAppsModalComponent, {
      autoFocus: 'first-tabbable',
      closeOnDestroy: true,
      closeOnNavigation: true,
    });
  }

  openNoServiceAllowedModal(): void {
    this.dialog.open(NoServiceModalComponent, {
      autoFocus: 'first-tabbable',
      closeOnDestroy: true,
      closeOnNavigation: true,
    });
  }

  openEmailConfirmationModal(): void {
    this.dialog.open(EmailConfirmationModalComponent, {
      closeOnDestroy: true,
      autoFocus: 'first-tabbable',
      closeOnNavigation: true,
    });
  }

  openPaidOnboardingModal(trackToPurchase: ReleaseDetailsTrack): void {
    this.dialog.open(PaidOnboardingModalComponent, {
      closeOnDestroy: true,
      autoFocus: 'first-tabbable',
      closeOnNavigation: true,
      data: {
        trackToPurchase
      }
    });
  }

  openWelcomeBackModal(): void {
    this.dialog.open(WelcomeBackModalComponent, {
      disableClose: true,
    });
  }
}
