import { createReducer, on } from '@ngrx/store';
import { UserActions } from '../actions/user.actions';
import { UserDetailsState } from '../states/UserDetailsState';
import { CurrencyToSymbolPipe } from '@pipes/our-currency.pipe';
import { CurrencyPipe } from '@angular/common';

export const initialState: UserDetailsState = new UserDetailsState();

export const userReducers = createReducer(initialState,
  on(UserActions.loadUserDetails, (state, userDetails) => {
    const userFromUK = userDetails.countryCode === 'UK' || userDetails.countryCode === 'GB';
    const subscriptionDetails = userDetails.responseText !== 'NO_SESSION' ? userDetails.subscriptionDetails : null;
    const isSignedIn = userDetails.responseText !== 'NO_SESSION';
    const userBenefits = userDetails.userBenefits;
    const hasCloudStorageLeft = userBenefits?.cloudUnlimited ? userBenefits?.cloudUnlimited : userDetails.tracksStoredInCloud < userBenefits?.cloudTrackLimit;

    // Available Credit String
    let creditBalance: string | null = userDetails.availableCredit.toString();
    const creditTemp = creditBalance.split('.');

    if (creditTemp.length === 1 || creditTemp[1].length < 3) {
      creditBalance = userDetails.availableCredit.toFixed(2);
    }

    creditBalance = new CurrencyPipe('en-US').transform(creditBalance, userDetails.currency);

    return {
      ...state,
      ...userDetails,
      userBenefits: {
        ...userBenefits,
        hasCloudStorageLeft: hasCloudStorageLeft,
      },
      subscriptionDetails,
      userFromUK,
      isSignedIn,
      hasSubscription: !!subscriptionDetails,
      creditBalance
    };
  }),

  on(UserActions.setFirstFetch, (state, {firstFetchDone}) => {
    return {...state, firstFetchDone};
  }),

  on(UserActions.loadUserLocation, (state, location) => ({...state, location})),

  on(UserActions.loadAvailableRewards, (state, availableRewards) => {
    const currentRewardProgram = availableRewards.availableRewardList[0];
    const hasCurrentRewardsProgram = state.emuTokenPurchaseAllowed && !!currentRewardProgram;
    const maxClaimableRewardAmountInPeriod = !availableRewards.availableRewardList || !currentRewardProgram ? '' :
      `${currentRewardProgram.maxTimesClaimableInPeriod * currentRewardProgram.rewardPerTimePerformed} ${new CurrencyToSymbolPipe().transform(currentRewardProgram.rewardType.toString())} tokens`;

    return {
      ...state,
      hasCurrentRewardsProgram,
      availableRewards: {
        ...availableRewards,
        maxClaimableRewardAmountInPeriod
      },
    };
  }),

  on(UserActions.setPathIntent, (state, {pathIntent}) => ({...state, pathIntent})),

  on(UserActions.loadSubscriptionDetails, (state, subscriptionDetails) => {
    const hasSubscription = subscriptionDetails.responseText !== 'SUBSCRIPTION_NOT_FOUND';
    const isOnHold = subscriptionDetails.subscription?.freezeDate !== null && subscriptionDetails.subscription?.status === 'FROZEN';
    const isChosenPlanOpenAccessMMC = subscriptionDetails.subscription?.plan.planId === 444130000;
    const isUserDelinquent = subscriptionDetails.subscription?.status === 'DELINQUENT';

    return {
      ...state,
      subscriptionDetails: {...subscriptionDetails, isOnHold, isChosenPlanOpenAccessMMC},
      hasSubscription,
      isUserDelinquent
    };
  }),

  on(UserActions.setTracksStoredInCloud, (state, {tracks}) => {
    const hasCloudStorageLeft = state.userBenefits?.cloudUnlimited ? state.userBenefits?.cloudUnlimited : state.tracksStoredInCloud < state.userBenefits?.cloudTrackLimit;

    return {
      ...state,
      tracksStoredInCloud: tracks,
      hasCloudStorageLeft,
    };
  }),

  on(UserActions.setEligibleForMembershipBonus, (state, {eligibleForMembershipBonus}) => ({
    ...state,
    eligibleForMembershipBonus
  })),

  on(UserActions.setUserDeviceList, (state, deviceListResponse) => {
    let userHasMobileApp = false;

    if (deviceListResponse.deviceList.length) {
      userHasMobileApp = deviceListResponse.deviceList.findIndex((device) => device.platform === 'MOBILE') !== -1;
    }

    return {...state, deviceDetails: {...deviceListResponse, userHasMobileApp}};
  }),

  on(UserActions.setUserLastRoute, (state, {lastRoute}: { lastRoute: string }) => ({...state, lastRoute})),

  on(UserActions.resetUserLastRoute, (state) => ({...state, lastRoute: '/'})),

  on(UserActions.setUserPromoCode, (state, {activePromo}: { activePromo: string }) => ({...state, activePromo})),

  on(UserActions.clearUserPromoCode, (state) => ({...state, activePromo: null})),

  on(UserActions.setUploadLimitReached, (state, {reached}) => ({...state, uploadLimitReached: reached})),

  //|- Access State
  on(UserActions.setAccessError, (state, {accessError}: { accessError: string | null }) => ({
    ...state,
    accessState: {...state.accessState, accessError}
  })),

  on(UserActions.setFreemiumFlow, (state, {isFreemiumSignUp}) => ({
    ...state,
    accessState: {...state.accessState, isFreemiumSignUp}
  })),

  on(UserActions.accessInProgress, (state, {inProgress}) => ({
    ...state,
    accessState: {...state.accessState, accessInProgress: inProgress}
  })),
  on(UserActions.resetUserdetailsstate, () => new UserDetailsState()),
);
