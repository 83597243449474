import {Component, HostBinding, Input} from "@angular/core";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'emusic-loader-spinner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: 'emusic-loader-spinner.component.html',
  styleUrls: ['emusic-loader-spinner.component.scss'],
})
export class EmusicLoaderSpinnerComponent {
  @Input()
  enableOpacity = false;

  @Input()
  loaderType: 'DOTS' | 'E-DOTS' = 'DOTS';

  @Input()
  customOpacity = 0.8;

  @Input()
  @HostBinding('style.height')
  height: string;
}
