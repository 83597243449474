<div class="onboarding-modal-container modal-container" [ngClass]="{error: errorMessagesArray.length}"
     *ngrxLet="vm$ as vm">
  <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

  <div class="modal-content">
    <onboarding [stage]="stage"
                [redirectAfter]="!!data.redirectAfter"
                [title]="title"></onboarding>

    <div class="not-now" (click)="closeModal(false)">
      <button class="btn" type="button">
        Not Now
      </button>
    </div>
  </div>

  <modal-error-message *ngIf="vm.accessState.accessError"
                       [errorMessages]="[vm.accessState.accessError]"></modal-error-message>

  <div class="password-updated-successfully" *ngIf="passwordUpdated">
    <p>Password updated. Please sign in again to continue</p>
  </div>

  <emusic-loader-spinner [enableOpacity]="true" [height]="'100%'" loaderType="E-DOTS"
                         *ngIf="vm.accessState.accessInProgress"></emusic-loader-spinner>
</div>
