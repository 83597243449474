<div class="onboarding-container" [ngSwitch]="stage">
  <div class="sign_in_wrapper" *ngSwitchCase="OnboardingStage.SIGN_IN">
    <sign-in [title]="title" [redirectAfter]="redirectAfter"></sign-in>

    <p class="footnote">
      Whoa... wait, you're not a member yet?

      <button class="btn link" type="button" (click)="stage = OnboardingStage.SIGN_UP">
        Join Now!
      </button>
    </p>
  </div>

  <div class="sign_up_wrapper" *ngSwitchCase="OnboardingStage.SIGN_UP">
    <sign-up [title]="title"></sign-up>

    <p class="footnote">
      Already have an account with us?

      <button type="button" class="btn link" (click)="stage = OnboardingStage.SIGN_IN">
        Sign In!
      </button>
    </p>
  </div>
</div>
