<div class="unsupported_wrapper">
  <p class="unsupported_title">
    We're sorry
  </p>

  <p class="unsupported_title">
    Your browser is not supported
  </p>

  <p class="unsupported_details">
    To enjoy music on eMusic please install the latest version of
    <a target="_blank" href="https://www.google.com/chrome/">Google Chrome</a>,
    <a target="_blank" href="https://www.mozilla.org/firefox">Mozilla Firefox</a>, <a
    href="https://www.microsoft.com/edge">Microsoft
    Edge</a>,
    or <a target="_blank" href="https://www.apple.com/safari/">Safari</a>.
  </p>

  <img class="emusic-logo" width="155" height="45" src="assets/images/logos/emusic-logo.svg" alt="eMusic">
</div>
