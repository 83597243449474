<ng-container *ngrxLet="vm$ as vm">
  <div class="token-wallet-state">
    <div class="token-wallet-wrapper" [ngClass]="{'remove-borders': removeBorders}">
      <ng-container *ngIf="template === 'WALLET' && tokenWallet">
        <div class="wallet-info">
          <i class="icon" [ngClass]="tokenWallet.type"></i>

          <p class="address" [title]="tokenWallet.address">
            {{tokenWallet.address | truncateWalletAddress:12}}
          </p>

          <i class="icon wallet-connected" *ngIf="tokenWallet.isActiveWallet"></i>
        </div>

        <button class="btn grayish-button" type="button"
                aria-label="Disconnect Wallet"
                (click)="disconnectWallet(tokenWallet)">
          Disconnect
        </button>
      </ng-container>

      <ng-container *ngIf="!!notConnectedWalletAddress && template === 'NOT-CONNECTED'">
        <div class="wallet-info">
          <i class="icon" [ngClass]="dappBrowserType"></i>

          <p class="address" [title]="notConnectedWalletAddress">
            {{notConnectedWalletAddress | truncateWalletAddress:12}}
          </p>
        </div>

        <button class="btn grayish-button" type="button"
                *ngIf="!isMobile"
                aria-label="Connect wallet"
                (click)="connectWallet(vm.isSignedIn)">
          Connect
        </button>
      </ng-container>

      <ng-container *ngIf="template === 'NO-WALLET'">
        <div class="wallet-info no-wallet">
          <p>No Wallet Connected</p>
        </div>

        <button class="btn grayish-button" type="button"
                *ngIf="!isMobile"
                aria-label="Connect wallet"
                (click)="connectWallet(vm.isSignedIn)">
          Connect
        </button>
      </ng-container>
    </div>
  </div>

  <p class="error" *ngIf="showErrors && vm.errorMessage">
    <i class="icon grey-warning"></i>

    {{vm.errorMessage}}
  </p>
</ng-container>
