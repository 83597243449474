import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

/**
 * @deprecated Use ngrxLet instead
 */
@Directive({
  selector: '[ngVar]',
  standalone: true,
})
export class VarDirective {

  private created = false;

  @Input()
  set ngVar(context: any) {
    this.context.$implicit = this.context.ngVar = context;
    this.updateView();
  }

  context: any = {};

  constructor(private vcRef: ViewContainerRef, private templateRef: TemplateRef<any>) {
  }

  updateView() {
    if (this.created) return;
    this.vcRef.clear();
    this.vcRef.createEmbeddedView(this.templateRef, this.context);
    this.created = true;
  }
}
