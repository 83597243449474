import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SafeHtmlPipe} from "@pipes/safe-html.pipe";

@Component({
  selector: 'modal-error-message',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, SafeHtmlPipe],
  templateUrl: './modal-error-message.component.html',
  styleUrls: ['./modal-error-message.component.scss']
})
export class ModalErrorMessageComponent {
  @Input()
  errorMessages: string[] = [];
}
