import {Directive, ElementRef, HostListener, inject, Renderer2} from '@angular/core';

@Directive({
  selector: 'img[imageLoadAnimation]',
  standalone: true,
})
export class ImageLoadAnimationDirective {
  elRef = inject(ElementRef);
  renderer = inject(Renderer2);
  imageRef: HTMLImageElement;

  constructor() {
    this.imageRef = this.elRef.nativeElement;
    this.renderer.setStyle(this.imageRef, 'opacity', 0);
    this.renderer.addClass(this.imageRef, 'js-empty-space-filler');
  }

  @HostListener('load')
  handleOnLoad(): void {
    this.renderer.removeStyle(this.imageRef, 'opacity');
    this.renderer.addClass(this.imageRef, 'image_load_animation_js');

    setTimeout(() => {
      this.renderer.removeClass(this.imageRef, 'image_load_animation_js');
    }, 1500);
  }
}
