import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogModule, DialogRef} from "@angular/cdk/dialog";
import {DialogCloseButtonComponent} from "@components/dialog/dialog-close-button/dialog-close-button.component";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {RadioButtonComponent} from "@components/forms/radio-button/radio-button.component";
import {Store} from "@ngrx/store";
import {SubscriptionService} from "@services/subscription.service";
import {StSelectComponent} from "@components/forms/st-select/st-select.component";
import {SnackBarService} from "@components/snack-bar/snack-bar.service";
import {AccountService} from "@account/account.service";

interface Issue {
  name: string;
  value: number;
}

interface ReadyToHelForm {
  issue: FormControl<string>;
  comments: FormControl<string>;
}

@Component({
  selector: 'ready-to-help-modal',
  standalone: true,
  imports: [CommonModule, DialogModule, DialogCloseButtonComponent, ReactiveFormsModule, RouterModule, RadioButtonComponent, StSelectComponent],
  templateUrl: './ready-to-help-modal.component.html',
  styleUrls: ['./ready-to-help-modal.component.scss', '../can-we-help-modal/can-we-help-modal.component.scss']
})
export class ReadyToHelpModalComponent {
  issues: Issue[] = [
    {
      name: 'I am having a hard time downloading music...',
      value: 0
    },
    {
      name: 'I am having trouble listening to my music...',
      value: 1
    },
    {
      name: 'I am having trouble with the website/app...',
      value: 2
    },
    {
      name: 'I am having trouble with my account...',
      value: 3
    },
    {
      name: 'I am having billing issues...',
      value: 4
    },
    {
      name: 'Other...',
      value: 5
    },
  ];

  formGroup: FormGroup<ReadyToHelForm>;
  requestInProgress = false;

  constructor(public dialogRef: DialogRef<boolean, ReadyToHelpModalComponent>,
              private store: Store,
              private fb: FormBuilder,
              private snackService: SnackBarService,
              private accountService: AccountService,
              private subscriptionService: SubscriptionService) {
    this.formGroup = fb.nonNullable.group({
      issue: fb.nonNullable.control<string>('', [Validators.required]),
      comments: fb.nonNullable.control<string>(''),
    })
  }

  back(): void {
    this.closeModal();
    this.subscriptionService.openCanWeHelpModal();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  submitForm(): void {
    if (this.requestInProgress) {
      return;
    }

    if (this.formGroup.invalid) {
      this.snackService.showErrorNotificationBanner('Please select a reason and write some comments', false, 3000);
      return;
    }

    const {issue, comments} = this.formGroup.getRawValue();
    this.requestInProgress = true;
    this.accountService.contactSupport('User requesting support from cancellation flow', `Selected issue: ${issue} <br />Comments: ${comments}`)
      .subscribe(() => {
        this.requestInProgress = false;
        this.subscriptionService.openSupportFormSubmittedModal();
        this.closeModal();
      });
  }
}
