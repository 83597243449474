import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {BillingDetailsActions} from "../actions/billing.actions";
import {map, switchMap, tap} from "rxjs";
import {BillingService} from "@services/billing.service";
import {GetPlanDetailsResponse, GetPlanDetailsResponseProps} from "../../protocol/GetPlanDetailsResponse";
import {UserActions} from "../actions/user.actions";
import {Router} from "@angular/router";
import {PromoService} from "@services/promo.service";

@Injectable()
export class BillingEffects {
  getPlanList$ = this.getPlanListEffect();
  getSavedPaymentDetails$ = this.getSavedPaymentDetailsEffect();
  getPlanListWithPromotion$ = this.getPlanListWithPromotionFx();

  constructor(private actions$: Actions,
              private store: Store, private billingService: BillingService,
              private promoService: PromoService,
              private router: Router) {
  }

  private getPlanListEffect() {
    return createEffect(() => this.actions$.pipe(
      ofType(BillingDetailsActions.getPlanList),
      switchMap((planDetailsResponseProps: GetPlanDetailsResponseProps) => {
        return this.billingService.getPlanList(planDetailsResponseProps).pipe(
          tap(({eligibleForMembershipBonus}) => this.store.dispatch(UserActions.setEligibleForMembershipBonus({eligibleForMembershipBonus}))),
          map(response => BillingDetailsActions.loadPlanList({planDetailsResponse: response, withPromoCode: false}))
        )
      })
    ));
  }

  private getPlanListWithPromotionFx() {
    return createEffect(() => this.actions$.pipe(
      ofType(BillingDetailsActions.getPlanListWithPromotion),
      switchMap(({variation, userSnid, promoCode, routeDetails}) => this.billingService.getPlanList({
        planFamilyList: null,
        planName: null,
        promoCode
      }).pipe(
        tap(({eligibleForMembershipBonus}) => this.store.dispatch(UserActions.setEligibleForMembershipBonus({eligibleForMembershipBonus}))),
        tap((response: GetPlanDetailsResponse) => {
          // savePromotionDetails
          if (response.responseStatus !== 'SUCCESS') {
            const isPromoBarIsOpen = localStorage.getItem('HIDE_PROMO_BAR') === null;

            this.store.dispatch(BillingDetailsActions.setPromoBarIsOpen({isOpen: isPromoBarIsOpen}))
            this.store.dispatch(BillingDetailsActions.setActivePromo({activePromoCode: null}));
            this.store.dispatch(BillingDetailsActions.setPromoMap({
              promoKey: promoCode,
              promotion: null,
              promoUrl: null,
              userSnid: null,
              variation: null
            }));
            this.promoService.parseFailedPromoMessage(response.responseText);

            this.router.navigate(['/']);

            return;
          }

          const promotion = response.promotionDetailsResponse.promotion;
          const currentVariation = variation ? variation : 0;
          const promoUrl = this.promoService.promoSlideMap()[promoCode][variation].promoUrl ?? null;

          this.store.dispatch(BillingDetailsActions.setActivePromo({activePromoCode: promoCode}));
          this.store.dispatch(BillingDetailsActions.setPromoMap({
            promoKey: promoCode,
            promotion,
            promoUrl,
            variation: currentVariation,
            userSnid
          }));
          this.store.dispatch(BillingDetailsActions.setPromoBarIsOpen({isOpen: true}));

          if (promoUrl) {
            this.promoService.redirectToPromoLanding(promoUrl, routeDetails);
          }
        }),
        map(response => BillingDetailsActions.loadPlanList({planDetailsResponse: response, withPromoCode: true}))
      )),
    ));
  }

  private getSavedPaymentDetailsEffect() {
    return createEffect(() => this.actions$.pipe(
      ofType(BillingDetailsActions.getSavedPaymentDetails),
      switchMap(() => this.billingService.getSavedPaymentDetails().pipe(
        map((paymentDetails) => BillingDetailsActions.setSavedPaymentDetails(paymentDetails)),
      ))
    ));
  }
}
