import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {DialogRef} from "@angular/cdk/dialog";

@Component({
  selector: 'dialog-close-button',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './dialog-close-button.component.html',
  styleUrls: ['./dialog-close-button.component.scss']
})
export class DialogCloseButtonComponent {
  @Input()
  dialogRef: MatDialogRef<any> | DialogRef<any, any>;

  @Input()
  color: 'black' | 'white' = 'black';

  closeDialog(): void {
    if (!this.dialogRef) {
      return;
    }

    this.dialogRef.close();
  }
}
