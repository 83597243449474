import { inject, Injectable, NgZone } from '@angular/core';
import { AccountService } from '@account/account.service';
import { RestService } from '../ws/rest.service';
import { MetamaskConnectionDetails } from '@public-pages/nft/protocol/CollectibleLite';
import { SnackBarService } from '@components/snack-bar/snack-bar.service';
import { GetTokenWalletListResponse, WalletError } from './protocol/GetTokenWalletListResponse';
import { WhichMobile } from '../utils/mobile-utils';
import { OnboardingStage } from '@account/onboarding/protocol/OnboardingStage';
import { GeneralResponse } from '../protocol/GeneralResponse';
import { InitiateWalletSignatureResponse } from './protocol/InitWalletSignatureResponse';
import { TokenWallet, TokenWalletType } from './protocol/TokenWallet';
import { MatDialog } from '@angular/material/dialog';
import {
  DisconnectWalletModalComponent
} from '@components/crypto/disconnect-wallet-modal/disconnect-wallet-modal.component';
import { catchError, EMPTY, from, map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { GetTokenBoosterListResponse } from '@services/protocol/GetTokenBoosterListResponse';
import Web3 from 'web3';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Web3Actions } from '@files-ngrx/actions/web3.actions';
import { WINDOW_TOKEN } from "../tokens/browser.tokens";
import { environment } from "../../../environments/environment";
import { selectWallet, selectWeb3 } from "@files-ngrx/selectors/web3.selectors";
import { TransactionConfig } from "web3-core";
import { GetTokenConfigurationsResponse } from "@services/protocol/Web3";
import { Web3State } from "@files-ngrx/states/web3.state";
import { cloneDeep } from "lodash";
import { truncNumber } from "../utils/common-functions";
import { GetMetamaskModalComponent } from '@components/crypto/get-metamask-modal/get-metamask-modal.component';

@Injectable({
  providedIn: 'root'
})

export class Web3Service {
  private readonly ngZone = inject(NgZone);
  private readonly isProduction = environment.production;
  private readonly accountService = inject(AccountService);
  private readonly restService = inject(RestService);
  private readonly store = inject(Store);
  private readonly snackBackService = inject(SnackBarService);
  private readonly matDialog = inject(MatDialog);
  private readonly window = inject(WINDOW_TOKEN);
  private readonly connectedWallet = new Subject<void>();
  private readonly web3: Web3 = new Web3(Web3.givenProvider);

  constructor() {
    this.accountService.observeSignInAction().subscribe(async () => this.checkForWeb3(true));
    this.accountService.observeSignOutAction().subscribe(async () => {
      this.clearWallet(true);
      this.setHasConnectedWallet(false);
    });
  }

  reportConnectedWallet(): void {
    this.connectedWallet.next();
  }

  // TODO
  checkForAlbumRedirect() {
    // if (this.albumRedirectObj) {
    //   let albumId = this.albumRedirectObj.release.id;
    //   $location.path('album/' + albumId);
    // }
  }

  listenForConnectedWallet(): Observable<void> {
    return this.connectedWallet.asObservable();
  }

  disconnectWallet(walletId: number) {
    this.store.dispatch(Web3Actions.disconnectWallet({walletId}));
  }

  setHasConnectedWallet(isConnected: boolean): void {
    this.store.dispatch(Web3Actions.setHasConnectedWallet({isConnected}));
  }

  clearWallet(resetAll = false): void {
    this.store.dispatch(Web3Actions.clearWallet({resetAll}));
  }

  checkForWeb3(isSignedIn: boolean): void {
    const ethInWindow = !!(this.window as any)['ethereum'];

    if (isSignedIn) {
      this.refreshWalletList();
    }

    if (isSignedIn && ethInWindow) {
      this.bindWeb3UpdateEvent();
    }
  }

  refreshWalletList(): void {
    const ethInWindow = !!(this.window as any)['ethereum'];
    this.store.dispatch(Web3Actions.refreshWalletList({ethInWindow}));
  }

  refreshWalletListObs(): Observable<any> {
    return this.getWalletList().pipe(
      tap((response) => {
        if (!response.successful) {
          this.broadCastWalletError({reasonCode: response.responseText});
        }

        this.assignWallet();
        this.store.dispatch(Web3Actions.setTokenWalletList({response}));
      }),
    );
  }

  assignWallet(): void {
    this.store.dispatch(Web3Actions.assignWallet());
  }

  getCurrentWalletObs(): Observable<TokenWallet | null> {
    return from(this.web3Instance().eth.requestAccounts()).pipe(
      switchMap((accounts) => this.store.select(selectWeb3).pipe(
        first(),
        map(state => {
          const newAssignedWalletState = Web3Service.getAssignedWalletState(state, accounts);

          return newAssignedWalletState.wallet;
        })
      ))
    );
  }

  static getAssignedWalletState(state: Web3State, accounts: string[]): Web3State {
    const newState: Web3State = cloneDeep(state);

    if (accounts[0]) {
      newState.notConnectedWalletAddress = accounts[0];
    }

    if (!newState.walletList) {
      return state;
    }

    newState.wallet = null;

    if (accounts[0]) {
      for (const wallet of newState.walletList) {
        wallet.isActiveWallet = wallet.address.toLowerCase() === accounts[0].toLowerCase();

        if (wallet.isActiveWallet) {
          newState.notConnectedWalletAddress = null;
          newState.wallet = cloneDeep(wallet);
        }
      }
    }

    if (accounts.length > 0) {
      newState.hasExposedWeb3Account = true;
    }

    if (newState.wallet) {
      newState.notConnectedWalletAddress = null;
      newState.wallet.balance.EMU = truncNumber(newState.wallet.balance.EMU);
      newState.walletError = null;
      newState.hasConnectedWallet = true;
    }

    return newState;
  }

  bindWeb3UpdateEvent(): void {
    (this.web3.currentProvider as any).on('accountsChanged', () => {
      this.ngZone.run(() => {
        this.assignWallet();
      })
    });

    (this.web3.currentProvider as any).on('chainChanged', () => {
      this.ngZone.run(() => {
        this.assignWallet();
      })
    });
  }

  hasMetaMaskInstalled(): boolean {
    return (this.web3.currentProvider as any).isMetaMask
  }

  showGetDappBrowserModal() {
    return this.matDialog.open(GetMetamaskModalComponent, {
      width: '100%',
      maxWidth: '528px',
      panelClass: ['modal', 'short_bottom_padding']
    });
  }

  switchEthereumChain(metamaskConnectionDetails: MetamaskConnectionDetails): Observable<any> {
    const etherum: any = (this.window as any)['ethereum'];
    const request: ({method, params}: { method: any, params: any }) => Promise<any> = etherum.request;

    return from(request({
      method: 'wallet_switchEthereumChain',
      params: [{chainId: metamaskConnectionDetails.chainId}],
    })).pipe(
      catchError((switchError: any) => {
        if (switchError.code === 4902) {
          return this.addEthereumChain(metamaskConnectionDetails).pipe(catchError(error => {
            this.snackBackService.showErrorNotificationBanner(error.message);
            throw error;
          }));
        } else {
          this.snackBackService.showErrorNotificationBanner(switchError.message)
          throw switchError;
        }
      }),
    );
  }

  initiateSignatureObs(account: string | null): Observable<any> {
    return of(account).pipe(
      switchMap((account) => {
        if (!account) {
          return from(this.web3Instance().eth.requestAccounts()).pipe(map(accounts => accounts[0] ?? null));
        }

        return of(account);
      }),
      switchMap((connectedAccount: string | null) => {
        if (!connectedAccount) {
          return EMPTY;
        }

        this.setDefaultAccount(connectedAccount);

        return this.initiateWalletSignature().pipe(
          switchMap(response => {
            if (!response.successful) {
              this.broadCastWalletError({reasonCode: response.responseText});

              return EMPTY;
            }

            return this.web3PersonalSign(response.messageToSign, connectedAccount).pipe(
              switchMap(signedMessage => {
                return this.web3PersonalEcRecover(response.messageToSign, signedMessage).pipe(
                  switchMap((recoveredAddress) => {
                    if (recoveredAddress && recoveredAddress.toLowerCase() !== connectedAccount.toLowerCase()) {
                      throw Error('SIGNATURE_MISMATCH');
                    }

                    return this.addWalletUsingSignature(signedMessage, connectedAccount, response.salt);
                  }),
                )
              }),
              tap(response => {
                if (!response.successful) {
                  this.broadCastWalletError({reasonCode: response.responseText});
                  this.snackBackService.showErrorNotificationBanner(this.getWalletError(response.responseText));
                  return;
                }

                this.reportConnectedWallet();
                this.checkForAlbumRedirect();
              }),
            );
          }),
          catchError((e) => {
            this.broadCastWalletError({reasonCode: e.message});
            throw e;
          })
        );
      })
    )
  }

  addEthereumChain(metamaskConnectionDetails: MetamaskConnectionDetails): Observable<any> {
    const etherum: any = (this.window as any)['ethereum'];
    const request: ({method, params}: { method: any, params: any }) => Promise<any> = etherum.request;

    return of(metamaskConnectionDetails.rpcUrls[0]).pipe(
      tap((payload) => {
        if (payload === null) {
          throw new Error('Network already exists in Metamask');
        }
      }),
      switchMap(() => from(request({
          method: 'wallet_addEthereumChain',
          params: [{
            chainId: metamaskConnectionDetails.chainId,
            chainName: metamaskConnectionDetails.chainName,
            nativeCurrency: metamaskConnectionDetails.nativeCurrency,
            rpcUrls: metamaskConnectionDetails.rpcUrls,
            blockExplorerUrls: metamaskConnectionDetails.blockExplorerUrls
          }]
        }))
      ),
    )
  }

  watchAsset(asset: any): Observable<any> {
    const etherum: any = (this.window as any)['ethereum'];
    const request: ({method, params}: { method: any, params: any }) => Promise<any> = etherum.request;

    return from(request({
      method: 'wallet_watchAsset',
      params: {
        type: asset.type,
        options: {
          address: asset.address,
          symbol: asset.symbol,
          decimals: 0,
          image: asset.image,
        },
      },
    })).pipe(
      tap(success => {
        if (success) {
          this.snackBackService.showBlueNotificationBanner('Contract ' + asset.address + ' was successfully added to your wallet');
        } else {
          throw new Error('User rejected the request.');
        }
      }),
      catchError((error: any) => {
        this.snackBackService.showErrorNotificationBanner(error.message);

        return EMPTY;
      })
    );
  }

  getDappLogoType(dappType?: TokenWalletType): string {
    const dapp = dappType ? dappType : this.getDappBrowserType();

    switch (dapp) {
      case 'METAMASK':
        return 'metamask_logo_png METAMASK';
      case 'COINBASE':
        return 'coinbase_logo_png COINBASE';
      default:
        if (WhichMobile.any()) {
          return 'coinbase_logo_png COINBASE';
        } else {
          return 'metamask_logo_png METAMASK';
        }
    }
  }

  getDappBrowserType(): string {
    if (!(this.window as any)['ethereum']) {
      return 'UNKNOWN';
    }

    const provider = this.web3.givenProvider;

    if (provider.isToshi || provider.isCipher) {
      return 'COINBASE';
    } else if (provider.isMetaMask) {
      return 'METAMASK';
    } else {
      return 'UNKNOWN'
    }
  }

  // TODO this should be an effect
  connectWallet(isSignedIn: boolean, metamaskConnectionDetails?: MetamaskConnectionDetails): Observable<any> {
    if (!isSignedIn) {
      this.accountService.openOnboardingModal({stage: OnboardingStage.SIGN_IN}).pipe(
        first(),
      ).subscribe(signedIn => {
        this.connectWallet(signedIn, metamaskConnectionDetails);
      });

      return EMPTY;
    }

    return this.checkWeb3ConnectionObs(true, metamaskConnectionDetails);
  }

  checkWeb3ConnectionObs(directConnect: boolean, metamaskConnectionDetails?: MetamaskConnectionDetails): Observable<any> {
    const etherum: any = (this.window as any)['ethereum'];

    if (!etherum) {
      this.showGetDappBrowserModal();
      throw Error('WEB3_UNDEFINED');
    }

    try {
      return this.store.select(selectWallet).pipe(
        first(),
        switchMap(wallet => {
          // This will return the connected accounts if they exists
          const result = !WhichMobile.any() ? from(this.web3.eth.requestAccounts()).pipe(
            map((accounts: string[]) => accounts[0] ?? null),
            switchMap(account => {
              if (!wallet && directConnect) {
                return this.checkWalletNetwork(true, metamaskConnectionDetails).pipe(
                  switchMap(() => this.initiateSignatureObs(account)),
                )
              } else if (!wallet && !directConnect) {
                // this.showUnlockWalletModal() TODO
                return EMPTY;
              } else {
                return this.checkWalletNetwork(true, metamaskConnectionDetails);
              }
            })
          ) : of(null);

          return result.pipe(
            switchMap(() => this.refreshWalletListObs()),
            switchMap(() => this.getCurrentWalletObs()),
            switchMap((newWallet) => this.doesBrowserWalletMatchConnectedWalletObs(newWallet)),
            map(() => {
              return {message: 'WALLET_READY', currentProvider: this.getDappBrowserType()};
            }),
            first(),
          );
        }),
      );

    } catch (error: any) {
      if (error === 'User rejected provider access') {
        this.snackBackService.showNotificationBanner(
          'You must give provider access to eMusic in order to continue', [], true);
      }

      throw error;
    }
  }

  checkWeb3Connection(directConnect: boolean, metamaskConnectionDetails: MetamaskConnectionDetails): void {
    this.store.dispatch(Web3Actions.checkWeb3Connection({directConnect, metamaskConnectionDetails}));
  }

  checkWalletNetwork(showToasterError: boolean, metamaskConnectionDetails?: MetamaskConnectionDetails): Observable<any> {
    if (metamaskConnectionDetails) {
      return this.switchEthereumChain(metamaskConnectionDetails);
    }

    return from(this.web3.eth.net.getId()).pipe(
      tap(netId => {
        let networkResponse: any = null;

        switch (netId) {
          case 1:
            if (!this.isProduction) {
              networkResponse = {
                reasonCode: 'WRONG_NETWORK', networkName: 'the Main Ethereum'
              };
            }
            break;
          case 2:
            networkResponse =
              {reasonCode: 'WRONG_NETWORK', networkName: 'the deprecated Morden'};
            break;
          case 3:
            if (this.isProduction) {
              networkResponse = {
                reasonCode: 'WRONG_NETWORK', networkName: 'the Ropsten'
              };
            }
            break;
          case 4:
            networkResponse = {
              reasonCode: 'WRONG_NETWORK', networkName: 'the Rinkeby'
            };
            break;
          case 42:
            networkResponse = {
              reasonCode: 'WRONG_NETWORK', networkName: 'the Kovan'
            };
            break;
          case 137:
            if (!this.isProduction) {
              networkResponse = {
                reasonCode: 'WRONG_NETWORK', networkName: "the Main Polygon"
              };
            }
            break;
          case 80001:
            if (this.isProduction) {
              networkResponse = {
                reasonCode: 'WRONG_NETWORK', networkName: "the Mumbai"
              };
            }
            break;
          default:
            networkResponse = {
              reasonCode: 'WRONG_NETWORK', networkName: 'an unknown'
            };
        }

        if (networkResponse) {
          if (showToasterError) {
            this.snackBackService.showErrorNotificationBanner(this.getWalletError(networkResponse.reasonCode,
              networkResponse.networkName));
          }

          this.broadCastWalletError(networkResponse);

          throw Error(networkResponse.networkName);
        }
      }),
    );
  }

  setDefaultAccount(defaultAccount: string | null): void {
    this.store.dispatch(Web3Actions.setDefaultAccount({defaultAccount}));
  }

  initiateSignature(account: string): void {
    this.store.dispatch(Web3Actions.initiateSignature({connectedAccount: account}));
  }

  doesBrowserWalletMatchConnectedWalletObs(wallet: TokenWallet | null): Observable<boolean> {
    if (!wallet) {
      throw Error('NO_WALLET_SAVED');
    }

    return from(this.web3.eth.requestAccounts()).pipe(
      map((accounts: string[]) => {
        if (wallet.address !== accounts[0].toLowerCase()) {
          this.snackBackService.showErrorNotificationBanner(
            `Your Connected Wallet address does not match your
                     MetaMask wallet address - Please use MetaMask to
                     login to you Connected Wallet address`, false
          );

          throw Error('WALLETS_DONT_MATCH');
        }

        return true;
      }),
    );
  }

  broadCastWalletError(error: WalletError) {
    this.store.dispatch(Web3Actions.broadcastWalletError({error}));
  }

  getWalletError(reasonCode: string, networkName?: string): string {
    switch (reasonCode) {
      case 'WRONG_NETWORK':
        if (this.isProduction) {
          return `Your wallet is on a Test Network. Connect
                            to the MAIN network and try again`;
        } else {
          return `Your wallet is on the  ${networkName}
                            Network. Connect to the Ropsten test network and try again`;
        }
      case 'METAMASK_LOCKED':
        return `Please login to MetaMask`;
      case 'WALLETS_DONT_MATCH':
        return `Your Connected Wallet address does not match your
                    MetaMask wallet address - Please use MetaMask to
                    login to you Connected Wallet address`;
      case 'SIGNATURE_MISMATCH':
        return `Failed to validate signature, please try again`;
      case 'WALLET_ALREADY_EXISTS':
        return `The wallet you've tried to add is already connected
                        to a different account. Please select a new wallet and try again`;
      case '':
        return '';
      default:
        return `Something went wrong, please try again`;
    }
  }

  // TODO
  openDisconnectWalletModal(wallet: TokenWallet | null) {
    return this.matDialog.open(DisconnectWalletModalComponent, {
      width: '100%',
      maxWidth: '528px',
      panelClass: ['modal'],
      data: {
        wallet: wallet
      }
    });
  }

  getGasPriceInGWEI(): Observable<any> {
    return this.getTokenConfigurations().pipe(
      switchMap((tokenConfigs) => {
        return this.restService.externalGet(tokenConfigs.gasPriceEstimateUrl);
      }),
    );
  }

  toWei(eth: any) {
    return this.web3.utils.toWei(eth, 'ether');
  }

  sendTransaction(request: TransactionConfig): Observable<any> {
    return from(this.web3.eth.sendTransaction(request));
  }

  public web3Instance(): Web3 {
    return this.web3;
  }

  // Web3 API calls
  web3PersonalSign(dataToSign: string, address: string, password = ''): Observable<string> {
    return from(this.web3.eth.personal.sign(dataToSign, address, password)).pipe(
      catchError(() => {
        throw Error('SIGNATURE_MISMATCH');
      })
    );
  }

  web3PersonalEcRecover(dataThatWasSigned: string, signature: string): Observable<string> {
    return from(this.web3.eth.personal.ecRecover(dataThatWasSigned, signature)).pipe(
      catchError(() => {
        throw Error('SIGNATURE_MISMATCH');
      }),
    );
  }

  // Server calls

  getTokenConfigurations(): Observable<GetTokenConfigurationsResponse> {
    return this.restService.postObs('token/getTokenConfigurations');
  }

  getTokenBoosterList(): Observable<GetTokenBoosterListResponse> {
    return this.restService.postObs<GetTokenBoosterListResponse>('token/getTokenBoosterList', {});
  }

  disconnectWalletServerCall(walledId: number): Observable<GeneralResponse> {
    return this.restService.postObs<GeneralResponse>('token/disconnectWallet', {id: walledId});
  }

  reportPendingTrackTokenTransaction(defaultWalletIdForPayment: string, transactionId: string, trackId: number): Observable<GeneralResponse> {
    const payload = {
      walletId: defaultWalletIdForPayment,
      trackId,
      quality: 'SD',
      transactionHash: transactionId
    };

    return this.restService.postObs('token/reportPendingTrackTokenTransaction', {...payload});
  }

  reportPendingReleaseTokenTransaction(defaultWalletIdForPayment: string, transactionId: string, releaseId: number): Observable<GeneralResponse> {
    const payload = {
      walletId: defaultWalletIdForPayment,
      releaseId,
      quality: 'SD',
      transactionHash: transactionId
    };

    return this.restService.postObs('token/reportPendingReleaseTokenTransaction', {...payload});
  }

  reportPendingBoosterTokenTransaction(defaultWalletIdForPayment: string, transactionId: string, planPricingId: number): Observable<GeneralResponse> {
    const payload = {
      walletId: defaultWalletIdForPayment,
      planPricingId,
      transactionHash: transactionId
    };

    return this.restService.postObs('token/reportPendingBoosterTokenTransaction', {...payload});
  }

  getWalletList(): Observable<GetTokenWalletListResponse> {
    return this.restService.postObs('token/getWalletList');
  }

  initiateWalletSignature(): Observable<InitiateWalletSignatureResponse> {
    return this.restService.postObs<InitiateWalletSignatureResponse>('token/initiateWalletSignature');
  }

  addWalletUsingSignature(signature: string, address: string, salt: string): Observable<GeneralResponse> {
    let reqObj = {
      signature,
      address,
      salt,
      type: this.getDappBrowserType(),
    };

    return this.restService.postObs('token/addWalletUsingSignature', reqObj);
  }
}
