import {inject, Injectable} from '@angular/core';
import {Params} from "@angular/router";
import {LocalStorageService} from "@services/local-storage.service";
import {UtmParams, UtmParamsEnum} from "./protocol/UtmParams";
import {Store} from "@ngrx/store";
import {selectQueryParams} from "@files-ngrx/selectors/router.selectors";
import {Observable} from "rxjs";
import {first, map, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UtmService {
  private store = inject(Store);
  private localStorageService = inject(LocalStorageService);

  public saveCurrentUtmQueryFromParams(): Observable<Params> {
    return this.store.select(selectQueryParams).pipe(
      first((params) => Object.entries(params ?? {}).length > 0),
      map((params: Params) => {
        const lowerCaseParams: Partial<Record<UtmParamsEnum, string>> = {};

        for (const [key, value] of Object.entries(params)) {
          lowerCaseParams[key.toLowerCase() as UtmParamsEnum] = String(value);
        }

        if (lowerCaseParams[UtmParamsEnum.utm_source] === '{{utm_source}}') {
          params[UtmParamsEnum.utm_source] = null;
        }
        if (lowerCaseParams[UtmParamsEnum.utm_campaign] === '{{utm_campaign}}') {
          params[UtmParamsEnum.utm_campaign] = null;
        }

        return lowerCaseParams;
      }),
      tap((params) => {
        const utmObj = {
          ref: params[UtmParamsEnum.ref],
          utmSource: params[UtmParamsEnum.utm_source],
          utmCampaign: params[UtmParamsEnum.utm_campaign],
          utmMedium: params[UtmParamsEnum.utm_medium],
          utmAffiliateTransactionId: params[UtmParamsEnum.utm_affiliateTransId],
          utmTerm: params[UtmParamsEnum.utm_term],
          utmContent: params[UtmParamsEnum.utm_content]
        };

        this.localStorageService.set('G_UTM', utmObj);
      }),
    );
  }

  public getUtmQueryParams(): UtmParams {
    const utmObj = this.localStorageService.get('G_UTM');

    if (!utmObj) {
      return new UtmParams();
    }

    return utmObj;
  }
}
