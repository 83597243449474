<div class="sign-in">
  <h2 class="sign-in-title" [ngClass]="{'long_text':title}">{{title ?? 'SIGN IN'}}</h2>

  <form [formGroup]="signInForm" (ngSubmit)="signInWithCredentials()" class="form">
    <st-input formControlName="email" fieldName="EMAIL ADDRESS" type="text"
              errorMessageId="modal-error"
              [markAsInvalid]="signInForm.controls['email'].invalid && signInForm.controls.email.touched"></st-input>

    <div class="password-container">
      <st-input formControlName="password"
                errorMessageId="modal-error"
                [markAsInvalid]="signInForm.controls.password.invalid && signInForm.controls.password.touched"
                fieldName="PASSWORD"
                type="password"></st-input>

      <button class="btn forgot-password" type="button" (click)="goToForgotPassword()">forgot?</button>
    </div>

    <check-box formControlName="rememberMe">Remember Me</check-box>

    <p class="agree-to-text">
      By signing in you agree to eMusic's <br/>
      <a target="_blank" routerLink="/privacy">Privacy
        Policy</a> and
      <a target="_blank" routerLink="/terms">Terms of Use</a>
    </p>

    <ngx-turnstile [siteKey]="turnstileSiteKey"
                   theme="light"
                   formControlName="captchaToken"></ngx-turnstile>

    <div class="sign-in-button-container">
      <button type="submit" class="btn red-button"
              [attr.aria-disabled]="signInForm.invalid || serverCallInProgress"
              [disabled]="signInForm.invalid || serverCallInProgress">
        SIGN IN
      </button>
    </div>
  </form>

  <p class="or_text">or</p>

  <div class="social_buttons" [ngStyle]="{'justify-content': isFacebookInternalBrowser ? 'center' : 'initial'}">
    <button class="btn social_button" (click)="signInWithFacebook()" type="button">
      <span class="fb_icon"></span>Continue with Facebook
    </button>

    <button *ngIf="!isFacebookInternalBrowser" type="button" class="btn social_button" (click)="signInWithGoogle()">
      <span class="google_icon"></span>Continue with Google
    </button>

    <button class="btn social_button" (click)="signInWithApple()" type="button">
      <span class="apple_icon"></span>Continue with Apple
    </button>
  </div>
</div>
