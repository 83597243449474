<div class="nft_seller">
  <artist-avatar [avatarUrl]="artistImage"
                 [avatarSize]="24"
                 [altText]="artistName"
                 [imageSize]="400"></artist-avatar>

  <div class="seller">Sold by
    <span>{{artistName}}</span>
  </div>
</div>
