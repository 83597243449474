import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DialogModule, DialogRef} from "@angular/cdk/dialog";
import {DialogCloseButtonComponent} from "@components/dialog/dialog-close-button/dialog-close-button.component";

@Component({
  selector: 'support-form-submitted-modal',
  standalone: true,
  imports: [CommonModule, DialogModule, DialogCloseButtonComponent],
  templateUrl: './support-form-submitted-modal.component.html',
  styleUrls: ['./support-form-submitted-modal.component.scss']
})
export class SupportFormSubmittedModalComponent implements OnInit {

  constructor(public dialogRef: DialogRef<boolean, SupportFormSubmittedModalComponent>){}

  ngOnInit(): void {
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
