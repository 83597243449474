import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ClickOutsideDirective} from "@directives/click-outside.directive";
import {FakeButtonEventHandlerDirective} from "@directives/fake-button-event-handler.directive";

@Component({
  selector: 'st-select',
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective, FakeButtonEventHandlerDirective],
  templateUrl: './st-select.component.html',
  styleUrls: ['./st-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: StSelectComponent,
      multi: true,
    }
  ],
})
export class StSelectComponent implements ControlValueAccessor {
  @Input()
  options: any[] = [];

  @Input()
  propertyToShow!: string;

  @Input()
  labeledBy = '';

  @Input()
  selectionValueProperty!: string;

  showOptions = false;
  currentSelection = -1;
  readonly fakeListId = new Date().getDate() + Math.random();

  onChange = (event: any) => {
  };
  onTouch = (event: any) => {
  };

  clickOutSide(): void {
    this.showOptions = false;
  }

  openSelection(): void {
    this.showOptions = !this.showOptions;
  }

  selectOption(option: any, index: number): void {
    this.currentSelection = index;
    this.showOptions = false;
    this.onChange(this.selectionValueProperty ? option[this.selectionValueProperty] : option);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    if (this.selectionValueProperty) {
      this.currentSelection = this.options.findIndex((option) => option[this.selectionValueProperty] === obj);
    }
  }
}
