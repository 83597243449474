import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  /**
   *
   * @param control
   * @return `{notNumberValue: true}` if the value is not a number
   */
  static onlyNumbers(control: AbstractControl) {
    if (control.value === null) {
      return null;
    }

    return new RegExp(/^[0-9]*$/).test(control.value) ? null : {notNumberValue: true};
  }

  /**
   *
   * @return `{'divisionNumberExceeded': true}` if the division number exceeds the maxDivisionNumberByComma
   */
  static maxDivisionNumberByComma(maxDivisionNumberByComma: number) {
    return (control: AbstractControl) => {
      if (typeof control.value !== 'string' || control.value.replace(/\s*,\s*/g, ',').split(',').length > maxDivisionNumberByComma) {
        return {
          divisionNumberExceeded: true,
        };
      }

      return null;
    }
  }

  /**
   *
   * @param control
   * @return `{'whitespace': true}` if the value has only white spaces
   */
  static noWhitespace(control: AbstractControl) {
    return (control.value || '').trim().length ? null : {'whitespace': true};
  }

  static samePassword(compareField: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const notSame = control.value !== control.parent?.get(compareField)?.value;
      return notSame ? {notSame} : null;
    };
  }
}
