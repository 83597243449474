<div id="change__or__cancel" class="modal-container">
  <div class="modal-content">
    <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

    <div class="modal-body">
      <h2 class="modal-header-title">What Would You Like to Do?</h2>

      <div class="body">
        <p>Find yourself needing a little less or a little more? Experiencing some technical issues? or just ready to
          move on? Either way we want to help, what are you looking to do today?</p>
      </div>

      <div class="buttons-container mobile-horizontal">
        <button type="button" (click)="cancelMemberShip()" class="btn new-secondary cancel">
          Cancel Membership
        </button>

        <a type="button" class="btn red-button confirm" routerLink="/plans">
          See Plan Options
        </a>
      </div>
    </div>
  </div>
</div>
