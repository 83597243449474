import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AccountService } from '../../account.service';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { StandardInputComponent } from '@components/forms/regular-input/standard-input.component';
import { CheckBoxComponent } from '@components/forms/check-box/check-box.component';
import { Store } from '@ngrx/store';
import { UserActions } from '@files-ngrx/actions/user.actions';
import { Platform } from '@account/protocol/Platform';
import { environment } from '../../../../environments/environment';
import { NgxTurnstileFormsModule } from '@components/ngx-turnstile/ngx-turnstile-forms.module';

interface ISignInForm {
  email: FormControl<string>;
  password: FormControl<string>;
  rememberMe: FormControl<boolean>;
  captchaToken: FormControl<string>;
}

@Component({
  selector: 'sign-in',
  standalone: true,
  imports: [
    CommonModule, MatFormFieldModule, FormsModule, MatCheckboxModule, StandardInputComponent,
    ReactiveFormsModule, CheckBoxComponent, RouterModule, NgxTurnstileFormsModule
  ],
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss', '../../scss/account.scss']
})
export class SignInComponent {
  @Input()
  title!: string | undefined;

  @Input()
  redirectAfter = false;

  protected readonly turnstileSiteKey = environment.turnstileSiteKey;
  signInForm: FormGroup<ISignInForm>;
  serverCallInProgress = false;
  isFacebookInternalBrowser = false;

  constructor(private readonly fb: FormBuilder,
              private readonly router: Router,
              public readonly accountService: AccountService,
              private store: Store) {
    this.isFacebookInternalBrowser = window.navigator.userAgent.includes('FB_IAB/');
    this.signInForm = fb.nonNullable.group({
      email: fb.nonNullable.control<string>('', [Validators.required, Validators.email]),
      password: fb.nonNullable.control<string>('', [Validators.required, Validators.minLength(1)]),
      rememberMe: fb.nonNullable.control<boolean>(true),
      captchaToken: fb.nonNullable.control<string>('', [Validators.required]),
    });
  }

  signInWithCredentials(): void {
    if (this.signInForm.invalid) {
      return;
    }

    const {email, password, rememberMe, captchaToken} = this.signInForm.getRawValue();

    this.store.dispatch(UserActions.signIn({email, password, rememberMe, platform: Platform.WEB, captchaToken}));
  }

  signInWithFacebook(): void {
    this.store.dispatch(UserActions.signInWithFacebook({rememberMe: this.signInForm.controls.rememberMe.value}));
  }

  public async signInWithGoogle() {
    this.store.dispatch(UserActions.signInWithGoogle({rememberMe: this.signInForm.controls.rememberMe.value}));
  }

  public async signInWithApple() {
    this.store.dispatch(UserActions.signInWithApple({rememberMe: this.signInForm.controls.rememberMe.value}));
  }

  goToForgotPassword(): void {
    const sourcePath = location.pathname;

    this.router.navigate(['/forgotPassword'], {queryParams: {src: sourcePath}});
  }
}
