import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { APP_BASE_HREF, CurrencyPipe, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UnsupportedBrowserComponent } from '@components/unsupported-browser/unsupported-browser.component';
import { CookieService } from 'ngx-cookie-service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { indexReducers } from './core/ngrx/reducers/index.reducers';
import { indexEffects } from './core/ngrx/effects/index.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { DEFAULT_DIALOG_CONFIG } from '@angular/cdk/dialog';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { DOCUMENT_TOKEN, WINDOW_TOKEN } from './core/tokens/browser.tokens';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SessionInterceptor } from './core/interceptors/index.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    UnsupportedBrowserComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatDialogModule, // This imports DialogModule
    MatSnackBarModule,
    HttpClientModule,
    BrowserAnimationsModule,
    HammerModule,
    StoreModule.forRoot(indexReducers),
    EffectsModule.forRoot(indexEffects),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
  ],
  providers: [
    CurrencyPipe,
    DatePipe,
    CookieService,
    SessionInterceptor,
    {provide: DOCUMENT_TOKEN, useValue: document},
    {provide: WINDOW_TOKEN, useValue: window},
    {provide: APP_BASE_HREF, useValue: '/'},
    {
      provide: DEFAULT_DIALOG_CONFIG,
      useValue: {
        backdropClass: 'modal-backdrop',
        hasBackdrop: true,
        role: 'dialog',
        autoFocus: 'first-tabbable',
        restoreFocus: true,
      },
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
}

declare global {
  interface Window {
    analytics: any;
  }
}
