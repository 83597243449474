export class UtmParams {
  utm_source: string | null = null;
  utm_campaign?: string | null = null;
  ref?: string | null = null;
  utm_medium?: string | null = null;
  utm_affiliateTransId?: string | null = null;
  utm_term?: string | null = null;
  utm_content?: string | null = null;
}

export enum UtmParamsEnum {
  utm_source = 'utm_source',
  utm_campaign = 'utm_campaign',
  ref = 'ref',
  utm_medium = 'utm_medium',
  utm_affiliateTransId = 'utm_affiliateTransId',
  utm_term = 'utm_term',
  utm_content = 'utm_content',
}
