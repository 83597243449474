export enum BreakpointQuery {
  MOBILE = '(min-width: 0px) and (max-width: 640px)',
  TABLET = '(min-width: 641px) and (max-width: 960px)',
  DESKTOP_SMALL = '(min-width: 961px) and (max-width: 1020px)',
  DESKTOP = '(min-width: 1021px)',
}

export type BreakpointState = {
  [key in BreakpointQuery]?: boolean;
}

export enum BreakpointStarterPixel {
  // MOBILE FIRST
  MF_MOBILE = 0,
  MF_TABLET = 641,
  MF_DESKTOP_SMALL = 961,
  MF_DESKTOP = 1021
}
