<mat-dialog-content class="dialog-content">
  <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

  <div class="crypto_verification_modal" [ngClass]="{error_margin: errorMessagesArray.length}">
    <h4 class="title">VERIFY YOUR ACCOUNT</h4>

    <div class="crypto_confirmation_description">
      In order to keep the integrity of eMusic auctions, we require all first time bidders to verify their account
      with a 1-time verification fee, after which you will be able to bid on eMusic auctions.
    </div>

    <div class="divider"></div>

    <div class="crypto_confirmation" *ngIf="!requestInProgress && verificationDetails">
      <div class="confirmation_description">
        <div class="confirmation_description_label">
          {{descriptionLabelText}}
        </div>

        <div class="confirmation_description_value">
          {{verificationDetails.payableAmount}} ETH
        </div>

        <p class="confirmation_description_notice" *ngIf="!cryptoCountdownZeroed">
          {{descriptionNotice}}
        </p>

        <p class="confirmation_description_notice red" *ngIf="cryptoCountdownZeroed">
          {{descriptionNoticeZeroed}}
        </p>
      </div>

      <div class="crypto_price_wrapper" *ngIf="!cryptoCountdownZeroed">
        <div class="crypto_payment_address_wrapper">
          <div class="crypto_payment_address">
            {{verificationDetails.address | truncateWalletAddress:11:true }}
          </div>

          <button *ngIf="verificationDetails.address"
                  title="Copy contact address to clipboard"
                  class="copy_contract_address"
                  (click)="copyAddressToClipboard()">
            <i class="contract_address_copy"></i>
          </button>
        </div>

        <div class="timer_wrapper">
          <i class="clock_icon"></i>

          <div class="countdown" *ngIf="countdown">
            {{countdown.m}}:{{countdown.s}}
          </div>
        </div>
      </div>

      <div class="crypto_price_wrapper" *ngIf="cryptoCountdownZeroed">
        <div class="crypto_payment_address_wrapper">
          <div class="crypto_payment_address">
            Address no longer available
          </div>
        </div>

        <div class="timer_wrapper refresh" (click)="refreshVerificationAddress()">
          <i class="refresh_icon"></i>

          <div class="refresh_text">
            Refresh
          </div>
        </div>
      </div>

      <div class="crypto_confirmation_notice" [innerHTML]="helpfulDescription | safeHtml">
      </div>
    </div>

    <emusic-loader-spinner *ngIf="requestInProgress"
                           height="100%"
                           [enableOpacity]="true"></emusic-loader-spinner>
  </div>

  <modal-error-message *ngIf="errorMessagesArray.length" [errorMessages]="errorMessagesArray"></modal-error-message>
</mat-dialog-content>
