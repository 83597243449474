import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppResolver} from './app-resolver.service';

const routes: Routes = [
  {
    path: '',
    resolve: {
      stream: AppResolver
    },
    loadChildren: () => import('./pages/public/public-pages.module').then(m => m.PublicPagesModule),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
