import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {BillingDetailsActions} from "../ngrx/actions/billing.actions";
import {Router} from "@angular/router";
import {Dialog} from "@angular/cdk/dialog";
import {BonusModalComponent} from "@components/dialog/bonus-modal/bonus-modal.component";
import {PlanDetailsState, PromoDetails} from "@files-ngrx/states/BillingState";
import {SnackBarService} from "@components/snack-bar/snack-bar.service";
import {PromoSlide, SectionAction} from "@services/protocol/Promotion";
import {MapType} from "../types/types";
import {RouteDetails} from "@files-ngrx/selectors/router.selectors";
import {ReleaseWithPricing} from "@services/protocol/Release";

@Injectable({
  providedIn: 'root'
})
export class PromoService {
  constructor(private readonly store: Store,
              private readonly router: Router,
              private snackBarService: SnackBarService,
              private dialog: Dialog) {
  }

  checkIfInWinbackPageAndRedirectHome(routeDetails: RouteDetails): void {
    const oldPromoParam = routeDetails.currentUrl.indexOf('promo/win');

    if (oldPromoParam > -1) {
      this.router.navigate(['/']);
    }
  }

  redirectToPromoLanding(promoUrl: string, routeDetails: RouteDetails): void {
    if (routeDetails.currentUrl === '/' && promoUrl) {
      this.router.navigate([promoUrl]);
    }
  }

  storePromoParam(promoDetails: PromoDetails, routeDetails: RouteDetails): void {
    if (promoDetails.activePromo) {
      const promotionMap = promoDetails.promotionsMap[promoDetails.activePromo];

      if (promotionMap && promotionMap.promoUrl) {
        this.redirectToPromoLanding(promotionMap.promoUrl, routeDetails);
      }

      return;
    }

    if (!routeDetails.queryParams['promo']) {
      this.store.dispatch(BillingDetailsActions.clearActivePromo());
      this.checkIfInWinbackPageAndRedirectHome(routeDetails);

      return;
    }

    let promoCode = routeDetails.queryParams['promo'];
    let userSnid = routeDetails.queryParams['snid'];
    let variation = routeDetails.queryParams['var'];

    this.store.dispatch(BillingDetailsActions.getPlanListWithPromotion({promoCode, userSnid, variation, routeDetails}));
  }

  parseFailedPromoMessage(failMessage: string): void {
    switch (failMessage) {
      case "PROMO_EXPIRED":
      case "NEVER_ELIGIBLE":
        this.snackBarService.showNotificationBanner('This promotion has ended.');
        break;
      case "USER_MUST_NOT_HAVE_SUBSCRIPTION":
        this.snackBarService.showNotificationBanner('this promotion is not available ' +
          'for eMusic members with active subscriptions.');
        break;
      default:
        this.snackBarService.showNotificationBanner('This promotion is not available.');
        break;
    }
  }

  promoSlideMap(): MapType<PromoSlide[]> {
    // TODO Make sure how does this work
    return {
      '3MO50BNS96987363C64FBAE98FE03FC4': [
        {
          variation: 0,
          backgroundUrl: '../assets/img/promo/winback/img-win-back-header-bg-a@2x.jpg',
          template: 'PROMO',
          headline:
            {
              'en': 'Get {{carouselCtrl.getPromoCurrency() | currencyToSymbol}}' +
                '50 in Music Credit for just {{carouselCtrl.getPromoCurrency() | currencyToSymbol}}10'
            },
          subHeadline: {
            'en': 'During each of your first 3 months of a Basic Plan.'
          },
          subtitle: {
            'en': 'That\'s up to 300 songs for just {{carouselCtrl.getPromoCurrency() | currencyToSymbol}}30. Download-to-own over 26 million songs in indie, ' +
              'Jazz, Classical or whatever moves you.'
          },
          sectionAction: SectionAction.WINBACK_PROMO,
          cta: {
            'en': 'GET STARTED'
          },
          minimal: true,
          planDescriptionPaymentDetails: 'Special Offer: Get {{plansCtrl.getPlanCurrencySymbol(planDescriptionCtrl.planToPurchase)}}' +
            '50 in music for' +
            ' {{plansCtrl.getPlanCurrencySymbol(planDescriptionCtrl.planToPurchase)}}10 each month for' +
            ' your first 3 months. {{plansCtrl.getPlanCurrencySymbol(planDescriptionCtrl.planToPurchase)}}10/mo in music credit thereafter. ' +
            '<span class="underline pointer" ' +
            'ng-click="planDescriptionCtrl.openPromoDescriptionModal()">Promo Details</span>',
          planDescriptionFootnote: 'Subscribe today to take your offer!',
          referral: 'WB_May-2018_default',
          bannerText: 'Your Offer: Get {{promoBarCtrl.getCurrentPromoPlanCurrency() | currencyToSymbol}}' +
            '50 in music for ' +
            '{{promoBarCtrl.getCurrentPromoPlanCurrency() | currencyToSymbol}}10',
          uniquePlanPage: false,
          promoUrl: '/promo/win'
        },
        {
          variation: 1,
          backgroundUrl: '../assets/img/promo/winback/img-win-back-header-bg-b@2x.jpg',
          template:
            'PROMO',
          headline:
            {
              'en': 'Get {{carouselCtrl.getPromoCurrency() | currencyToSymbol}}' +
                '150 in Music Credit for just {{carouselCtrl.getPromoCurrency() | currencyToSymbol}}30'
            },
          subtitle: {
            'en': 'Score {{carouselCtrl.getPromoCurrency() | currencyToSymbol}}50 in music for just ' +
              '{{carouselCtrl.getPromoCurrency() | currencyToSymbol}}10, during each of your first three ' +
              'months for a Basic plan.  That\'s over 300 songs for just ' +
              '{{carouselCtrl.getPromoCurrency() | currencyToSymbol}}30.  Choose what moves you from' +
              ' our catalog of over 26 million songs.'
          },
          sectionAction: SectionAction.WINBACK_PROMO,
          cta: {
            'en': 'GET STARTED'
          },
          minimal: true,
          planDescriptionPaymentDetails: 'Special Offer: Get {{plansCtrl.getPlanCurrencySymbol(planDescriptionCtrl.planToPurchase)}}' +
            '50 in music for' +
            ' {{plansCtrl.getPlanCurrencySymbol(planDescriptionCtrl.planToPurchase)}}10 each month for' +
            ' your first 3 months. {{plansCtrl.getPlanCurrencySymbol(planDescriptionCtrl.planToPurchase)}}10/mo in music credit thereafter. ' +
            '<span class="underline pointer" ' +
            'ng-click="planDescriptionCtrl.openPromoDescriptionModal()">Promo Details</span>',
          planDescriptionFootnote: 'Subscribe today to take your offer!',
          referral: 'WB_May-2018_1',
          bannerText: 'Your Offer: Get {{promoBarCtrl.getCurrentPromoPlanCurrency() | currencyToSymbol}}' +
            '150 in music for ' +
            '{{promoBarCtrl.getCurrentPromoPlanCurrency() | currencyToSymbol}}30',
          uniquePlanPage: false,
          promoUrl: '/promo/win'
        }
      ],
      '12MO20BNS6A8907EC57C1C431A4308EC': [
        {
          backgroundUrl: '../assets/img/promo/winback/img-win-back-header-bg-b@2x.jpg',
          template: 'PROMO',
          headline:
            {
              'en': 'Get $50 in Music <br> for just $10'
            }
          ,
          sectionAction: SectionAction.WINBACK_PROMO,
          cta: {
            'en': 'GET STARTED'
          },
          subtitle: {
            'en': 'That\'s up to 300 songs for just $30. Download-to-own over 26 million songs in indie, ' +
              'Jazz, Classical or whatever moves you.'
          },
          minimal: true,
          planDescriptionPaymentDetails: 'Special Offer: Get {{plansCtrl.getPlanCurrencySymbol(planDescriptionCtrl.planToPurchase)}}' +
            '50 in music for' +
            '{{plansCtrl.getPlanCurrencySymbol(planDescriptionCtrl.planToPurchase)}}10 each month for' +
            ' your first 3 months. {{plansCtrl.getPlanCurrencySymbol(planDescriptionCtrl.planToPurchase)}}10/mo in music credit thereafter.' +
            '<span class="underline pointer"' +
            ' ng-click="planDescriptionCtrl.openPromoDescriptionModal()"> Promo Details</span>',
          planDescriptionFootnote: 'Subscribe today to take your offer!',
          referral: '',
          uniquePlanPage: false
        }
      ],

      'F3118A9C8D811EC69162329B98F9C1AF': [
        {
          promoCode: 'F3118A9C8D811EC69162329B98F9C1AF',
          backgroundUrl: '../assets/img/promo/3for1/bkg-discover-black-friday_2x.jpg',
          template: 'FEATURED_PROMO',
          headline: {
            'en': 'Get 2 Months Free'
          },
          eyebrow: {
            'en': 'Special promotional text goes here'
          },
          subtitle: {
            'en': 'Get 3 glorious months to shop millions of songs & albums for the price of 1 month'
          },
          sectionAction: SectionAction.PROMO,
          cta: {
            'en': 'GET STARTED'
          },
          bannerText: 'Your offer: Get 3 months for the price of 1',
          uniquePlanPage: true
        }
      ],
      '1D1951CB1BC6010EB850FE27BDD88D7D': [
        {
          promoCode: '1D1951CB1BC6010EB850FE27BDD88D7D',
          backgroundUrl: '../assets/img/promo/doubleBonus/bkg-discover-double-bonus_2x.jpg',
          template: 'FEATURED_PROMO',
          headline: {
            'en': 'Double Bonus Days'
          },
          subtitle: {
            'en': 'Get up to a <span class="line__through">{{carouselCtrl.getPremiumPromoPlanMembershipBonusAmount(true)}}</span> ' +
              '<strong>{{carouselCtrl.getPremiumPromoPlanMembershipBonusAmount()}} Music Bonus </strong> when you sign-up before December 31, 2017'
          },
          sectionAction: SectionAction.PROMO,
          cta: {
            'en': 'GET STARTED'
          },
          bannerText: 'Your offer: Get up to ' +
            '{{promoBarCtrl.getCurrentPromoPlanCurrency() | currencyToSymbol}} when sign up today!',
          uniquePlanPage: true

        }
      ]
    };
  }

  openBonusModal(release: ReleaseWithPricing | undefined, planDetails: PlanDetailsState | undefined): void {
    this.dialog.open(BonusModalComponent, {data: {release, planDetails}});
  }
}
