import {GeneralResponse} from "../../protocol/GeneralResponse";

export class GetSavedPaymentDetailsResponse extends GeneralResponse {
  creditCardDetails: CreditCardDetails;
  paymentMethod: PaymentMethod;
  paymentMethodToken: string;
  paypalEmail: string;
}

export interface SavePaymentDetailsResponse extends GeneralResponse {
  billingError: string;
  paymentMethodSaved: boolean;
}

export interface PurchasePlanResponse extends GeneralResponse {
  subscriptionId: number;
  billingError: string;
}

export interface CreditCardDetails {
  cardDateCreated: number;
  cardDateModified: number;
  cardType: string;
  cardholderName: string;
  countryCodeAlpha2: string;
  creditCardToken: string;
  expirationMonth: string;
  expirationYear: string;
  lastFourDigits: string;
  maskedNumber: string;
  postalCode: string;
}

export interface PaymentClientToken extends GeneralResponse {
  btGeneratedClientToken: string;
  stGeneratedClientToken: string;
}

export enum PaymentProcessor {
  BRAINTREE = "BRAINTREE",
  STRIPE = "STRIPE"
}


export enum PaymentMethod {
  CREDIT_CARD = 'CREDIT_CARD',
  PAYPAL = 'PAYPAL'
}
