import { Component } from '@angular/core';
import {MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {DialogCloseButtonComponent} from "@components/dialog/dialog-close-button/dialog-close-button.component";

@Component({
  selector: 'app-transaction-in-progress-modal',
  standalone: true,
  imports: [
    MatDialogModule,
    DialogCloseButtonComponent
  ],
  templateUrl: './transaction-in-progress-modal.component.html',
  styleUrls: ['./transaction-in-progress-modal.component.scss']
})
export class TransactionInProgressModalComponent {
  constructor(public dialogRef: MatDialogRef<any>) { }

  closeModal(): void {
    this.dialogRef.close();
  }
}
