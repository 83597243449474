import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {Web3Service} from "@services/web3.service";
import {TokenWallet} from "@services/protocol/TokenWallet";
import {DialogCloseButtonComponent} from "@components/dialog/dialog-close-button/dialog-close-button.component";

@Component({
  selector: 'disconnect-wallet-modal',
  standalone: true,
  imports: [
    MatDialogModule,
    DialogCloseButtonComponent
  ],
  templateUrl: './disconnect-wallet-modal.component.html',
  styleUrls: ['./disconnect-wallet-modal.component.scss']
})
export class DisconnectWalletModalComponent implements OnInit {

  wallet: TokenWallet;

  constructor(@Inject(MAT_DIALOG_DATA) private data: {wallet: TokenWallet},
              public dialogRef: MatDialogRef<DisconnectWalletModalComponent>,
              private web3Service: Web3Service) {
    this.wallet = data.wallet;
  }

  ngOnInit(): void {
  }

  async disconnectWallet() {
    this.web3Service.disconnectWallet(this.wallet.id);
    this.dialogRef.close();
  }
}
