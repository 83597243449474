import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {RemotePurchasePayload} from "@components/payment-form/Protocol/RemotePurchasePayload";

@Injectable()
export class PaymentService {
  private remotePurchase = new Subject<RemotePurchasePayload>();
  constructor() {
  }

  public observeRemotePurchase(): Observable<RemotePurchasePayload> {
    return this.remotePurchase.asObservable();
  }

  public doRemotePurchase(payload: RemotePurchasePayload): void {
    this.remotePurchase.next(payload);
  }
}
