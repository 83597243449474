<div id="change__pw" class="modal-container" [ngClass]="{error: !!serverError}">
  <div class="modal-content">
    <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

    <form [formGroup]="formGroup" (ngSubmit)="savePassword()" class="modal-body">
      <h2 class="modal-header-title">CHANGE PASSWORD</h2>

      <div class="body">
        <div>
          <div class="form-group">
            <st-input formControlName="currentPassword" fieldName="OLD PASSWORD" type="password"></st-input>
          </div>

          <div class="form-group" *ngrxLet="formGroup.controls.newPassword as newPassword">
            <st-input formControlName="newPassword"
                      [errorText]="newPassword.errors?.['minlength'] && !!newPassword.value ? 'MINIMUM 6 CHARACTERS REQUIRED!' : null"
                      fieldName="NEW PASSWORD" type="password"></st-input>
          </div>

          <div class="form-group" *ngrxLet="formGroup.controls.confirmationPassword as confirmPass">
            <st-input formControlName="confirmationPassword"
                      [errorText]="confirmPass.errors?.['notSame'] && !!confirmPass.value ? 'PASSWORD MISMATCH!' : null"
                      fieldName="CONFIRM PASSWORD" type="password"></st-input>
          </div>
        </div>
      </div>

      <div class="buttons-container">
        <button type="button" (click)="closeModal()" class="btn transparent-button cancel">
          CANCEL
        </button>

        <button type="submit"
                [disabled]="formGroup.invalid || requestChangePasswordInProgress"
                class="btn red-button confirm">
          SAVE
        </button>
      </div>
    </form>
  </div>

  <modal-error-message *ngIf="!!serverError" [errorMessages]="[serverError]"></modal-error-message>
</div>
