import {Injectable} from '@angular/core';
import {BreakpointObserver, BreakpointState as CdkBreakpointState} from "@angular/cdk/layout";
import {map, Observable} from "rxjs";
import {BreakpointQuery, BreakpointState} from "@services/protocol/BreakpointHandler";

/**
 * @description Just a BreakpointObserver (Angular CDK) wrapper
 */
@Injectable({
  providedIn: 'root'
})
export class WhichBreakpointService {
  constructor(private breakPointObs: BreakpointObserver) {
  }

  public observe(value: string | readonly string[]): Observable<BreakpointState> {
    return this.breakPointObs.observe(value).pipe(
      map((state: CdkBreakpointState) => state.breakpoints as BreakpointState),
    );
  }

  public observeBuiltInBreakpoints(): Observable<BreakpointState> {
    return this.breakPointObs.observe([...Object.values(BreakpointQuery)]).pipe(
      map((state: CdkBreakpointState) => state.breakpoints as BreakpointState),
    );
  }
}
