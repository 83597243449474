import {Injectable} from '@angular/core';
import {Device} from "./protocol/Device";
import {Platform} from "./protocol/Platform";
import {DeviceType} from "./protocol/DeviceType";

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor() {
  }

  static getDevice() {
    return new Device("Web", DeviceType.WEB, Platform.WEB);
  }
}
