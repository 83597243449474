import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[ngForRange]',
  standalone: true,
})
export class NgForRangeDirective {
  @Input()
  set ngForRange(param: number) {
    this.viewContainer.clear();

    for (let i = 0; i < param; i++) {
      this.viewContainer.createEmbeddedView(this.templateRef, {index: i});
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
  }
}
