<div id="support__form__submitted" class="modal-container">
  <div class="modal-content">
    <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

    <div class="modal-body">
      <h2 class="modal-header-title">Form Submitted!</h2>

      <div class="body">
        <p>
          Thank you for your request. Sit back and relax, our
          <br />
          support team will follow up with you shortly.
        </p>
      </div>

      <div class="buttons-container">
        <button type="button" class="btn red-button" (click)="closeModal()">
          Done
        </button>
      </div>
    </div>
  </div>
</div>
