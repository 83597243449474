<div class="sign_up_container" *ngrxLet="vm$  as vm">
  <h2 class="sign-in-title" [ngClass]="{'long_text':title}">{{title ?? 'SIGN UP'}}</h2>

  <form [formGroup]="signUpForm" (ngSubmit)="signUp()" class="form">
    <st-input formControlName="fullName" fieldName="FULL NAME" type="text"
              [markAsInvalid]="signUpForm.controls.fullName.invalid && signUpForm.controls.fullName.touched"></st-input>

    <st-input formControlName="email" fieldName="EMAIL ADDRESS" type="text"
              [markAsInvalid]="signUpForm.controls.email.invalid && signUpForm.controls.email.touched"></st-input>

    <st-input formControlName="confirmEmail"
              class="confirm-email"
              [tabIndex]="-1"
              autoComplete="off"
              fieldName="CONFIRM EMAIL ADDRESS" type="text"></st-input>

    <st-input formControlName="password" fieldName="PASSWORD" type="password"
              [errorText]="signUpForm.controls.password.hasError('minlength') ? 'MINIMUM 6 CHARACTERS REQUIRED!' : ''"
              [markAsInvalid]="signUpForm.controls.password.invalid && signUpForm.controls.password.touched"></st-input>

    <st-input formControlName="confirmPassword" fieldName="CONFIRM PASSWORD" type="password"
              [markAsInvalid]="(signUpForm.controls.confirmPassword.hasError('notSame') || signUpForm.controls.confirmPassword.invalid && signUpForm.controls.confirmPassword.touched)"></st-input>

    <div class="marketing-opt-in-group" *ngIf="vm?.code !== 'US'">
      <check-box class="sign_up_checkbox agree_to_terms_checkbox"
                 formControlName="marketingCommunicationOptIn"></check-box>

      <p class="marketing-opt-in">
        Yes, I'd like to discover free music, discounts & special offers from eMusic via email.
      </p>
    </div>

    <p class="agree-to-text">
      By signing in you agree to eMusic's <br/>
      <a target="_blank" routerLink="/privacy">Privacy
        Policy</a> and
      <a target="_blank" routerLink="/terms">Terms of Use</a>
    </p>

    <ngx-turnstile [siteKey]="turnstileSiteKey"
                   theme="light"
                   formControlName="captchaToken"></ngx-turnstile>

    <div class="sign-in-button-container">
      <button type="submit" class="btn red-button" [attr.aria-disabled]="signUpForm.invalid || serverCallInProgress"
              [disabled]="signUpForm.invalid || serverCallInProgress">
        SIGN UP
      </button>
    </div>
  </form>

  <p class="or_text">or</p>

  <div class="social_buttons" [ngStyle]="{'justify-content': isFacebookInternalBrowser ? 'center' : 'initial'}">
    <button class="btn social_button" type="button" (click)="signInWithFacebook()">
      <span class="fb_icon"></span>Continue with Facebook
    </button>

    <button *ngIf="!isFacebookInternalBrowser" type="button" class="btn social_button" (click)="signInWithGoogle()">
      <span class="google_icon"></span>Continue with Google
    </button>

    <button class="btn social_button" type="button" (click)="signInWithApple()">
      <span class="apple_icon"></span>Continue with Apple
    </button>
  </div>
</div>
