<mat-dialog-content *ngrxLet="breakpoints$ as breakpoints">
  <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

  <ng-container *ngrxLet="breakpoints[BreakpointQuery.MOBILE] as isMobile">
    <h2 class="title">{{title}}</h2>

    <div [ngClass]="dappLogo"></div>

    <p class="text">
      {{text}}
    </p>

    <a class="btn red-button full-width get_metamask" target="_blank" href="https://metamask.io/" *ngIf="!isMobile">
      <span>GET METAMASK</span>
    </a>

    <button type="button" (click)="downloadCoinbase()"
            class="coinbase_wallet_button"
            *ngIf="isMobile"></button>

    <p class="note" *ngIf="!isMobile">
      After you install MetaMask, please refresh the page so we can detect your newly connected wallet or <span
      class="pointer bold underline__hover" (click)="refreshPage()">refresh here</span>
    </p>
  </ng-container>
</mat-dialog-content>
