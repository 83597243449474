import {ChangeDetectionStrategy, Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogCloseButtonComponent} from "@components/dialog/dialog-close-button/dialog-close-button.component";
import {DialogRef} from "@angular/cdk/dialog";

@Component({
  selector: 'no-service-modal',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, DialogCloseButtonComponent],
  templateUrl: './no-service-modal.component.html',
  styleUrls: ['./no-service-modal.component.scss']
})
export class NoServiceModalComponent {
  constructor(public dialogRef: DialogRef<boolean, NoServiceModalComponent>) { }

  closeModal(): void {
    this.dialogRef.close();
  }
}
