import {Component, HostBinding, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR} from "@angular/forms";
import {FakeButtonEventHandlerDirective} from "@directives/fake-button-event-handler.directive";

@Component({
  selector: 'check-box',
  standalone: true,
  imports: [CommonModule, FormsModule, FakeButtonEventHandlerDirective],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CheckBoxComponent,
      multi: true,
    }
  ],
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss']
})
export class CheckBoxComponent implements ControlValueAccessor {
  @Input()
  id = new Date().getTime() + Math.random();

  @Input()
  labelOrder: 'INVERSE' | 'NORMAL' = 'NORMAL';

  @Input()
  borderColor: 'WHITE' | 'GREY' = 'GREY';

  @Input()
  borderWidth = 1.5;

  @Input()
  spaceBetween = false;

  @Input()
  marginBetween = 10;

  @Input()
  checkBoxFill: 'CHECK_MARK' | 'FILL' = 'CHECK_MARK';

  @Input()
  @HostBinding('style.display')
  hostDisplay = 'inline-block';

  @Input()
  size = 14;

  @Input()
  borderRadius = 0;

  finalValue: any = true;

  onChange = (evt: any) => {
  };
  onTouch = (evt: any) => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.finalValue = obj;
  }
}
