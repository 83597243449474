import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { GetCollectibleLiteDetailsResponse } from '../../../protocol/GetCollectibleDetailsResponse';
import { AuctionState } from '../../../protocol/CollectibleLite';
import { NftService } from '../../../nft.service';
import { SnackBarService } from '@components/snack-bar/snack-bar.service';
import { DialogCloseButtonComponent } from '@components/dialog/dialog-close-button/dialog-close-button.component';
import { NgClass, NgIf } from '@angular/common';
import { PaymentFormComponent } from '@components/payment-form/payment-form.component';
import { EmusicLoaderSpinnerComponent } from '@components/emusic-loader-spinner/emusic-loader-spinner.component';
import { ModalErrorMessageComponent } from '@components/dialog/modal-error-message/modal-error-message.component';

@Component({
  selector: 'fiat-verification-modal',
  standalone: true,
  imports: [
    MatDialogModule,
    DialogCloseButtonComponent,
    NgClass,
    PaymentFormComponent,
    EmusicLoaderSpinnerComponent,
    ModalErrorMessageComponent,
    NgIf
  ],
  templateUrl: './fiat-verification-modal.component.html',
  styleUrls: ['./fiat-verification-modal.component.scss']
})
export class FiatVerificationModalComponent implements OnInit {
  paymentError: string[] = [];
  requestInProgress = false;

  constructor(public dialogRef: MatDialogRef<FiatVerificationModalComponent>,
              private nftService: NftService,
              private snackService: SnackBarService,
              @Inject(MAT_DIALOG_DATA) public parentData: {
                nftDetails: GetCollectibleLiteDetailsResponse,
                auctionState: AuctionState
              }) {
  }

  ngOnInit(): void {
  }

  handleOnPaymentUpdate(): void {
    this.nftService.openBiddingModal(this.parentData.nftDetails, this.parentData.auctionState);
    this.snackService.showBlueNotificationBanner('Your account has been successfully verified.');
    this.dialogRef.close();
  }

  handleOnPaymentError(error: string): void {
    if (error === '') {
      return;
    }

    this.paymentError.push(error);
  }

  handleOnLoading(isLoading: boolean): void {
    this.requestInProgress = isLoading;
  }
}
