import { ComponentStore } from '@ngrx/component-store';
import {
  BiddingModalState,
} from '@public-pages/nft/nft-details/modals/bidding-modal/store/bidding-modal.state';
import { inject, Injectable } from '@angular/core';
import { combineLatestWith, exhaustMap, first, Observable, startWith, switchMap, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  selectUserLocation,
  selectVerifiedCryptoBidder
} from '@files-ngrx/selectors/user.selectors';
import { NftService } from '@public-pages/nft/nft.service';
import { GlobalService } from '@services/global.service';
import { LocationInfo } from '@account/protocol/location-response';
import { AuctionState } from '@public-pages/nft/protocol/CollectibleLite';
import { GetCollectibleLiteDetailsResponse } from '@public-pages/nft/protocol/GetCollectibleDetailsResponse';
import { FormControl } from '@angular/forms';
import { BillingDetailsActions } from '@files-ngrx/actions/billing.actions';
import { SnackBarService } from '@components/snack-bar/snack-bar.service';
import { DialogRef } from '@angular/cdk/dialog';
import { selectNoPaymentMethodSaved } from '@files-ngrx/selectors/billing.selectors';

interface InitialPayload {
  fiatForm: FormControl;
  ethForm: FormControl;
  nftDetails: GetCollectibleLiteDetailsResponse;
}

@Injectable()
export class BiddingModalStore extends ComponentStore<BiddingModalState> {
  private readonly globalService = inject(GlobalService);
  private readonly store = inject(Store);
  private readonly nftService = inject(NftService);
  private readonly snackBarService = inject(SnackBarService);

  constructor() {
    super();

    this.store.dispatch(BillingDetailsActions.getSavedPaymentDetails());
  }

  readonly initializeStoreFx = this.effect((payload$: Observable<InitialPayload>) => payload$.pipe(
    exhaustMap(({nftDetails, ethForm, fiatForm}) => this.store.select(selectUserLocation).pipe(
      combineLatestWith(
        this.nftService.listenForBiddingStatus(),
        fiatForm.valueChanges.pipe(startWith(null)),
        ethForm.valueChanges.pipe(startWith(null)),
      ),
      tap(([location, biddingStatus, biddingFiatAmount, biddingEthAmount]) => {
        if (!location || !biddingStatus || !biddingStatus.auctionState || !biddingStatus.auctionStage) {
          return;
        }

        const auctionState = biddingStatus.auctionState!; // TODO try to remove this
        const nextBidMinimumFiatPrice = parseFloat(auctionState.nextBidMinimumFiatPrice.toFixed(4));
        const nextBidMinimumEthPrice = parseFloat(auctionState.nextBidMinimumEthPrice.toFixed(6));
        const countdown = {
          d: ('0' + biddingStatus.timerDetails?.daysLeft).slice(-3),
          h: ('0' + biddingStatus.timerDetails?.hoursLeft).slice(-2),
          m: ('0' + biddingStatus.timerDetails?.minutesLeft).slice(-2),
          s: ('0' + biddingStatus.timerDetails?.secondsLeft).slice(-2)
        };
        const highestPrimaryBidLabel = this.nftService.getHighestBidLabel(auctionState.highestBidPrimaryPrice, auctionState.highestBidPrimaryCurrency);
        const highestSecondaryBidLabel = this.nftService.getHighestBidLabel(auctionState.highestBidSecondaryPrice, auctionState.highestBidSecondaryCurrency);


        // We do this once only when the bidding modal component is initialized
        if (biddingFiatAmount === null && biddingEthAmount === null) {
          const currentCurrency = location.currency ?? '';
          const currentFiatCurrencySymbol = this.globalService.currencyToSymbol(currentCurrency);
          const nextCurrency = this.handleNextCurrency(currentCurrency, location);
          const nextMinimumBidLabel = this.getNextMinimumBidLabel(currentCurrency, this.getNextMinimumBidValue(auctionState, currentCurrency));

          this.setState({
            currentCurrency,
            currentFiatCurrencySymbol,
            nextCurrency,
            nextBidMinimumFiatPrice,
            nextBidMinimumEthPrice,
            nextMinimumBidLabel,
            countdown,
            highestPrimaryBidLabel,
            highestSecondaryBidLabel,
            bidNotAccepted: false,
            nftDetails,
            errorMessagesArray: [],
            requestInProgress: false,
            biddingStatus,
            locationInfo: location,
          });

          return;
        }


        this.patchState(state => {
          const submittedBid = state.currentCurrency === 'ETH' ? biddingEthAmount : biddingFiatAmount;
          const bidNotAccepted = submittedBid < this.getNextMinimumBidValue(auctionState, state.currentCurrency);
          const nextMinimumBidLabel = this.getNextMinimumBidLabel(state.currentCurrency, this.getNextMinimumBidValue(auctionState, state.currentCurrency));

          return {
            nextBidMinimumFiatPrice,
            nextBidMinimumEthPrice,
            nextMinimumBidLabel,
            countdown,
            highestPrimaryBidLabel,
            highestSecondaryBidLabel,
            bidNotAccepted,
            biddingStatus,
            locationInfo: location,
          };
        });
      }),
    )),
  ));

  private getNextMinimumBidLabel(currentCurrency: string, nextMinimumBidValue: number) {
    return this.nftService.getHighestBidLabel(nextMinimumBidValue, currentCurrency);
  }

  getNextMinimumBidValue(auctionState: AuctionState, currentCurrency: string): number {
    return currentCurrency === 'ETH' ? auctionState.nextBidMinimumEthPrice : auctionState.nextBidMinimumFiatPrice;
  }

  handleNextCurrency(currentCurrency: string, location: LocationInfo | null): string {
    if (!location || !location.currency) {
      return '';
    }

    return currentCurrency === location.currency ? 'ETH' : location.currency;
  }

  placeBidFx = this.effect((payload$: Observable<{
    biddingEthAmount: number,
    biddingFiatAmount: number,
    dialogRef: DialogRef,
  }>) => payload$.pipe(
    switchMap(({biddingEthAmount, biddingFiatAmount, dialogRef}) => this.select(state => state).pipe(
      first(),
      switchMap(state => {
        this.patchState({requestInProgress: true});

        const submittedBid = state.currentCurrency === 'ETH' ? biddingEthAmount : biddingFiatAmount;

        return this.nftService.placeBidRequest(state.nftDetails.collectible.id, submittedBid, state.currentCurrency).pipe(
          tap(response => {
            if (response && response.responseStatus === 'SUCCESS') {
              this.snackBarService.showBlueNotificationBanner(`Your bid of ${this.nftService.getHighestBidLabel(submittedBid,
                state.currentCurrency)} has been confirmed. You are the current high bidder.`);

              dialogRef.close();
            }

            if (response && response.responseStatus === 'FAILED') {
              this.patchState({
                errorMessagesArray: [response.responseText]
              });
            }

            this.patchState({requestInProgress: false});
          }),
        );
      }),
    )),
  ));

  readonly submitBiddingFx = this.effect((payload$: Observable<{
    vm: BiddingModalState,
    dialogRef: DialogRef,
    biddingFiatAmount: number,
    biddingEthAmount: number,
  }>) => payload$.pipe(
    switchMap(({
                 vm,
                 dialogRef,
                 biddingFiatAmount,
                 biddingEthAmount
               }) => this.store.select(selectVerifiedCryptoBidder).pipe(
      combineLatestWith(this.store.select(selectNoPaymentMethodSaved)),
      first(),
      tap(([verifiedCryptoBidder, noPaymentMethodSaved]) => {
        this.patchState({requestInProgress: false});

        if (vm.currentCurrency !== 'ETH') {
          if (noPaymentMethodSaved) {
            this.nftService.openFiatVerifyAccountModal(vm.nftDetails, vm.biddingStatus?.auctionState!);

            dialogRef.close();
          } else {
            this.placeBidFx({
              dialogRef,
              biddingFiatAmount,
              biddingEthAmount,
            });
          }

        } else {
          if (!verifiedCryptoBidder) {
            this.nftService.openCryptoVerifyAccountModal(vm.nftDetails, true);
            dialogRef.close();
            return;
          }

          this.placeBidFx({
            dialogRef,
            biddingFiatAmount,
            biddingEthAmount,
          });
        }
      })
    )),
  ));

  clearErrors(): void {
    this.patchState({
      bidNotAccepted: false,
      errorMessagesArray: [],
    });
  }

  readonly selectViewModel: Observable<BiddingModalState> = this.select(state => state);
}
