import {ChangeDetectionStrategy, Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogModule, DialogRef} from "@angular/cdk/dialog";
import {DialogCloseButtonComponent} from "@components/dialog/dialog-close-button/dialog-close-button.component";
import {RadioButtonComponent} from "@components/forms/radio-button/radio-button.component";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {SubscriptionService} from "@services/subscription.service";
import {Store} from "@ngrx/store";
import {UserDetailsState} from "@files-ngrx/states/UserDetailsState";
import {UserActions} from "@files-ngrx/actions/user.actions";
import {skip} from "rxjs";
import {SnackBarService} from "@components/snack-bar/snack-bar.service";
import {FreezeSubscriptionResponse} from "@services/protocol/Subscription";
import {AccountService} from "@account/account.service";
import {take} from "rxjs/operators";

interface HoldDuration {
  name: string;
  value: number;
}

@Component({
  selector: 'app-hold-account-modal',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, DialogModule, DialogCloseButtonComponent, RadioButtonComponent, ReactiveFormsModule],
  templateUrl: './hold-account-modal.component.html',
  styleUrls: ['./hold-account-modal.component.scss', '../can-we-help-modal/can-we-help-modal.component.scss']
})
export class HoldAccountModalComponent {
  holdDurations: HoldDuration[] = [
    {
      name: '30 days',
      value: 30
    },
    {
      name: '60 days',
      value: 60
    },
    {
      name: '90 days',
      value: 90
    }
  ];

  selectedDuration = new FormControl(30, {nonNullable: true});
  requestInProgress = false;

  constructor(public dialogRef: DialogRef<boolean, HoldAccountModalComponent>,
              private store: Store<{ userDetails: UserDetailsState }>,
              private snackBarService: SnackBarService,
              private accountService: AccountService,
              private subscriptionService: SubscriptionService) {
  }

  showCancelAccountModal(): void {
    this.subscriptionService.openCancelConfirmationModal();
    this.closeModal();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  submitHoldAccount(): void {
    if (this.requestInProgress) {
      return;
    }

    this.requestInProgress = true;

    this.store.select('userDetails').pipe(skip(1), take(1))
      .subscribe(({subscriptionDetails, responseStatus}: UserDetailsState) => {
        if (responseStatus !== 'SUCCESS' || !subscriptionDetails?.subscription) {
          this.snackBarService.showErrorNotificationBanner('Active subscription not found', false, 3000);
          this.closeModal();
          return;
        }

        const days = this.selectedDuration.getRawValue();

        if (subscriptionDetails.subscription.billingCycleUnitType === 'MONTHLY') {
          this.subscriptionService.freezeSubscription(subscriptionDetails?.subscription.id, days).subscribe((response: FreezeSubscriptionResponse) => {
            this.requestInProgress = false;
            if (response.responseStatus !== 'SUCCESS') {
              this.snackBarService.showErrorNotificationBanner('Unable to freeze subscription', false, 3000);
              return;
            }

            this.subscriptionService.openHoldSubmittedModal(days);
            this.closeModal();
          });
        } else {
          this.accountService.contactSupport('User requesting hold from cancellation flow',
            `Number of days to hold: ${days}`).subscribe(() => {
            this.requestInProgress = false;
            this.subscriptionService.openHoldSubmittedModal(days);
            this.closeModal();
          });
        }
      });

    this.store.dispatch(UserActions.getSubscriptionDetails());
  }
}
