import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogModule, DialogRef} from "@angular/cdk/dialog";
import {DialogCloseButtonComponent} from "@components/dialog/dialog-close-button/dialog-close-button.component";
import {RouterModule} from "@angular/router";
import {SubscriptionService} from "@services/subscription.service";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {RadioButtonComponent} from "@components/forms/radio-button/radio-button.component";
import {Store} from '@ngrx/store';
import {UserActions} from "@files-ngrx/actions/user.actions";
import {UserDetailsState} from "@files-ngrx/states/UserDetailsState";
import {SnackBarService} from "@components/snack-bar/snack-bar.service";
import {take} from "rxjs/operators";

@Component({
  selector: 'can-we-help-modal',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, DialogModule, DialogCloseButtonComponent, ReactiveFormsModule, RouterModule, RadioButtonComponent],
  templateUrl: './can-we-help-modal.component.html',
  styleUrls: ['./can-we-help-modal.component.scss']
})
export class CanWeHelpModalComponent implements OnInit {

  leaveReasons = [
    `I have a technical problem`,
    `I can't find enough music to buy`,
    `I'm trying another service`,
    `I'm not using it enough`,
    `I don't like the music in the catalog`,
    `I like it but it's more than I want to pay`,
  ];

  selectedReason = new FormControl<string>(this.leaveReasons[0], {nonNullable: true});

  constructor(public dialogRef: DialogRef<boolean, CanWeHelpModalComponent>,
              private store: Store<{ userDetails: UserDetailsState }>,
              private snackBarService: SnackBarService,
              private subscriptionService: SubscriptionService) {
  }

  ngOnInit(): void {
    this.store.dispatch(UserActions.getSubscriptionDetails());
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  leaveReasonSelected(): void {
    const reason = this.selectedReason.value;

    if (reason === this.leaveReasons[0]) {
      this.closeModal();
      this.subscriptionService.openReadyToHelpModal();
    } else {
      this.store.select('userDetails').pipe(take(1)).subscribe(({subscriptionDetails}: UserDetailsState) => {
        const subscription = subscriptionDetails?.subscription;

        if (subscriptionDetails?.responseStatus !== 'SUCCESS' || !subscription) {
          this.snackBarService.showErrorNotificationBanner('Active subscription not found', false);
          this.closeModal();
          return;
        }

        if (!subscription.freezeDate) {
          this.subscriptionService.openHoldAccountModal();
        } else if (subscription.plan.name !== 'Lite') {
          this.subscriptionService.openLitePlanModal();
        } else {
          this.subscriptionService.openCancelConfirmationModal();
        }

        this.closeModal();
      });
    }
  }
}
