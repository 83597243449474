import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BillingState } from '@files-ngrx/states/BillingState';

const feature = createFeatureSelector<BillingState>('billingDetails');

// State Pieces
export const selectPromoDetails = createSelector(feature, (state: BillingState) => state.promoDetails);
export const selectSavedPaymentDetails = createSelector(feature, (state: BillingState) => state.savedPaymentDetails);
export const selectNoPaymentMethodSaved = createSelector(feature, (state) => !!state.savedPaymentDetails?.noPaymentMethodSaved);
export const selectPaymentMethodMessage = createSelector(feature, (state) => {
  if (!state.savedPaymentDetails || state.savedPaymentDetails?.noPaymentMethodSaved) {
    return '';
  }

  return state.savedPaymentDetails.isPaymentMethodPaypal ? state.savedPaymentDetails.paypalEmail : state.savedPaymentDetails.creditCardDetails.maskedNumber;
});

// State Groups
export const selectActivePromotionMap = createSelector(selectPromoDetails, (promoDetails) => {
  const activePromo = promoDetails.activePromo;

  if (!activePromo) {
    return null;
  }

  return promoDetails.promotionsMap[activePromo];
});

export const selectActivePromo = createSelector(selectPromoDetails, (promoDetails) => promoDetails.activePromo);

export const selectBillingDetails = createSelector(feature, (state) => state);

export const selectActivePromoCode = createSelector(selectPromoDetails, (promoDetails) => {
  const activePromo = promoDetails.activePromo;

  if (!activePromo) {
    return null;
  }

  const promotionMap = promoDetails.promotionsMap[activePromo];

  if (!promotionMap || !promotionMap.promotion) {
    return null;
  }

  return promotionMap.promotion.promoCode;
});
