export enum PurchaseStep {
  SELECTION = 'SELECTION',
  FIAT_PURCHASE = 'FIAT_PURCHASE',
  CRYPTO_PURCHASE = 'CRYPTO_PURCHASE',
}

export enum PurchaseType {
  SIMPLE = 'SIMPLE',
  AUCTION = 'AUCTION',
  EMUSICLIVE_TICKET_BUNDLE = 'EMUSICLIVE_TICKET_BUNDLE',
  RELEASE = 'RELEASE',
  MERCH = 'MERCH',
}
