<div class="current_fiat_payment_method" *ngrxLet="vm$ as vm">
  <div class="payment_info">
    <i class="icon credit-card payment_type"
       *ngIf="vm.paymentDetails && vm.paymentDetails.paymentMethod === PaymentMethod.CREDIT_CARD"
       [ngClass]="vm.paymentDetails.creditCardDetails.cardType | creditCardIconClass"></i>

    <i class="icon credit-card paypal"
       *ngIf="vm.paymentDetails && vm.paymentDetails.paymentMethod === PaymentMethod.PAYPAL"></i>

    <span class="billed_to">{{price}} billed to {{vm.paymentMethodMessage}}</span>
  </div>

  <button class="change_payment_method" (click)="openUpdatePaymentModal()">Change</button>

  <emusic-loader-spinner *ngIf="!vm.paymentDetails"></emusic-loader-spinner>
</div>
