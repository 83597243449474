import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DIALOG_DATA, DialogModule, DialogRef } from '@angular/cdk/dialog';
import { DialogCloseButtonComponent } from '@components/dialog/dialog-close-button/dialog-close-button.component';
import { StandardInputComponent } from '@components/forms/regular-input/standard-input.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { AccountService } from '@account/account.service';
import { SnackBarService } from '@components/snack-bar/snack-bar.service';
import { GoogleAnalyticsService } from '@services/google-analytics.service';
import { ModalErrorMessageComponent } from '@components/dialog/modal-error-message/modal-error-message.component';
import { CustomValidators } from '../../../core/utils/form-validators';
import { LetModule } from '@ngrx/component';

interface ChangePasswordForm {
  currentPassword: FormControl<string>;
  newPassword: FormControl<string>;
  confirmationPassword: FormControl<string>;
}

@Component({
  selector: 'change-password-modal',
  standalone: true,
  imports: [CommonModule, DialogModule, DialogCloseButtonComponent, StandardInputComponent, ReactiveFormsModule, ModalErrorMessageComponent, LetModule],
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent {
  serverError!: string;
  requestChangePasswordInProgress = false;
  formGroup!: FormGroup<ChangePasswordForm>;

  constructor(public dialogRef: DialogRef<boolean, ChangePasswordModalComponent>,
              private fb: FormBuilder,
              private snackBar: SnackBarService,
              private accountService: AccountService,
              private ga: GoogleAnalyticsService,
              @Inject(DIALOG_DATA) public data: any) {
    this.formGroup = fb.nonNullable.group<ChangePasswordForm>({
      currentPassword: fb.nonNullable.control('', Validators.required),
      newPassword: fb.nonNullable.control('', [Validators.required, Validators.minLength(6)]),
      confirmationPassword: fb.nonNullable.control('', [Validators.required, CustomValidators.samePassword('newPassword')]),
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  savePassword(): void {
    if (this.formGroup.invalid) {
      return;
    }

    const {currentPassword, newPassword} = this.formGroup.getRawValue();

    this.requestChangePasswordInProgress = true;
    this.accountService.updatePassword(currentPassword, newPassword).subscribe((response) => {
      if (response.responseStatus === 'SUCCESS') {
        this.closeModal();
        this.snackBar.showBlueNotificationBanner('Password Updated');
        this.ga.trackEvent('UX', 'Update Profile', 'Change Password', newPassword.length);
      } else {
        switch (response.responseText) {
          case 'PASSWORDS_ARE_IDENTICAL':
            this.serverError = 'Your new password cannot match the existing one';
            break;
          case 'INCORRECT_PASSWORD':
            this.serverError = 'The password you entered does not match our records';
            break;
        }

        this.requestChangePasswordInProgress = false;
      }
    });
  }
}
