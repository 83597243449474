<div id="cancel__confirmation" class="modal-container">
  <div class="modal-content">
    <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

    <ng-container *ngVar="userDetailsState$ | async as userDetails">
      <div class="modal-body" *ngIf="userDetails.subscriptionDetails as subscriptionDetails">
        <h2 class="modal-header-title">
          {{!subscriptionDetails.subscription?.upcomingCancellationDate ? 'Cancelling...' : 'You Are Set To Cancel'}}
        </h2>

        <ng-container *ngIf="subscriptionDetails.subscription?.upcomingCancellationDate">
          <div class="body">
            <p>
              Your membership is set to cancel and it will not renew as of your next renewal date on
              {{subscriptionDetails.subscription?.upcomingCancellationDate | date:'MMMM dd, y'}}
            </p>

            <p>
              Please remember your free account offers these additional benefits:
            </p>

            <ul>
              <li>Upload and access to your entire music collection</li>

              <li>
                Listen to or download your eMusic purchases from free eMusic app (iOS/Android)
              </li>

              <li>
                Get a 50% discount* on eStories audiobooks membership using your eMusic login, and listen to 1000s of free books
                (<a href="https://www.estories.com" target="_blank">https://www.estories.com</a>)
              </li>
            </ul>

            <p class="restrictions">
              *Restrictions Apply. Available for new members to eStories
            </p>

          </div>

          <div class="buttons-container">
            <button type="button" class="btn red-button" (click)="closeModal()">
              Ok, Got It!
            </button>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
