import {inject, Injectable} from '@angular/core';
import {RestService} from "../ws/rest.service";
import {Observable} from "rxjs";
import {GeneralResponse} from "../protocol/GeneralResponse";

@Injectable({
  providedIn: 'root'
})
export class GdprService {
  private restService = inject(RestService);

  public requestPrivacyDataDump(email: string): Observable<GeneralResponse> {
    return this.restService.postObs('account/requestPrivacyDataDump', {toEmail: email});
  }

  public requestAccountDeletion(): Observable<GeneralResponse> {
    return this.restService.postObs('account/requestAccountDeletion');
  }
}
