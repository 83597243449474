import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { BillingService } from '@services/billing.service';
import { PaymentMethod } from '@services/protocol/GetSavedPaymentDetailsResponse';
import { combineLatestWith, map, Observable } from 'rxjs';
import { NgClass, NgIf } from '@angular/common';
import { CreditCardIconClassPipe } from '@pipes/payment-method-class.pipe';
import { Store } from '@ngrx/store';
import { selectPaymentMethodMessage, selectSavedPaymentDetails } from '@files-ngrx/selectors/billing.selectors';
import { LetModule } from '@ngrx/component';
import { SavedPaymentDetailsState } from '@files-ngrx/states/BillingState';
import { EmusicLoaderSpinnerComponent } from '@components/emusic-loader-spinner/emusic-loader-spinner.component';

interface ViewModel {
  paymentDetails: SavedPaymentDetailsState | undefined;
  paymentMethodMessage: string;
}

@Component({
  selector: 'current-fiat-payment-method',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './current-fiat-payment-method.component.html',
  styleUrls: ['./current-fiat-payment-method.component.scss'],
  imports: [
    NgClass,
    CreditCardIconClassPipe,
    NgIf,
    LetModule,
    EmusicLoaderSpinnerComponent,
  ],
})
export class CurrentFiatPaymentMethodComponent {
  private readonly billingService = inject(BillingService);
  private readonly store = inject(Store);
  protected readonly PaymentMethod = PaymentMethod;
  protected readonly vm$: Observable<ViewModel> = this.store.select(selectSavedPaymentDetails).pipe(
    combineLatestWith(this.store.select(selectPaymentMethodMessage)),
    map(([paymentDetails, paymentMethodMessage]) => ({paymentDetails, paymentMethodMessage}))
  );

  @Input()
  price: string;

  openUpdatePaymentModal(): void {
    this.billingService.openUpdatePaymentModal('Continue to Confirm Payment', 'PAYMENT INFORMATION');
  }
}
