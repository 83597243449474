import { inject, Injectable } from '@angular/core';
import { AccountService } from '@account/account.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserActions } from '../actions/user.actions';
import {
  combineLatestWith,
  concatMap,
  EMPTY,
  exhaustMap,
  first, firstValueFrom,
  from,
  map,
  Observable,
  of,
  switchMap,
  tap
} from 'rxjs';
import { Store } from '@ngrx/store';
import { SubscriptionService } from '@services/subscription.service';
import { SubscriptionDetailsState, UserDetailsState } from '../states/UserDetailsState';
import { selectAccessStatus } from '@files-ngrx/selectors/user.selectors';
import { GeneralResponse } from '../../protocol/GeneralResponse';
import { Platform } from '@account/protocol/Platform';
import { selectPromoDetails } from '@files-ngrx/selectors/billing.selectors';
import { filter } from 'rxjs/operators';
import { selectRouteDetails } from '@files-ngrx/selectors/router.selectors';
import { OnBoardingService } from '@services/on-boarding.service';

@Injectable()
export class UserEffects {
  private readonly accountService = inject(AccountService);
  private readonly subscriptionService = inject(SubscriptionService);
  private readonly onboardingService = inject(OnBoardingService);
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);

  getUserDetails$ = this.getUserDetailsEffect();
  getUserLocation$ = this.getUserLocationEffect();
  getAvailableRewards$ = this.getAvailableRewards();
  getSubscriptionDetails$ = this.getSubscriptionDetails();
  getCheckMembershipBonusEligibility$ = this.checkMembershipBonusEligibility();
  getUserDeviceList$ = this.getUserDeviceListEffect();
  signIn$ = this.signInWithEmail();
  signUp$ = this.signUpWithEmail();
  signOut$ = this.signOut();


  private getUserDetailsEffect() {
    return createEffect(() => this.actions$.pipe(
      ofType(UserActions.getUserDetails),
      tap(() => this.store.dispatch(UserActions.setFirstFetch({firstFetchDone: true}))),
      switchMap(() => this.accountService.getUserDetails().pipe(
          map((response) => UserActions.loadUserDetails(response as UserDetailsState)),
          tap(() => this.store.dispatch(UserActions.getMembershipBonusEligibility())),
          tap(() => this.store.dispatch(UserActions.getUserLocation())),
          tap(() => this.store.dispatch(UserActions.getAvailableRewards())),
          tap(async (res) => {
            // If user is signed in we get the subscription details
            if (res.successful && res.responseText !== 'NO_SESSION') {
              const {currentUrl} = await firstValueFrom(this.store.select(selectRouteDetails));
              const inTermsPage = currentUrl === '/terms'
                || currentUrl === '/privacy'
                || currentUrl === '/terms/mobile'
                || currentUrl === '/privacy/mobile';

              if (!res.agreedToTerms && !inTermsPage) {
                this.onboardingService.openWelcomeBackModal();
              }

              this.store.dispatch(UserActions.getUserDeviceList());
              return this.store.dispatch(UserActions.getSubscriptionDetails());
            }

          }),
        )
      )
    ));
  }

  private getUserDeviceListEffect() {
    return createEffect(() => this.actions$.pipe(
      ofType(UserActions.getUserDeviceList),
      switchMap(() => this.accountService.getDeviceList().pipe(
        filter(response => response.successful),
        map((response) => {
          return UserActions.setUserDeviceList(response);
        }),
      )),
    ));
  }

  private getUserLocationEffect() {
    return createEffect(() => this.actions$.pipe(
      ofType(UserActions.getUserLocation),
      switchMap(() => this.accountService.getUserLocation().pipe(
          map((response) => UserActions.loadUserLocation(response.country))
        )
      )
    ));
  }

  private getAvailableRewards() {
    return createEffect(() => this.actions$.pipe(
      ofType(UserActions.getAvailableRewards),
      switchMap(() => this.accountService.getAvailableRewardList().pipe(
        map(response => UserActions.loadAvailableRewards(response))
      ))
    ));
  }

  private getSubscriptionDetails() {
    return createEffect(() => this.actions$.pipe(
      ofType(UserActions.getSubscriptionDetails),
      switchMap(() => this.subscriptionService.getSubscriptionDetailsObs().pipe(
        map(response => UserActions.loadSubscriptionDetails(response as SubscriptionDetailsState))
      ))
    ));
  }

  private checkMembershipBonusEligibility() {
    return createEffect(() => this.actions$.pipe(
      ofType(UserActions.getMembershipBonusEligibility),
      switchMap(() => this.accountService.getMembershipBonusEligibility().pipe(
        map(response => UserActions.setEligibleForMembershipBonus({eligibleForMembershipBonus: response.responseStatus === 'SUCCESS'}))
      ))
    ));
  }

  private signInWithEmail() {
    return createEffect(() => this.actions$.pipe(
      ofType(UserActions.signIn),
      tap(() => this.store.dispatch(UserActions.accessInProgress({inProgress: true}))),
      switchMap(({
                    email,
                    password,
                    rememberMe,
                    platform,
                    captchaToken,
                    redirectAfter
                  }) => {

        return this.handleCommonSignIn(this.accountService.requestSignIn(email, password, rememberMe, captchaToken), platform);
      }),
    ), {dispatch: false});
  }

  readonly signInWithGooglefx = createEffect(() => this.actions$.pipe(
    ofType(UserActions.signInWithGoogle),
    switchMap(({rememberMe}) => {
      this.store.dispatch(UserActions.accessInProgress({inProgress: true}));

      return this.handleCommonSignIn(from(this.accountService.signInWithGoogleRequest(!!rememberMe)), Platform.GOOGLE);
    })
  ), {dispatch: false});

  readonly signInWithApplefx = createEffect(() => this.actions$.pipe(
    ofType(UserActions.signInWithApple),
    switchMap(({rememberMe}) => {
      this.store.dispatch(UserActions.accessInProgress({inProgress: true}));

      return this.handleCommonSignIn(from(this.accountService.signInWithAppleRequest(!!rememberMe)), Platform.APPLE);
    })
  ), {dispatch: false});

  readonly signInWithFacebookFx = createEffect(() => this.actions$.pipe(
    ofType(UserActions.signInWithFacebook),
    switchMap(({rememberMe}) => {
      this.store.dispatch(UserActions.accessInProgress({inProgress: true}));

      return this.handleCommonSignIn(from(this.accountService.signInWithFacebookRequest(!!rememberMe)), Platform.FACEBOOK);
    }),
  ), {dispatch: false});

  private signUpWithEmail() {
    return createEffect(() => this.actions$.pipe(
      ofType(UserActions.signUp),
      tap(() => this.store.dispatch(UserActions.accessInProgress({inProgress: true}))),
      exhaustMap(({email, fullName, password, marketingCommunicationOptIn, platform, confirmEmail, captchaToken}) => {

        return this.accountService.requestSignUp(email, fullName, password, marketingCommunicationOptIn, confirmEmail, captchaToken).pipe(
          concatMap((response: GeneralResponse) => {
            if (!response.successful) {
              this.store.dispatch(UserActions.accessInProgress({inProgress: false}));
              this.store.dispatch(UserActions.setAccessError({accessError: this.accountService.getSignUpErrorMessage(response.message)}));

              return EMPTY;
            }

            return this.handleCommonSignIn(
              of(response),
              platform,
            );
          })
        );
      }),
    ), {dispatch: false});
  }

  private handleCommonSignIn(response$: Observable<GeneralResponse>, platform: Platform) {
    return response$.pipe(
      switchMap((response) => {
        if (!response.successful) {
          this.store.dispatch(UserActions.accessInProgress({inProgress: false}));
          this.store.dispatch(UserActions.setAccessError({accessError: this.accountService.getSignInErrorMessage(response.message, platform)}));

          return EMPTY;
        }

        // Clear access error we had before
        this.store.dispatch(UserActions.setAccessError({accessError: null}));
        this.store.dispatch(UserActions.getUserDetails());

        return of(response);
      }),
      combineLatestWith(
        this.store.select(selectPromoDetails),
        this.store.select(selectAccessStatus).pipe(first(accessStatus => accessStatus.isSignedIn)),
      ),
      first(),
      tap(([response, promoDetails, accessStatus]) => {
        this.accountService.handleSignInResponse({
          response,
          keepModalOpen: false,
          platform: Platform.FACEBOOK,
          promoDetails,
          accessState: accessStatus.accessState,
        });
      })
    );
  }

  private signOut() {
    return createEffect(() => this.actions$.pipe(
      ofType(UserActions.signOut),
      exhaustMap(() => this.accountService.requestSignOut()),
    ), {dispatch: false});
  }
}

