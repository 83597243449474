<div id="can__we__help" class="modal-container">
  <div class="modal-content">
    <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

    <div class="modal-body">
      <h2 class="modal-header-title">Can We Help?</h2>

      <p>Please tell us why you are leaving and we'll do<br/>our best to support you.</p>

      <div class="reasons">
        <div class="form-group" *ngFor="let reason of leaveReasons">
          <radio-button [value]="reason" color="BLACK" [formControl]="selectedReason">
            {{reason}}
          </radio-button>
        </div>
      </div>

      <div class="buttons-container mobile-horizontal">
        <button type="button" (click)="closeModal()" class="btn new-secondary cancel">
          Back
        </button>

        <button type="button" class="btn red-button confirm" (click)="leaveReasonSelected()">
          Continue
        </button>
      </div>
    </div>
  </div>
</div>
