import { Component, inject, OnDestroy } from '@angular/core';
import { OnboardingStage } from '../protocol/OnboardingStage';
import { filter, tap } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { DialogCloseButtonComponent } from '@components/dialog/dialog-close-button/dialog-close-button.component';
import { OnboardingComponent } from '@account/onboarding/onboarding.component';
import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { EmusicLoaderSpinnerComponent } from '@components/emusic-loader-spinner/emusic-loader-spinner.component';
import { ModalErrorMessageComponent } from '@components/dialog/modal-error-message/modal-error-message.component';
import { NavigationStart, Router } from '@angular/router';
import { merge, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAccessStatus } from '@files-ngrx/selectors/user.selectors';
import { UserActions } from '@files-ngrx/actions/user.actions';
import { AccountService } from '@account/account.service';
import { AccessStatus } from '@files-ngrx/protocol/select-account-chunks';
import { LetModule } from '@ngrx/component';

interface OnBoardingModalPayload {
  stage: OnboardingStage;
  title?: string;
  redirectAfter?: boolean;
  passwordUpdated?: boolean;
}

@Component({
  selector: 'app-onboarding-modal',
  standalone: true,
  imports: [CommonModule, DialogCloseButtonComponent, OnboardingComponent, EmusicLoaderSpinnerComponent,
    ModalErrorMessageComponent, LetModule],
  templateUrl: './onboarding-modal.component.html',
  styleUrls: ['./onboarding-modal.component.scss', '../../scss/account.scss']
})
export class OnboardingModalComponent implements OnDestroy {
  private readonly accountService = inject(AccountService);
  private readonly router = inject(Router);
  private readonly store = inject(Store);
  protected readonly data: OnBoardingModalPayload = inject(DIALOG_DATA);
  protected dialogRef: DialogRef<boolean, OnboardingModalComponent> = inject(DialogRef);

  stage: OnboardingStage;
  title: string | undefined;
  passwordUpdated = false;
  requestInProgress = false;
  errorMessagesArray: string[] = [];
  activeSubscriptions = new Subscription();
  vm$: Observable<AccessStatus>;

  constructor() {
    this.vm$ = merge(
      this.store.select(selectAccessStatus));

    this.stage = this.data.stage;
    this.title = this.data.title;
    this.passwordUpdated = !!this.data.passwordUpdated;

    this.activeSubscriptions = merge(
      this.router.events.pipe(
        filter(events => events instanceof NavigationStart),
        tap(() => {
          this.closeModal(false);
        })
      ),
      this.accountService.observeSignInAction().pipe(
        tap(() => {
          this.closeModal(true);
        }),
      )
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.activeSubscriptions.unsubscribe();
    this.store.dispatch(UserActions.setAccessError({accessError: null}));
    this.store.dispatch(UserActions.accessInProgress({inProgress: false}));
  }

  closeModal(signedIn: boolean): void {
    this.dialogRef.close(signedIn);
  }
}
