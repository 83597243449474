<div id="delete-account" class="modal-container" *ngrxLet="state$ as vm">
  <div class="modal-content">
    <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

    <ng-container *ngIf="vm.stage === 'ASK'">
      <h2 class="modal-header-title">
        WARNING!
      </h2>

      <p class="body">
        By making this request, you will <strong>forever</strong> lose access to purchased content, uploaded content,
        wishlists,
        playlists, transaction history, credit balance and any active subscriptions.
      </p>

      <div class="buttons-container">
        <a href="https://emusic.zendesk.com/hc/en-us/requests/new" target="_blank" class="btn red-button cancel">
          CONTACT SUPPORT
        </a>

        <button class="btn transparent-button confirm" type="button" (click)="requestDeletion()">
          CONTINUE
        </button>
      </div>
    </ng-container>

    <div class="confirmed-dialog" *ngIf="vm.stage === 'CONFIRMED'">
      <h2>Confirmed!</h2>

      <p>
        Great news we have received your request,
        expect to hear from us within 72 hours.
      </p>
    </div>
  </div>

  <emusic-loader-spinner *ngIf="vm.requestInProgress" [enableOpacity]="true"></emusic-loader-spinner>
</div>
