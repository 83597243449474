import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { LocationInfo } from '@account/protocol/location-response';
import { GetAvailableRewardResponse } from '../../protocol/GetAvailableRewardResponse';
import { SubscriptionDetailsState, UserDetailsState } from '../states/UserDetailsState';
import { GetDeviceListResponse } from '@services/protocol/GetDeviceListResponse';
import { Platform } from '@account/protocol/Platform';

export const UserActions = createActionGroup({
  source: 'User',
  events: {
    // Actions Reducers
    'Load User Details': props<UserDetailsState>(),
    'Load User Location': props<LocationInfo>(),
    'Reset UserDetailsState': emptyProps(),
    'Load Available Rewards': props<GetAvailableRewardResponse>(),
    'Load Subscription Details': props<SubscriptionDetailsState>(),
    'Set Eligible For Membership Bonus': props<{ eligibleForMembershipBonus: boolean }>(),
    'Set First Fetch': props<{ firstFetchDone: boolean }>(),
    'Set User Device List': props<GetDeviceListResponse>(),
    'Set User Last Route': props<{ lastRoute: string }>(),
    'Reset User Last Route': emptyProps(),
    'Set User Promo Code': props<{ activePromo: string }>(),
    'Clear User Promo Code': emptyProps(),
    'Set Freemium Flow': props<{ isFreemiumSignUp: boolean }>(),
    'Set Path Intent': props<{ pathIntent: string | null }>(),
    'Set Upload Limit Reached': props<{ reached: boolean }>(),
    'Set Tracks Stored In Cloud': props<{ tracks: number }>(),

    // Action Effects
    'Get User Details': emptyProps(),
    'Get User Location': emptyProps(),
    'Get Available Rewards': emptyProps(),
    'Get Subscription Details': emptyProps(),
    'Get Membership Bonus Eligibility': emptyProps(),
    'Get User Device List': emptyProps(),

    //|- Account
    'Sign In With Facebook': props<{ rememberMe?: boolean }>(),
    'Sign Up With Facebook': props<{ rememberMe?: boolean }>(),
    'Sign In With Google': props<{ rememberMe?: boolean }>(),
    'Sign Up With Google': props<{ rememberMe?: boolean }>(),
    'Sign In With Apple': props<{ rememberMe?: boolean }>(),
    'Sign Up With Apple': props<{ rememberMe?: boolean }>(),
    'Sign In': props<{
      email: string,
      password: string,
      rememberMe: boolean,
      captchaToken: string,
      platform: Platform,
      redirectAfter?: boolean
    }>(),
    'Sign Up': props<{
      email: string,
      fullName: string,
      password: string,
      marketingCommunicationOptIn: boolean,
      platform: Platform,
      confirmEmail: string | null,
      captchaToken: string,
      redirectAfter?: boolean,
    }>(),
    'Sign Out': emptyProps(),
    'Access In Progress': props<{ inProgress: boolean }>(),
    'Set Access Error': props<{ accessError: string | null }>(),
  }
});
