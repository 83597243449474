import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";
import {PendingNftTransaction} from "../../../protocol/GetCollectibleMintingTransactionListResponse";
import {NftService} from "../../../nft.service";
import {LocalStorageService} from "@services/local-storage.service";
import {NgSwitch, NgSwitchCase} from "@angular/common";

type ToastType = 'CRYPTO_ACCOUNT_VERIFY' | 'CRYPTO_PURCHASE';

@Component({
  selector: 'nft-success-crypto-purchase-toast',
  standalone: true,
  imports: [
    NgSwitch,
    NgSwitchCase
  ],
  templateUrl: './nft-success-toast.component.html',
  styleUrls: ['./nft-success-toast.component.scss', '../../../../../../components/snack-bar/snack-bar.component.scss']
})
export class NftSuccessToastComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public parentData: { nft: PendingNftTransaction, toastType: ToastType},
    private snackBarRef: MatSnackBarRef<NftSuccessToastComponent>,
    private nftService: NftService,
    private localStorageService: LocalStorageService,
  ) { }

  dismiss(): void {
    this.snackBarRef.dismiss();
  }

  openCongratsNftModal(): void {
    this.nftService.openCollectiblePurchaseSuccessModal(this.parentData.nft.collectible);
    this.dismiss();
  }

  async openAuctionCollectiblePage() {
    const collectibleId = this.parentData.nft.collectibleId;

    if (window.location.href.indexOf('/nft/' + collectibleId) === -1) {
      this.localStorageService.set('REOPEN_BIDDING_MODAL', true);
      window.location.href = '/nft/' + collectibleId;

    } else {
      const getCollectibleDetailsResponse = await this.nftService.getCollectibleDetails(collectibleId, 'COLLECTIBLE_STORE_PAGE');
      const auctionState = getCollectibleDetailsResponse.collectible.auctionState;
      this.nftService.openBiddingModal(getCollectibleDetailsResponse, auctionState);
    }

    this.dismiss();
  }
}
