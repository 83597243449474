import {Directive, ElementRef, HostListener, inject, Input, Renderer2} from "@angular/core";

@Directive({
  selector: '[fakeButton]',
  standalone: true,
})
export class FakeButtonEventHandlerDirective {
  @Input()
  set overrideRole(role: string) {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'role', role);
  }

  private elementRef: ElementRef<HTMLElement> = inject(ElementRef);
  private renderer = inject(Renderer2);

  constructor() {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'role', 'button');
    this.renderer.setAttribute(this.elementRef.nativeElement, 'tabIndex', '0');
  }

  @HostListener('keydown', ['$event'])
  handleKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      this.elementRef.nativeElement.click();
    }
  }
}
