import {Injectable} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AffiliateProgramService {
  private boughtPlanWithUtm = false;
  private amountBought: string | null = null;

  constructor(private activatedRoute: ActivatedRoute, private cookieService: CookieService) {
  }

  storeAllUTMParams() {
    const utmSource = this.activatedRoute.snapshot.queryParamMap.get('utm_source');

    if (!utmSource) {
      return;
    }

    const ref = this.activatedRoute.snapshot.queryParamMap.get('ref');
    const utmCampaign = this.activatedRoute.snapshot.queryParamMap.get('utm_campaign');
    const utmMedium = this.activatedRoute.snapshot.queryParamMap.get('utm_medium');
    const utmAffiliateTransId = this.activatedRoute.snapshot.queryParamMap.get('utm_affiliateTransId');

    const utmObj = {
      ref,
      utmSource,
      utmCampaign,
      utmMedium,
      utmAffiliateTransId
    };

    const expirationDate = new Date();

    expirationDate.setDate(expirationDate.getDate() + 15);
    this.cookieService.set('EM_AF_P_UTM', JSON.stringify(utmObj), {expires: expirationDate});
  }

  getAllUTMParams() {
    const utmParamsObj = this.cookieService.get('EM_AF_P_UTM');

    return utmParamsObj ? JSON.parse(utmParamsObj) : null;
  }

  removeAffiliateCookies() {
    this.cookieService.delete('EM_AF_P_UTM');
    // $cookies.remove('EM_AF_P_UTM');

    this.boughtPlanWithUtm = false;
    this.amountBought = null;
  }

  getAffiliatePixel(): string | null {
    if (!this.boughtPlanWithUtm || !this.getAllUTMParams() || !this.amountBought) {
      return null;
    }

    const utmObj = this.getAllUTMParams();
    const ref = utmObj.ref;
    const campaign = utmObj.utmCampaign;
    const amount = this.amountBought;

    /*My Points*/
    if (ref === 'mp' || ref === 'sb') {
      this.removeAffiliateCookies();
    }

    if (ref === 'mp' && campaign === 'SW_100Song_Premium') {
      return '<iframe src="https://sboffers.swagbucks.com/GL405?amount=' + amount + '" scrolling="no" frameborder="0" width="1" height="1"></iframe>';
    }

    if (ref === 'sb' && campaign === 'SW_100Song_Premium') {
      return '<iframe src="https://sboffers.swagbucks.com/GL405?amount=' + amount + '" scrolling="no" frameborder="0" width="1" height="1"></iframe>';
    }

    this.removeAffiliateCookies();

    return null;
  }

  setPlanBoughtWithPromoFlag(amount: string | null) {
    this.boughtPlanWithUtm = true;
    this.amountBought = amount;
  }

  getUtmRequestObj() {
    if (!this.getAllUTMParams()) {
      return null;
    }

    const utmObj = this.getAllUTMParams();

    return {
      utmCampaign: utmObj.utmCampaign,
      utmMedium: utmObj.utmMedium,
      utmSource: utmObj.utmSource,
      utmAffiliateTransactionId: utmObj.utmAffiliateTransId
    }
  }
}
