<mat-dialog-content class="dialog-content">
  <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

  <div class="nft_purchase_congrats_modal">
    <h4 class="title">CONGRATS!</h4>

    <h5 class="subtitle">You are the proud owner of</h5>

    <div class="nft_name dotted">{{parentData.nftDetails.name}}</div>

    <div class="nft_image">
      <img imageLoadAnimation class="cover" [alt]="parentData.nftDetails.imageList[0].altText"
           [src]="parentData.nftDetails.imageList[0].imageUrl | customImgUrl: {format: 'GIF'}"/>
    </div>

    <div class="helpful_description">
      Visit <b>My NFTs Page</b> to withdraw your NFT.
    </div>

    <a href="/nfts/my-nfts" class="btn red-button nft_button"> Go to My NFTs </a>

    <div class="nft_details_modal_faq">
      For more information Head over to our
      <a href="https://emusic.zendesk.com/hc/en-us/articles/4402149698963-How-can-I-see-my-newly-acquired-eMusic-NFT-in-my-wallet-"
         target="_blank">FAQs</a>.
    </div>
  </div>
</mat-dialog-content>
