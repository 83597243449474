import { createReducer, on } from '@ngrx/store';
import { Web3State } from '@files-ngrx/states/web3.state';
import { Web3Actions } from '@files-ngrx/actions/web3.actions';
import { WalletError } from '@services/protocol/GetTokenWalletListResponse';
import { cloneDeep } from 'lodash';
import { TokenWallet } from '@services/protocol/TokenWallet';
import { Web3Service } from '@services/web3.service';

const initialState = new Web3State();

export const Web3Reducers = createReducer(initialState,
  //|- Token Wallet reducers
  on(Web3Actions.setTokenWalletList, (state, {response}) => {
    const walletList = response.walletList ? response.walletList.map(wallet => {
      const newWallet: TokenWallet = cloneDeep(wallet);

      return newWallet;
    }) : null;

    return {
      ...state,
      walletList,
      defaultWalletForPayment: response.defaultWalletForPayment ?? null,
      defaultWalletForCredit: response.defaultWalletForCredit ?? null,
    };
  }),

  on(Web3Actions.setWalletError, (state, walletError: WalletError) => {
    return {
      ...state,
      walletError: {...walletError},
    };
  }),

  on(Web3Actions.setDefaultAccount, (state, {defaultAccount}) => ({...state, defaultAccount})),

  on(Web3Actions.setHasConnectedWallet, (state, {isConnected}) => {
    return {...state, hasConnectedWallet: isConnected};
  }),

  on(Web3Actions.clearWallet, (state, {resetAll}) => {
    if (resetAll) {
      return {...new Web3State()};
    }

    return {...state, wallet: null};
  }),

  on(Web3Actions.setWallet, (state, {accounts}) => Web3Service.getAssignedWalletState(state, accounts)),

  on(Web3Actions.setNftTransactionList, (state, {transactionList}) => {
    return {...state, pendingNftTransactionList: transactionList};
  })
);
