import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogCloseButtonComponent } from "@components/dialog/dialog-close-button/dialog-close-button.component";
import { DialogRef } from "@angular/cdk/dialog";
import { BehaviorSubject } from "rxjs";
import { LetModule } from "@ngrx/component";
import { GdprService } from "@services/gdpr.service";
import { first } from "rxjs/operators";
import { EmusicLoaderSpinnerComponent } from "@components/emusic-loader-spinner/emusic-loader-spinner.component";

export interface DeleteAccountState {
  stage: 'ASK' | 'CONFIRMED';
  requestInProgress: boolean;
}

@Component({
  selector: 'delete-account',
  standalone: true,
  imports: [CommonModule, DialogCloseButtonComponent, LetModule, EmusicLoaderSpinnerComponent],
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteAccountComponent {
  private gdprService = inject(GdprService);
  dialogRef = inject(DialogRef);

  state$: BehaviorSubject<DeleteAccountState>;

  constructor() {
    this.state$ = new BehaviorSubject<DeleteAccountState>({stage: 'ASK', requestInProgress: false});
  }

  requestDeletion(): void {
    this.state$.next({...this.state$.value, requestInProgress: true});
    this.gdprService.requestAccountDeletion().pipe(first()).subscribe((response) => {
      if (!response.successful) {
        this.state$.next({...this.state$.value, requestInProgress: false});
        return;
      }

      this.state$.next({stage: 'CONFIRMED', requestInProgress: false});
    });
  }
}
