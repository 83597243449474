import { ChangeDetectionStrategy, Component, inject, Inject } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { DialogCloseButtonComponent } from '@components/dialog/dialog-close-button/dialog-close-button.component';
import { DIALOG_DATA, DialogModule, DialogRef } from '@angular/cdk/dialog';
import { CustomImgUrlPipe } from '@pipes/bg-image.pipe';
import { PlanDetailsState } from '@files-ngrx/states/BillingState';
import { CurrencyToSymbolPipe } from '@pipes/our-currency.pipe';
import { ExtraCreditsMembershipBonus } from '@services/protocol/Subscription';
import { MembershipBonus } from '../../../core/protocol/GetPlanDetailsResponse';
import { Router } from '@angular/router';
import { AccountService } from '@account/account.service';
import { OnboardingStage } from '@account/onboarding/protocol/OnboardingStage';
import { first } from 'rxjs/operators';
import { Release } from '@services/protocol/Release';
import { ImageLoadAnimationDirective } from '@directives/image-load-animation.directive';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatestWith, map, Observable } from 'rxjs';
import { selectIsSignedInUser } from '@files-ngrx/selectors/user.selectors';
import { LetModule } from '@ngrx/component';

@Component({
  selector: 'bonus-modal',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, DialogCloseButtonComponent, DialogModule, CustomImgUrlPipe, NgOptimizedImage, ImageLoadAnimationDirective, LetModule],
  templateUrl: './bonus-modal.component.html',
  styleUrls: ['./bonus-modal.component.scss']
})
export class BonusModalComponent {
  private store = inject(Store);
  bonusValue = new BehaviorSubject<string>('');
  vm$: Observable<{ bonusValue: string, isSignedIn: boolean }>;

  constructor(public dialogRef: DialogRef<BonusModalComponent, boolean>,
              private router: Router,
              private accountService: AccountService,
              @Inject(DIALOG_DATA) public data: { release: Release, planDetails: PlanDetailsState }) {
    const {planList} = data.planDetails;

    for (let i = 0; i < planList.length; i++) {
      if (planList[i].benefits.membershipBonus) {
        this.bonusValue.next(`${new CurrencyToSymbolPipe().transform(planList[i].currency)}${(planList[i].benefits.membershipBonus as ExtraCreditsMembershipBonus | MembershipBonus).amount}`);
      }
    }

    this.vm$ = this.bonusValue.asObservable().pipe(
      combineLatestWith(this.store.select(selectIsSignedInUser)),
      map(([bonusValue, isSignedIn]) => ({bonusValue, isSignedIn})),
    );
  }

  startSubscriptionPurchaseFlow(vm: { bonusValue: string, isSignedIn: boolean }): void {
    this.closeModal();

    if (!vm.isSignedIn) {
      this.accountService.openOnboardingModal({stage: OnboardingStage.SIGN_UP}).pipe(first()).subscribe((signedIn) => {
        this.closeModal();

        if (signedIn) {
          this.router.navigate(['/plans']);
        }
      });
    } else {
      this.router.navigate(['/plans']);
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
