import {MapType} from "../../types/types";

export interface PromoSlide {
  variation?: number;
  promoCode?: string;
  backgroundUrl: string;
  template: string;
  headline: MapType<string>;
  subHeadline?: MapType<string>;
  subtitle: MapType<string>;
  sectionAction: SectionAction,
  cta: MapType<string>;
  minimal?: boolean;
  planDescriptionPaymentDetails?: string;
  planDescriptionFootnote?: string,
  referral?: string,
  bannerText?: string;
  uniquePlanPage: boolean;
  promoUrl?: string;
  eyebrow?: MapType<string>;
}

export enum SectionAction {
  SEE_ALL = 'SEE_ALL',
  WINBACK_PROMO = 'WINBACK_PROMO',
  PROMO = 'PROMO',
  SUBSCRIBE = 'SUBSCRIBE',
  SIGN_UP = 'SIGN_UP',
  UPLOAD = 'UPLOAD',
  BOOSTER_PROMO = 'BOOSTER_PROMO',
  LINK = 'LINK',
}
