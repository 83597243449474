import { Injectable } from '@angular/core';
import { RestService } from '../../../core/ws/rest.service';
import { GetOwnedCollectibleListResponse } from './protocol/GetOwnedCollectibleListResponse';
import { GetCollectibleLiteDetailsResponse } from './protocol/GetCollectibleDetailsResponse';
import { MatDialog } from '@angular/material/dialog';
import {
  NftWithdrawModalComponent
} from './emusic-nfts/my-nfts/my-nfts-item/nft-withdraw-modal/nft-withdraw-modal.component';
import { CollectibleToken } from './protocol/CollectibleToken';
import { GeneralResponse } from '../../../core/protocol/GeneralResponse';
import {
  GetCollectibleMintingTransactionListResponse,
  PendingNftTransaction,
  PendingNftTransactionResponse
} from './protocol/GetCollectibleMintingTransactionListResponse';
import { LocalStorageService } from '@services/local-storage.service';
import {
  BehaviorSubject,
  catchError,
  delay,
  EMPTY,
  interval,
  Observable,
  Subject,
  switchMap,
  tap
} from 'rxjs';
import { SnackBarService } from '@components/snack-bar/snack-bar.service';
import { WithdrawStage } from './emusic-nfts/my-nfts/my-nfts-item/nft-withdraw-modal/protocol/WithdrawStage';
import { Web3Service } from '@services/web3.service';
import { Clipboard } from '@angular/cdk/clipboard';
import {
  MyNftsItemDetailsModalComponent
} from './emusic-nfts/my-nfts/my-nfts-item/my-nfts-item-details-modal/my-nfts-item-details-modal.component';
import { AccountService } from '@account/account.service';
import {
  AuctionStage,
  AuctionState,
  AuctionStateResponse,
  BiddingStatus,
  CollectibleLite,
  PurchaseCollectibleResponse,
  SaleStage,
  SaleStatus
} from './protocol/CollectibleLite';
import { first } from 'rxjs/operators';
import { OnboardingStage } from '@account/onboarding/protocol/OnboardingStage';
import { GlobalService } from '@services/global.service';
import { CurrencyPipe } from '@angular/common';
import { NftPurchaseModalComponent } from './nft-details/modals/nft-purchase-modal/nft-purchase-modal.component';
import { PurchaseStep, PurchaseType } from './protocol/PurchaseFlow';
import {
  TransactionInProgressModalComponent
} from './nft-details/modals/transaction-in-progress-modal/transaction-in-progress-modal.component';
import { GetPurchaseAddressResponse } from '@services/protocol/GetPurchaseAddressResponse';
import { BiddingModalComponent } from './nft-details/modals/bidding-modal/bidding-modal.component';
import {
  FiatVerificationModalComponent
} from './nft-details/modals/fiat-verification-modal/fiat-verification-modal.component';
import {
  CryptoVerificationModalComponent
} from './nft-details/modals/crypto-verification-modal/crypto-verification-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NftSuccessToastComponent } from './nft-details/toasts/nft-success-toast/nft-success-toast.component';
import {
  NftPurchaseCongratsModalComponent
} from './nft-details/modals/nft-purchase-congrats-modal/nft-purchase-congrats-modal.component';
import { Store } from '@ngrx/store';
import { UserActions } from '@files-ngrx/actions/user.actions';
import { selectIsSignedInUser } from '@files-ngrx/selectors/user.selectors';
import { Dialog } from '@angular/cdk/dialog';
import { Web3Actions } from '@files-ngrx/actions/web3.actions';

@Injectable({
  providedIn: 'root'
})
export class NftService {
  private readonly refreshOwedCollectibleList = new Subject<void>();
  private readonly refreshCollectibleDetails = new Subject<void>();
  private readonly endPendingBiddingVerification = new Subject<void>();

  private biddingStatus$ = new BehaviorSubject<BiddingStatus | null>(null);
  private saleStatus$ = new BehaviorSubject<SaleStatus | null>(null);

  private pendingMintingTransactionsArray: Array<CollectibleToken> = [];
  private pendingNftPurchaseMap: Partial<Record<number, boolean>> = {};
  private mintingPollingInterval: any;

  constructor(private restService: RestService,
              private matDialog: MatDialog,
              private dialog: Dialog,
              private accountService: AccountService,
              private localStorageService: LocalStorageService,
              private snackBarService: SnackBarService,
              private snackBar: MatSnackBar,
              private web3Service: Web3Service,
              private globalService: GlobalService,
              private currencyPipe: CurrencyPipe,
              private readonly store: Store,
              private clipboard: Clipboard) {
  }

  listenForEndPendingBiddingVerification(): Observable<void> {
    return this.endPendingBiddingVerification.asObservable();
  }

  notifyEndPendingBiddingVerification(): void {
    this.endPendingBiddingVerification.next();
  }

  notifyRefreshCollectibleDetails(): void {
    this.refreshCollectibleDetails.next();
  }

  listenForRefreshCollectibleDetails(): Observable<void> {
    return this.refreshCollectibleDetails.asObservable();
  }

  notifyRefreshOwedCollectibleList(): void {
    this.refreshOwedCollectibleList.next();
  }

  listenForRefreshOwedCollectibleList(): Observable<void> {
    return this.refreshOwedCollectibleList.asObservable();
  }

  //Server calls
  getCurrentAuctionState(collectibleId: number): Observable<AuctionStateResponse> {
    return this.restService.postObs('collectible/getCollectibleAuctionState', {collectibleId});
  }

  getOwnedCollectibleList() {
    return this.restService.postObs<GetOwnedCollectibleListResponse>('collectible/getOwnedCollectibleList');
  }

  async getCollectibleDetails(collectibleId: number, requestContext: string) {
    const req = {
      collectibleId: collectibleId,
      requestContext
    };
    return await this.restService.post<GetCollectibleLiteDetailsResponse>('collectible/getCollectibleDetails', req);
  }

  async withdrawNft(nftItem: CollectibleToken) {
    const req = {
      collectibleId: nftItem.collectible.id,
      tokenId: nftItem.tokenId
    };
    return await this.restService.post<GeneralResponse>('collectible/mintCollectible', req);
  }

  //NFT Actions
  addEthereumChain(nftItem: CollectibleToken): void {
    if (!this.web3Service.hasMetaMaskInstalled()) {
      this.web3Service.showGetDappBrowserModal();
    } else {
      this.web3Service.addEthereumChain(nftItem.collectible.metamaskConnectionDetails).pipe(
        first(),
        catchError(error => {
          this.snackBarService.showErrorNotificationBanner(error.message);

          return EMPTY;
        })
      ).subscribe(() => {
        this.snackBarService.showBlueNotificationBanner('Network was successfully added to your wallet');
      });
    }
  }

  addContractToWallet(nftItem: CollectibleToken): void {
    if (!this.web3Service.hasMetaMaskInstalled()) {
      this.web3Service.showGetDappBrowserModal();
    } else {
      this.web3Service.switchEthereumChain(nftItem.collectible.metamaskConnectionDetails).pipe(
        delay(200),
        switchMap(() => this.web3Service.watchAsset({
          type: 'ERC20',
          address: nftItem.collectible.contractAddress,
          symbol: nftItem.collectible.symbol,
          image: nftItem.collectible.imageList[0].imageUrl
        }))
      ).subscribe();
    }
  }

  copyContractAddressToClipboard(contractAddress: string): void {
    this.clipboard.copy(contractAddress);
    this.snackBarService.showBlueNotificationBanner('NFT contact address ' + contractAddress + ' has been copied to your clipboard');
  }

  async downloadNft(fileUrl: string, fileName: string) {
    const a = document.createElement('a');
    const href = await this.toDataURL(fileUrl);
    if (!href) {
      return;
    }
    a.href = href;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  toDataURL(url: string) {
    return fetch(url).then((response) => {
      return response.blob();
    }).then(blob => {
      if (blob.size === 0) {
        return null;
      }
      return URL.createObjectURL(blob);
    });
  }

  async sleep(msec: number) {
    return new Promise(resolve => setTimeout(resolve, msec));
  }

  //Modal opening

  openWithdrawNftModal(nftItem: CollectibleToken, stage: WithdrawStage) {
    return this.matDialog.open(NftWithdrawModalComponent, {
      width: '100%',
      maxWidth: '528px',
      panelClass: ['modal', 'short_bottom_padding'],
      data: {
        nftItem: nftItem,
        stage: stage
      }
    });
  }

  openViewNftItem(nftItem: CollectibleToken, fullScreen: boolean, panelClass?: string[]) {
    if (fullScreen) {
      return this.matDialog.open(MyNftsItemDetailsModalComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100vh',
        panelClass: panelClass ? ['modal', ...panelClass] : 'modal',
        data: {
          nftItem: nftItem,
          fullScreen: true
        }
      });
    }

    return this.matDialog.open(MyNftsItemDetailsModalComponent, {
      width: '100%',
      maxWidth: '528px',
      panelClass: ['modal'],
      data: {
        nftItem: nftItem,
        fullScreen: false
      }
    });
  }

  openCollectiblePurchaseModal(nftDetails: GetCollectibleLiteDetailsResponse, purchaseStep?: PurchaseStep, purchaseType?: PurchaseType): void {
    this.matDialog.open(NftPurchaseModalComponent, {
      width: '100%',
      maxWidth: '528px',
      panelClass: ['modal', 'nft_modal'],
      data: {
        nftDetails,
        purchaseStep,
        purchaseType
      }
    });
  }

  openCryptoVerifyAccountModal(nftDetails: GetCollectibleLiteDetailsResponse, verification = true) {
    this.matDialog.open(CryptoVerificationModalComponent, {
      width: '100%',
      maxWidth: '528px',
      panelClass: ['modal'],
      data: {
        nftDetails,
        verification,
      }
    });
  }

  openFiatVerifyAccountModal(nftDetails: GetCollectibleLiteDetailsResponse, auctionState: AuctionState): void {
    this.matDialog.open(FiatVerificationModalComponent, {
      width: '100%',
      maxWidth: '528px',
      panelClass: ['modal', 'nft_modal'],
      data: {
        nftDetails,
        auctionState,
      }
    });
  }

  openBiddingModal(nftDetails: GetCollectibleLiteDetailsResponse, auctionState?: AuctionState): Observable<any> {
    return this.dialog.open(BiddingModalComponent, {
      width: '100%',
      maxWidth: '528px',
      panelClass: ['modal', 'nft_modal', 'no_padding'],
      data: {
        nftDetails,
        auctionState,
      }
    }).closed.pipe(first());
  }

  //Polling minting
  async getCollectibleMintingTransactionList(tokenList: any[], successCallback: Function, errorCallback?: Function) {
    const req = {
      collectibleTokenIdentifierList: tokenList
    };
    return this.restService.post<GetCollectibleMintingTransactionListResponse>('collectible/getCollectibleMintingTransactionList', req)
      .then((response) => {
        if (successCallback) successCallback(response);
      })
      .catch((response => {
        if (errorCallback) errorCallback(response);
      }));
  }

  updateLocalStorageMintingTransactions() {
    this.localStorageService.set('PENDING_MINTING_TRANSACTIONS', this.pendingMintingTransactionsArray);
  }

  addCollectibleToMintingTransactionsArray(mintingTransactionArray: Array<CollectibleToken>) {
    if (!mintingTransactionArray || mintingTransactionArray.length === 0) return;
    for (let i = 0; i < mintingTransactionArray.length; i++) {
      this.pendingMintingTransactionsArray.push(mintingTransactionArray[i]);
    }
    //update localStorage
    this.updateLocalStorageMintingTransactions();

    if (!this.mintingPollingInterval) {
      this.mintingPollingInterval = interval(5000)
        .subscribe(() => {
          this.checkPendingMintingTransactionStatus();
        });
    }
  }

  editPendingMintingTransactionsArray(arrayOfCollectiblesToRemove: any[]) {
    if (!arrayOfCollectiblesToRemove.length) {
      return;
    }

    for (let i = 0; i < arrayOfCollectiblesToRemove.length; i++) {
      let collectibleToRemove = arrayOfCollectiblesToRemove[i];
      if (collectibleToRemove.status === 'MINTING_IN_PROGRESS') {
        continue;
      }

      this.pendingMintingTransactionsArray.forEach((token, index) => {
        if (token.tokenId === collectibleToRemove.tokenId && token.collectible.id === collectibleToRemove.collectible.id) {
          this.pendingMintingTransactionsArray.splice(index, 1);
        }
      });
      this.updateLocalStorageMintingTransactions();
    }
  }

  getCompletedMintingTransactionMessaging(collectibleToken: any) {
    switch (collectibleToken.collectible.type) {
      case 'SIMPLE':
        if (collectibleToken.status === 'MINTED') {
          this.snackBarService.showBlueNotificationBanner(
            collectibleToken.collectible.name +
            ' has been successfully minted to your connected wallet. Click here to <a>Learn More</a>',
            true,
            undefined,
            collectibleToken,
            (event: Event) => {
              event.preventDefault();
              this.openWithdrawNftModal(collectibleToken, WithdrawStage.SUCCESS);
            });
        } else if (collectibleToken.status === 'MINTING_FAILED') {
          this.snackBarService.showErrorNotificationBanner('We have encountered an issue minting ' +
            collectibleToken.collectible.name + ' to your wallet. If this issue persists let us know at' +
            ' <a href="mailto:support@emusic.com">support@emusic.com</a>', true);
        }
        break;
    }

    this.notifyRefreshOwedCollectibleList();
  }

  handlePendingMintingTransactionsResponse(serverResponse: any) {
    if (!serverResponse['collectibleTokenList'].length) {
      return;
    }

    for (let i = 0; i < serverResponse['collectibleTokenList'].length; i++) {
      let collectibleToken = serverResponse['collectibleTokenList'][i];
      if (collectibleToken.status === 'MINTING_IN_PROGRESS') {
        continue;
      }

      let foundToken = this.pendingMintingTransactionsArray.find(i => i.tokenId === collectibleToken.tokenId && i.collectible.id === collectibleToken.collectible.id);
      if (foundToken) {
        this.getCompletedMintingTransactionMessaging(collectibleToken);
      }
    }

    this.editPendingMintingTransactionsArray(serverResponse['collectibleTokenList']);

    if (!this.pendingMintingTransactionsArray.length) {
      this.endPollingMintingTransactionStatus();
    }
  }

  checkPendingMintingTransactionStatus() {
    const requestArray = [];

    for (let i = 0; i < this.pendingMintingTransactionsArray.length; i++) {
      requestArray.push(
        {
          collectibleId: this.pendingMintingTransactionsArray[i].collectible.id,
          tokenId: this.pendingMintingTransactionsArray[i].tokenId
        });
    }
    this.getCollectibleMintingTransactionList(requestArray, (response: any) => {
      this.handlePendingMintingTransactionsResponse(response);
    }).then();
  }

  endPollingMintingTransactionStatus() {
    if (this.mintingPollingInterval) {
      this.mintingPollingInterval.unsubscribe();
      if (this.mintingPollingInterval) {
        delete this.mintingPollingInterval;
      }
    }
  }

  notifyMeWhenNFTSaleStarts(collectibleDetails: CollectibleLite): Observable<GeneralResponse> {
    return this.store.select(selectIsSignedInUser).pipe(
      first(),
      switchMap((isSignedIn) => {
        const notifyRequest = this.restService.postObs<GeneralResponse>('collectible/notifyMeWhenNftIsAvailable', {collectibleId: collectibleDetails.id}).pipe(
          tap((response) => {
            if (response.responseStatus === 'SUCCESS') {
              this.snackBarService.showNotificationBanner(
                `You’ve opted in to receive notifications about ${collectibleDetails.name}`
              );
            } else {
              this.snackBarService.showErrorNotificationBanner(`An error has occurred, please try again.`);
            }
          })
        );

        const ifNotSignedIn = this.accountService.openOnboardingModal({stage: OnboardingStage.SIGN_IN}).pipe(
          first(),
          switchMap((didUserSignedIn) => {
            if (!didUserSignedIn) {
              return EMPTY;
            }

            return notifyRequest;
          })
        );

        return isSignedIn ? ifNotSignedIn : notifyRequest;
      }),
    );
  }

  static getSaleStage(data: CollectibleLite): SaleStage {
    if (data.serverCurrentDate < data.startDate) {
      return SaleStage.PRE_SALE;
    }

    if (data.serverCurrentDate > data.startDate && data.serverCurrentDate < data.endDate) {

      return SaleStage.IN_PROGRESS;
    }

    if (data.serverCurrentDate > data.startDate && data.endDate === null) {

      return SaleStage.ON_SALE_FOREVER;
    }

    if (data.serverCurrentDate >= data.endDate) {
      return SaleStage.POST_SALE;
    }

    return SaleStage.PRE_SALE;
  }

  getAuctionStage(data: CollectibleLite, warningTime: number): AuctionStage {
    if (data.serverCurrentDate < data.startDate) {
      return AuctionStage.PRE_BIDDING;
    }

    if (data.serverCurrentDate > data.startDate && data.serverCurrentDate < data.endDate) {
      const timeLeft = data.endDate - data.serverCurrentDate;

      if (warningTime > timeLeft) {
        return AuctionStage.BIDDING_ABOUT_TO_END;
      }

      return AuctionStage.BIDDING;
    }

    if (data.serverCurrentDate >= data.endDate) {
      return AuctionStage.POST_BIDDING;
    }

    return AuctionStage.PRE_BIDDING;
  }

  getHighestBidLabel(price: number, currency: string): string {
    const currencySymbol = this.globalService.currencyToSymbol(currency);

    return (currencySymbol === '' ? price.toFixed(6) + ' ' + currency
      : this.currencyPipe.transform(price, currency)) ?? '';
  }

  formatUnixTime(unixTime: number): { d: number, h: number, m: number, s: number } {
    const daysLeft = Math.floor((unixTime / (86400 * 1000)));
    const daysLeftMod = unixTime % (86400 * 1000);

    const hoursLeft = Math.floor(daysLeftMod / (3600 * 1000));
    const hoursLeftMod = unixTime % (3600 * 1000);

    const minutesLeft = Math.floor(hoursLeftMod / (60 * 1000));
    const minutesLeftMod = unixTime % (60 * 1000);

    const secondsLeft = Math.floor(minutesLeftMod / 1000);

    return {d: daysLeft, h: hoursLeft, m: minutesLeft, s: secondsLeft};
  }

  humanReadableFormat(time: { d: number, h: number, m: number, s: number }, format: 'indicators' | 'numbers'): string {
    switch (format) {
      case 'indicators':
        return `${time.d}d ${time.h}h ${time.m}m ${time.s}s`;
      case 'numbers':
        return `${time.d}:${time.h}:${time.m}:${time.s}`;
    }
  }

  placeBidRequest(nftId: number, amount: number, currency: string): Observable<GeneralResponse> {
    const request = {
      collectibleId: nftId,
      amount,
      currency
    };

    return this.restService.postObs<GeneralResponse>('collectible/placeBid', request);
  }

  // Broadcast Bidding Status
  public notifyBiddingStatus(newBiddingStatus: BiddingStatus): void {
    this.biddingStatus$.next({
      ...this.biddingStatus$.value,
      ...newBiddingStatus,
    });
  }

  public setPartialBiddingStatus(biddingStatus: BiddingStatus): void {
    this.biddingStatus$.next(biddingStatus);
  }

  public listenForBiddingStatus(): Observable<BiddingStatus | null> {
    return this.biddingStatus$.asObservable();
  }

  // Broadcast Sale Status
  public broadcastSaleStatus(newSaleStatus: SaleStatus): void {
    this.saleStatus$.next(newSaleStatus);
  }

  public listenSaleStatus(): Observable<SaleStatus | null> {
    return this.saleStatus$.asObservable();
  }

  public purchaseCollectibleRequest(collectibleId: number): Observable<PurchaseCollectibleResponse> {
    const req = {collectibleId};

    return this.restService.postObs<PurchaseCollectibleResponse>('billing/purchaseCollectible', req);
  }

  public setPendingNftPurchaseMap(nftId: number, pendingPurchase: boolean) {
    this.pendingNftPurchaseMap[nftId] = pendingPurchase;
  }

  public getPendingNftPurchaseMap(nftId: number): boolean {
    return !!this.pendingNftPurchaseMap[nftId];
  }

  public getPurchaseAddress(collectibleId: number, cryptoCurrency: string, verification?: boolean): Observable<GetPurchaseAddressResponse> {
    const req = {
      collectibleId,
      cryptoCurrency,
      verification
    };

    return this.restService.postObs<GetPurchaseAddressResponse>('collectible/getPurchaseAddress', req);
  }

  openNftPurchaseInProgressModal(nftTransactionsArray: string[]): void {
    this.addAddressToNftTransactionsArray(nftTransactionsArray);

    this.matDialog.open(TransactionInProgressModalComponent, {
      width: '100%',
      maxWidth: '528px',
      panelClass: ['modal', 'nft_modal'],
    });
  }

  addAddressToNftTransactionsArray(pendingTransactions: string[]) {
    this.store.dispatch(Web3Actions.addAddressToNftTransactions({pendingTransactions}));
  }

  updateLocalStorageNftTransactions(pendingNftTransactions: string[]) {
    this.localStorageService.set('PENDING_COLLECTIBLE_TRANSACTIONS', pendingNftTransactions);
  }

  getNftPurchaseTransactionListRequest(purchaseAddressList: string[]): Observable<PendingNftTransactionResponse> {
    const req = {
      purchaseAddressList: purchaseAddressList
    };

    return this.restService.postObs('collectible/getCollectiblePurchaseTransactionList', req);
  }

  getCompletedNftTransactionMessaging(nftTransaction: PendingNftTransaction): void {
    if (nftTransaction.status === 'CONFIRMED') {
      switch (nftTransaction.type) {
        case 'COLLECTIBLE':
          this.notifyEndPendingBiddingVerification();
          this.snackBar.openFromComponent(NftSuccessToastComponent, {
            panelClass: ['blue'],
            data: {
              nft: nftTransaction,
              toastType: 'CRYPTO_PURCHASE'
            }
          });
          break;
        case 'AUCTION_VERIFICATION':
          this.store.dispatch(UserActions.getUserDetails());

          this.snackBar.openFromComponent(NftSuccessToastComponent, {
            panelClass: ['blue'],
            data: {
              nft: nftTransaction,
              toastType: 'CRYPTO_ACCOUNT_VERIFY'
            }
          });
          break;
      }

    } else if (nftTransaction.status === 'FAILED') {
      switch (nftTransaction.type) {
        case 'COLLECTIBLE':
          this.snackBarService.showErrorNotificationBanner('We have encountered an issue with ' +
            'purchasing ' + nftTransaction.collectible.name + '. If this issue persists let us know at' +
            ' <a href="mailto:support@emusic.com">support@emusic.com</a>', true);
          break;

        case 'AUCTION_VERIFICATION':
          this.snackBarService.showErrorNotificationBanner('We have encountered an issue with ' +
            'the verification process. You have not been charge. If this issue continues please ' +
            'contact our support team at <a href="mailto:support@emusic.com">support@emusic.com</a>', true);
          break;
      }
    }

    switch (nftTransaction.type) {
      case 'COLLECTIBLE':
        this.setPendingNftPurchaseMap(nftTransaction.collectible.id, false);
        this.notifyRefreshCollectibleDetails();
        break;
      case 'AUCTION_VERIFICATION':
        this.notifyEndPendingBiddingVerification();
        break;
    }
  }

  startPendingTransactionPoll() {
    const pendingTransactions = this.localStorageService.getItem<string[] | null>('PENDING_COLLECTIBLE_TRANSACTIONS');

    if (!pendingTransactions) {
      return;
    }

    this.addAddressToNftTransactionsArray(pendingTransactions);
  }

  openCollectiblePurchaseSuccessModal(nftDetails: CollectibleLite): void {
    this.matDialog.open(NftPurchaseCongratsModalComponent, {
      width: '100%',
      maxWidth: '528px',
      panelClass: ['modal', 'nft_modal'],
      data: {
        nftDetails
      }
    });
  }
}
