import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {GetCollectibleLiteDetailsResponse} from "../../../protocol/GetCollectibleDetailsResponse";
import {NftService} from "../../../nft.service";
import {GetPurchaseAddressResponse} from "@services/protocol/GetPurchaseAddressResponse";
import {Clipboard} from "@angular/cdk/clipboard";
import {SnackBarService} from "@components/snack-bar/snack-bar.service";
import {DialogCloseButtonComponent} from "@components/dialog/dialog-close-button/dialog-close-button.component";
import {NgClass, NgIf} from "@angular/common";
import {TruncateWalletAddressPipe} from "@pipes/truncate-wallet-address.pipe";
import {SafeHtmlPipe} from "@pipes/safe-html.pipe";
import {EmusicLoaderSpinnerComponent} from "@components/emusic-loader-spinner/emusic-loader-spinner.component";
import {ModalErrorMessageComponent} from "@components/dialog/modal-error-message/modal-error-message.component";
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-crypto-verification-modal',
  standalone: true,
  imports: [
    DialogCloseButtonComponent,
    MatDialogModule,
    NgClass,
    TruncateWalletAddressPipe,
    SafeHtmlPipe,
    EmusicLoaderSpinnerComponent,
    ModalErrorMessageComponent,
    NgIf
  ],
  templateUrl: './crypto-verification-modal.component.html',
  styleUrls: ['./crypto-verification-modal.component.scss']
})
export class CryptoVerificationModalComponent implements OnInit {
  errorMessagesArray: string[] = [];
  requestInProgress = false;

  verificationDetails: GetPurchaseAddressResponse;
  cryptoCountdownZeroed: boolean;

  countdown: {
    m: string;
    s: string;
  }

  descriptionLabelText: string;
  modalTitle: string;
  confirmationText: string;
  descriptionNotice: string;
  descriptionNoticeZeroed: string;
  helpfulDescription: string;

  constructor(public dialogRef: MatDialogRef<CryptoVerificationModalComponent>,
              private clipboard: Clipboard,
              private snackService: SnackBarService,
              @Inject(MAT_DIALOG_DATA) public parentData: { nftDetails: GetCollectibleLiteDetailsResponse, verification: boolean },
              private nftService: NftService) { }

  async ngOnInit() {
    this.modalTitle = this.parentData.verification ? 'VERIFY YOUR ACCOUNT' : 'CONFIRM YOUR PURCHASE';
    this.confirmationText = this.parentData.verification ?
      `In order to keep the integrity of eMusic auctions, we require all first time bidders to verify their
                    account with a 1-time verification fee, after which you will be able to bid on eMusic auctions.`
      : `Please send the final bid price in ETH to the wallet address listed below. Make sure to send enough
                    gas for the transaction to go through.Once payment is received you will receive a notification via
                    email to redeem your NFT.`;

    this.descriptionLabelText = this.parentData.verification ? 'One-Time Verification Fee' : 'Final Bidding Price';

    this.descriptionNotice = this.parentData.verification ? 'Verification fee will expire after 30 minutes.'
      : 'The wallet address below will expire in 30 minutes.';

    this.descriptionNoticeZeroed = this.parentData.verification ? 'Price no longer available. Click refresh to see an updated price.'
      : 'Address no longer available. Click refresh to see an updated address.';

    this.helpfulDescription = this.parentData.verification ?
      `Use any crypto wallet with sufficient ETH in order to get verified by sending the ETH amount to the
                    wallet address above. Make sure you send enough to cover the transaction fees! We will notify you
                    as soon as we receive the verification fee.`
      : `Use any crypto wallet with sufficient ETH in order to complete your purchase by sending the ETH
                    amount to the wallet address above. Make sure you send enough to cover the gas fees!
                    <br />Having problems? Contact us at <a href="mailto:support@emusic.com">support@emusic.com</a>`

    this.nftService.listenForEndPendingBiddingVerification().subscribe(() => {
      this.closeModal();
    });

    await this.refreshVerificationAddress();
    this.nftService.addAddressToNftTransactionsArray([this.verificationDetails.address]);
  }

  async refreshVerificationAddress() {
    this.requestInProgress = true;
    this.verificationDetails = await firstValueFrom(this.nftService.getPurchaseAddress(
      this.parentData.nftDetails.collectible.id,
      'ETH',
      this.parentData.verification));

    this.startCryptoVerificationCountdown();
  }

  startCryptoVerificationCountdown() {
    const countDownDate = this.verificationDetails.expirationDate;
    let now = this.verificationDetails.currentTime;

    const x = setInterval(() => {
      now = now + 1000;

      if (now > countDownDate) {
        this.cryptoCountdownZeroed = true;
        this.requestInProgress = false;
        this.countdown = {m: '00', s: '00'};
        clearInterval(x);

        return;
      }

      this.cryptoCountdownZeroed = false;
      let distance = countDownDate - now;
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.countdown = {
        m: ('0' + minutes).slice(-2),
        s: ('0' + seconds).slice(-2)
      };

      this.requestInProgress = false;
    }, 1000);
  }

  copyAddressToClipboard(): void {
    this.clipboard.copy(this.verificationDetails.address);
    this.snackService.showBlueNotificationBanner('Address Copied to Clipboard!');
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
