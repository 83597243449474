import {DomSanitizer} from "@angular/platform-browser";
import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'safeHtml',
  standalone: true,
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {
  }

  transform(value: string | undefined) {
    return this.domSanitizer.bypassSecurityTrustHtml(value || '');
  }
}
