import { inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserActions } from '@files-ngrx/actions/user.actions';
import { BillingDetailsActions } from '@files-ngrx/actions/billing.actions';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  private readonly router = inject(Router);
  private readonly store = inject(Store);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(response => {
        if (response instanceof HttpResponse) {
          if ((response.url ?? '').includes('account/getUserDetails')) {
            return;
          }

          if (response.body && (response.body as any).responseText === 'NO_SESSION') {
            // If the user receives the response with no session it will be redirected to
            // the landing page and refresh user details
            this.store.dispatch(UserActions.getUserDetails());
            this.store.dispatch(BillingDetailsActions.getPlanList({planFamilyList: ['ANNUAL', 'MONTHLY', 'QUARTERLY']}));
            this.router.navigate(['/']);
          }
        }
      }));
  }
}
