<div id="sign__up--paid" class="paid-onboarding-modal-container modal-container"
     *ngrxLet="accessState$ as accessState">
  <div class="carousel-wrapper">
    <ng-container *ngIf="onboardingSignUpSlides">
      <div class="carousel" (onSlideChange)="currentSlide = $event" carousel [delay]="5000">
        <div class="slide" *ngFor="let slide of onboardingSignUpSlides"
             carouselSlide
             [style.background-image]="slide.backgroundUrl && (slide.backgroundUrl | bgImage)"
             [style.background-color]="slide.backgroundColor">
          <div class="slide-content">
            <h3 class="slide-title" *ngIf="slide.title"
                [innerHTML]="slide.title | safeHtml"></h3>

            <div class="slide-subtitle" [ngClass]="{bigger: slide.template === 'FREE_TRACK'}"
                 *ngIf="slide.subTitle"
                 [innerHTML]="slide.subTitle | safeHtml"></div>

            <ul class="slide-bullets" *ngIf="slide.list">
              <li *ngFor="let bullet of slide.list" [innerHTML]="bullet | safeHtml"></li>
            </ul>

            <div class="apps-container" *ngIf="slide.showAppLinks">
              <a target="_blank" href="https://itunes.apple.com/us/app/emusic/id1047229220?mt=8"
                 aria-label="Go to eMusic app in the Apple App Store">
                <img src="assets/images/on_boarding/img-appstore-copy.svg" alt="Appstore"/>
              </a>

              <a target="_blank" href="https://play.google.com/store/apps/details?id=com.emusic.android"
                 aria-label="Go to eMusic app in the Google Play Store">
                <img src="assets/images/on_boarding/img-play-store-copy@2x.png" alt="Google Play"/>
              </a>
            </div>

            <div class="mobile-background-asset" *ngIf="slide.template === 'FREE_TRACK'">
              <img class="free-track-cover" imageLoadAnimation
                   [src]="data.trackToPurchase.coverUrl | customImgUrl"
                   alt="Release Cover Art"/>
            </div>
          </div>
        </div>
      </div>

      <div class="buttons-container">
        <button class="btn" (click)="selectSlide(i)"
                *ngForRange="onboardingSignUpSlides.length; let i = index">
          <i class="carousel-dot" [ngClass]="{active: i === currentSlide}"></i>
        </button>
      </div>
    </ng-container>
  </div>

  <div class="sign-up-wrapper">
    <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

    <div class="modal-content">
      <sign-up [title]="' FOR FREE'"></sign-up>

      <p class="footnote">
        Already have an account with us?

        <button type="button" class="btn link" (click)="openSignInModal()">
          Sign In!
        </button>
      </p>
    </div>

    <modal-error-message *ngIf="accessState.accessError"
                         [errorMessages]="[accessState.accessError]"></modal-error-message>

    <emusic-loader-spinner [enableOpacity]="true" [height]="'100%'" loaderType="E-DOTS"
                           *ngIf="accessState.accessInProgress"></emusic-loader-spinner>
  </div>
</div>
