<div class="nft_success_crypto_purchase_toast">
  <div class="close" (click)="dismiss()"></div>

  <div class="icon_container blue">
    <div class="icon"></div>
  </div>

  <div class="body" [ngSwitch]="parentData.toastType">

    <ng-container *ngSwitchCase="'CRYPTO_PURCHASE'">
      You have successfully purchased {{parentData.nft.collectible.name}}.
      <span class="learn_more" (click)="openCongratsNftModal()">Learn More</span>.
    </ng-container>

    <ng-container *ngSwitchCase="'CRYPTO_ACCOUNT_VERIFY'">
      Your account has been successfully verified.
      <span class="learn_more" (click)="openAuctionCollectiblePage()"> You can now Continue to place a bid </span>.
    </ng-container>
  </div>
</div>
