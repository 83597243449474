import { Component, inject, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { AccountService } from '../../account.service';
import { environment } from '../../../../environments/environment';
import { Store } from '@ngrx/store';
import { UserActions } from '@files-ngrx/actions/user.actions';
import { CommonModule } from '@angular/common';
import { StandardInputComponent } from '@components/forms/regular-input/standard-input.component';
import { CheckBoxComponent } from '@components/forms/check-box/check-box.component';
import { Observable } from 'rxjs';
import { selectUserLocation } from '@files-ngrx/selectors/user.selectors';
import { LocationInfo } from '@account/protocol/location-response';
import { Platform } from '@account/protocol/Platform';
import { LetModule } from '@ngrx/component';
import { CustomValidators } from '../../../core/utils/form-validators';
import { RouterLinkWithHref } from '@angular/router';
import { NgxTurnstileFormsModule } from '@components/ngx-turnstile/ngx-turnstile-forms.module';

interface ISignUpForm {
  fullName: FormControl<string>;
  email: FormControl<string>;
  confirmEmail: FormControl<string | null>;
  password: FormControl<string>;
  confirmPassword: FormControl<string>;
  captchaToken: FormControl<string>;
  marketingCommunicationOptIn: FormControl<boolean>;
}

@Component({
  selector: 'sign-up',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    StandardInputComponent,
    CheckBoxComponent,
    LetModule,
    NgxTurnstileFormsModule,
    RouterLinkWithHref
  ],
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss', '../../scss/account.scss']
})
export class SignUpComponent implements OnInit {
  private readonly fb = inject(FormBuilder);
  private readonly store = inject(Store);
  protected readonly turnstileSiteKey = environment.turnstileSiteKey;
  public readonly accountService = inject(AccountService);

  @Input()
  title!: string | undefined;

  signUpForm: FormGroup<ISignUpForm>;
  isFacebookInternalBrowser = false;
  serverCallInProgress = false;
  vm$: Observable<LocationInfo | null>;

  constructor() {
    this.vm$ = this.store.select(selectUserLocation);

    this.signUpForm = this.fb.nonNullable.group<ISignUpForm>({
      fullName: this.fb.nonNullable.control('', [Validators.required, Validators.minLength(1)]),
      email: this.fb.nonNullable.control('', [Validators.required, Validators.email]),
      confirmEmail: this.fb.nonNullable.control(null, [Validators.email]),
      password: this.fb.nonNullable.control('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: this.fb.nonNullable.control('', [Validators.required, Validators.minLength(6), CustomValidators.samePassword('password')]),
      captchaToken: this.fb.nonNullable.control<string>('', [Validators.required]),
      marketingCommunicationOptIn: this.fb.nonNullable.control(false),
    });
  }

  ngOnInit(): void {
    if (!environment.production) {
      const randomEmail = 'eml-' + Math.random().toString(36).substring(2, 13) + '@mailinator.com';

      this.signUpForm.patchValue({
        fullName: randomEmail,
        email: randomEmail,
        password: randomEmail,
        confirmPassword: randomEmail,
        marketingCommunicationOptIn: false
      });
    }

    this.isFacebookInternalBrowser = window.navigator.userAgent.includes('FB_IAB/');
  }

  public async signInWithFacebook(): Promise<void> {
    this.store.dispatch(UserActions.signInWithFacebook({rememberMe: false}));
  }

  public async signInWithGoogle() {
    this.store.dispatch(UserActions.signInWithGoogle({rememberMe: false}));
  }

  public async signInWithApple() {
    this.store.dispatch(UserActions.signInWithApple({rememberMe: false}));
  }

  public signUp(): void {
    if (this.signUpForm.invalid) {
      return;
    }

    const {
      email,
      fullName,
      password,
      marketingCommunicationOptIn,
      captchaToken,
      confirmEmail
    } = this.signUpForm.getRawValue();

    this.store.dispatch(UserActions.signUp({
      email,
      password,
      captchaToken,
      marketingCommunicationOptIn,
      platform: Platform.WEB,
      fullName,
      confirmEmail
    }));
  }
}
