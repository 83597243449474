<div class="bonus-modal" *ngrxLet="vm$ as vm">
  <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

  <div class="bonus-wrapper">
    <div class="cover-container">
      <img [alt]="data.release.title"
           height="260"
           width="260"
           imageLoadAnimation
           [ngSrc]="data.release.coverUrl | customImgUrl:{width: 240}"/>
    </div>

    <div class="bonus-info">
      <h2 class="headline">
        Today's New Member Bonus is {{vm.bonusValue}}**
      </h2>

      <p class="body-text">
        Pick a plan, and get {{vm.bonusValue}} in bonus music credit to use on music from
      </p>

      <p class="artist-name dotted">{{data.release.artist.name}}!</p>

      <div class="button-container">
        <button type="button" class="btn red-button" (click)="startSubscriptionPurchaseFlow(vm)">Yes, Please!</button>

        <button type="button" class="btn no-thanks" (click)="closeModal()">No Thanks</button>
      </div>

      <p class="restrictions">
        **Restrictions apply
      </p>
    </div>
  </div>
</div>
