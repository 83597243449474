import { Component, inject, OnInit } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { WhichMobile } from '../../../core/utils/mobile-utils';
import { DialogCloseButtonComponent } from '@components/dialog/dialog-close-button/dialog-close-button.component';
import { NgClass, NgIf } from '@angular/common';
import { WhichBreakpointService } from '@services/which-breakpoint.service';
import { LetModule } from '@ngrx/component';
import { BreakpointQuery } from '@services/protocol/BreakpointHandler';

@Component({
  selector: 'get-metamask-modal',
  standalone: true,
  imports: [
    MatDialogModule,
    DialogCloseButtonComponent,
    NgClass,
    NgIf,
    LetModule
  ],
  templateUrl: './get-metamask-modal.component.html',
  styleUrls: ['./get-metamask-modal.component.scss']
})
export class GetMetamaskModalComponent implements OnInit {
  private breakpointService = inject(WhichBreakpointService);
  dappLogo = this.getDappLogoByDevice();
  title: string;
  text: string;
  breakpoints$ = this.breakpointService.observeBuiltInBreakpoints();
  BreakpointQuery = BreakpointQuery;

  constructor(public dialogRef: MatDialogRef<GetMetamaskModalComponent>) {
  }

  ngOnInit(): void {
    this.title = this.getTitle();
    this.text = this.getText();
  }

  getDappLogoByDevice(): string {
    return WhichMobile.any() ? 'coinbase_logo_img' : 'metamask_logo_img';
  }

  private getTitle(): string {
    return WhichMobile.any() ? 'GET COINBASE WALLET' : 'GET METAMASK';
  }

  private getText(): string {
    if (WhichMobile.any()) {
      return `To use this feature, please open this page within the
                     dAPP browser in the Coinbase Wallet app.`;
    } else {
      return `To use this feature, you need to install MetaMask,
                    a browser extension that allows you to interact with web
                    applications using your Ethereum wallet.`;
    }
  }

  downloadCoinbase(): void {
    if (WhichMobile.Android()) {
      window.location.href = 'https://play.google.com/store/apps/details?id=org.toshi';
    } else if (WhichMobile.iOS()) {
      window.location.href = 'https://itunes.apple.com/us/app/coinbase-wallet/id1278383455?mt=8';
    }
  }

  refreshPage(): void {
    location.reload();
  }
}
