import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { CollectibleToken } from "../../../../protocol/CollectibleToken";
import { NftService } from "../../../../nft.service";
import { DialogCloseButtonComponent } from "@components/dialog/dialog-close-button/dialog-close-button.component";
import { ImageLoadAnimationDirective } from "@directives/image-load-animation.directive";
import { NgIf, NgOptimizedImage } from "@angular/common";
import { CustomImgUrlPipe } from '@pipes/bg-image.pipe';

@Component({
  selector: 'my-nfts-item-details-modal',
  standalone: true,
  imports: [
    DialogCloseButtonComponent,
    MatDialogModule,
    ImageLoadAnimationDirective,
    NgIf,
    CustomImgUrlPipe,
    NgOptimizedImage
  ],
  templateUrl: './my-nfts-item-details-modal.component.html',
  styleUrls: ['./my-nfts-item-details-modal.component.scss']
})
export class MyNftsItemDetailsModalComponent {

  nftItem: CollectibleToken;
  fullScreen: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) private data: {
                nftItem: CollectibleToken,
                fullScreen: boolean
              },
              public dialogRef: MatDialogRef<MyNftsItemDetailsModalComponent>,
              private nftService: NftService) {
    this.nftItem = data.nftItem;
    this.fullScreen = data.fullScreen;
  }

  showFullscreen() {
    this.nftService.openViewNftItem(this.nftItem, true, ['full_screen']);
  }

  async downloadNft() {
    const collectibleFileName = this.nftItem.collectible.name + "." + this.nftItem.collectible.imageList[0].imageFormat.toLowerCase();
    await this.nftService.downloadNft(this.nftItem.collectible.imageList[0].imageUrl, collectibleFileName);
  }
}
