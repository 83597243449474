import {WalletError} from '@services/protocol/GetTokenWalletListResponse';
import {TokenWallet} from '@services/protocol/TokenWallet';

export class Web3State {
  walletList: TokenWallet[] | null = null;
  defaultWalletForPayment: TokenWallet | null = null;
  defaultWalletForCredit: TokenWallet | null = null;
  hasConnectedWallet: boolean = false;
  defaultAccount: string | null = null;
  notConnectedWalletAddress: string | null = null;
  wallet: TokenWallet | null = null;
  hasExposedWeb3Account: boolean = false;
  walletError: WalletError | null = null;
  pendingNftTransactionList: string[];
}
