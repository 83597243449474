import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserDetailsState } from '@files-ngrx/states/UserDetailsState';
import { selectWeb3 } from '@files-ngrx/selectors/web3.selectors';
import { LocationInfo } from '@account/protocol/location-response';

const feature = createFeatureSelector<UserDetailsState>('userDetails');

// State pieces
export const selectIsSignedInUser = createSelector(feature, ({isSignedIn}) => isSignedIn);
export const selectUserEmail = createSelector(feature, ({email}) => email);
export const selectAvailableCredit = createSelector(feature, ({availableCredit}) => availableCredit);
export const selectAvatarUrl = createSelector(feature, ({avatarUrl}) => avatarUrl);
export const selectAvatarInitial = createSelector(feature, ({avatarInitials}) => avatarInitials);
export const selectFullName = createSelector(feature, ({fullName}) => fullName);
export const selectCreationDate = createSelector(feature, ({creationDate}) => creationDate);
export const selectServiceAllowed = createSelector(feature, ({subscriptionPurchaseAllowed}) => subscriptionPurchaseAllowed);
export const selectBoosterPromotionEnabled = createSelector(feature, ({boosterPromotionEnabled}) => boosterPromotionEnabled);
export const selectTracksStoredInCloud = createSelector(feature, ({tracksStoredInCloud}) => tracksStoredInCloud);
export const selectCloudTrackLimit = createSelector(feature, ({userBenefits}) => userBenefits.cloudTrackLimit);
export const selectCloudUnlimited = createSelector(feature, ({userBenefits}) => userBenefits.cloudUnlimited);
export const selectUserLocation = createSelector(feature, ({isSignedIn, location, countryCode, currency}) => {
  if (isSignedIn) {
    return {
      countryCode,
      currency,
      name: '',
    } as LocationInfo;
  }

  return location;
});
export const selectShouldSeeMarketingExtraCreditBanner = createSelector(feature, ({boosterPromotionEnabled, isSignedIn}) => boosterPromotionEnabled && isSignedIn);
export const selectVerifiedCryptoBidder = createSelector(feature, ({verifiedCryptoBidder}) => verifiedCryptoBidder);
export const selectHasSubscription = createSelector(feature, ({hasSubscription}) => hasSubscription);
export const selectAccessState = createSelector(feature, ({accessState}) => accessState);
export const selectDeviceDetails = createSelector(feature, ({deviceDetails}) => deviceDetails);
export const selectUserBenefits = createSelector(feature, ({userBenefits}) => userBenefits);
export const selectCreditBalance = createSelector(feature, ({creditBalance}) => creditBalance);
export const selectHasCloudStorageLeft = createSelector(feature, ({userBenefits}) => !!userBenefits?.hasCloudStorageLeft);
export const selectUploadLimitReached = createSelector(feature, ({uploadLimitReached}) => uploadLimitReached);
export const selectIsOnHold = createSelector(feature, ({subscriptionDetails}) => !!subscriptionDetails?.isOnHold);
export const selectBoosterAllowed = createSelector(feature, ({subscriptionDetails}) => !!subscriptionDetails?.subscription?.plan.benefits.boosterAllowed);
export const selectIsCreditBasedPlan = createSelector(feature, ({subscriptionDetails}) => !!subscriptionDetails?.subscription?.plan.creditBased);
export const selectHasCollectibles = createSelector(feature, ({hasCollectibles}) => hasCollectibles);

// State groups
export const selectPrePurchaseInfo = createSelector(
  selectIsSignedInUser,
  selectServiceAllowed,
  selectHasSubscription, (isSignedIn, subscriptionPurchaseAllowed, hasSubscription) => ({
    isSignedIn,
    subscriptionPurchaseAllowed,
    hasSubscription
  })
);

export const selectUserSnidAndIsSignedIn = createSelector(feature, ({snid, isSignedIn}) => ({snid, isSignedIn}));

export const selectAccessStatus = createSelector(selectIsSignedInUser, selectAccessState, (isSignedIn, accessState) => ({
  isSignedIn,
  accessState
}));

export const selectUserDetails = createSelector(feature, (state) => state);

export const selectHeaderInfo = createSelector(
  selectUserSnidAndIsSignedIn,
  selectAvailableCredit,
  selectAvatarUrl,
  selectAvatarInitial,
  selectFullName,
  selectCreationDate,
  selectWeb3,
  selectCreditBalance,
  selectIsOnHold,
  selectBoosterAllowed,
  selectIsCreditBasedPlan,
  (userSnidAndIsSignedIn, availableCredit, avatarUrl, avatarInitials, fullName, creationDate, web3, creditBalance, isOnHold, boosterAllowed, creditBasedPlan) => ({
    ...userSnidAndIsSignedIn,
    availableCredit,
    creditBalance,
    avatarUrl,
    avatarInitials,
    fullName,
    creationDate,
    web3,
    isOnHold,
    boosterAllowed,
    creditBasedPlan
  }));
