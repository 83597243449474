<div class="dialog-content bidding-modal-container" *ngrxLet="vm$ as vm">
  <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

  <div class="bidding_modal">
    <div class="gray_section">
      <div class="gray_section_wrapper">
        <h4 class="title">
          Place Bid
        </h4>

        <div class="nft_name dotted">{{vm.nftDetails.collectible.name}}</div>

        <nft-seller [artistName]="vm.nftDetails.artist.name"
                    [artistImage]="vm.nftDetails.artist.imageUrl">
        </nft-seller>

        <div class="nft_image">
          <img imageLoadAnimation class="cover" [alt]="vm.nftDetails.collectible.imageList[0].altText"
               width="217"
               height="217"
               [ngSrc]="vm.nftDetails.collectible.imageList[0].imageUrl | customImgUrl: {format: 'GIF'}"/>
        </div>

        <div class="bidding_status">
          <div class="highest_bid">
            <div class="highest_bid_label">Highest Bid</div>

            <div class="bidding_prices">
              <div class="primary_currency">{{vm.highestPrimaryBidLabel}}</div>

              <div class="secondary_currency">{{vm.highestSecondaryBidLabel}}</div>
            </div>
          </div>

          <div class="hr"></div>

          <div class="bidding_timer" *ngIf="vm.biddingStatus && vm.biddingStatus.auctionStage">
            <div class="timer_label">Time left</div>

            <div class="timer"
                 [ngClass]="{'bid_about_to_end': vm.biddingStatus.auctionStage === AuctionStage.BIDDING_ABOUT_TO_END}"
                 *ngIf="vm.biddingStatus.auctionStage === AuctionStage.BIDDING || vm.biddingStatus.auctionStage === AuctionStage.BIDDING_ABOUT_TO_END">
              <span>{{vm.countdown.d}}</span>
              :<span>{{vm.countdown.h}}</span>
              :<span>{{vm.countdown.m}}</span>
              :<span [ngClass]="{'less_than_ten': vm.countdown.s < 11}">{{vm.countdown.s}}</span>
            </div>

            <div class="end_date">
              {{vm.nftDetails.collectible.endDate | date:"MMM. d, y 'at' h:mm a"}} {{timeZone}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="white_section" *ngIf="vm.biddingStatus && vm.biddingStatus.auctionStage">
      <div class="white_section_wrapper">
        <div class="my_bidding_information">
          <h5 class="your_bid_title">Your bid amount</h5>

          <div class="your_bid_value" *ngIf="vm.biddingStatus.auctionStage !== AuctionStage.POST_BIDDING">
            Enter minimum of {{vm.nextMinimumBidLabel}}
          </div>
        </div>

        <div class="bidding_input_container">
          <div class="bidding_input_container_wrapper">
            <div class="bidding_currency" *ngIf="vm.currentCurrency !== 'ETH'">
              {{vm.currentFiatCurrencySymbol}}
            </div>

            <div class="bidding_input">
              <input *ngIf="vm.currentCurrency !== 'ETH'" type="number" step="any"
                     [ngClass]="{'bid_not_accepted': vm.bidNotAccepted, 'bid_ended':vm.biddingStatus.auctionStage === AuctionStage.POST_BIDDING}"
                     [formControl]="biddingFiatAmount"
                     (focus)="clearErrorArray()">

              <input *ngIf="vm.currentCurrency === 'ETH'" type="number" step="any"
                     [ngClass]="{'bid_not_accepted': vm.bidNotAccepted, 'bid_ended':vm.biddingStatus.auctionStage === AuctionStage.POST_BIDDING}"
                     [formControl]="biddingEthAmount"
                     (focus)="clearErrorArray()"/>
            </div>

            <div class="bidding_currency" *ngIf="vm.currentCurrency === 'ETH'">
              ETH
            </div>
          </div>

          <button class="switch_currency" (click)="switchCurrency(vm)">
            Switch to {{vm.nextCurrency}}
          </button>
        </div>

        <div class="bidding_notice" *ngIf="vm.biddingStatus.auctionStage !== AuctionStage.POST_BIDDING">
          By clicking confirm, you commit to buy this item from the seller if you are the winning bidder.
        </div>

        <div class="bidding_notice" *ngIf="vm.biddingStatus.auctionStage === AuctionStage.POST_BIDDING">
          This bidding has ended.
        </div>

        <button class="btn red-button nft_button"
                (click)="submitBidding(vm)"
                [disabled]="vm.biddingStatus.auctionStage === AuctionStage.POST_BIDDING">
          Confirm Bid
        </button>
      </div>
    </div>
  </div>

  <emusic-loader-spinner *ngIf="vm.requestInProgress" [enableOpacity]="true" height="100%"></emusic-loader-spinner>

  <modal-error-message *ngIf="vm.errorMessagesArray && vm.errorMessagesArray.length > 0"
                       [errorMessages]="vm.errorMessagesArray"></modal-error-message>
</div>
