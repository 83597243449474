import {Pipe} from "@angular/core";

@Pipe({
  name: 'truncateWalletAddress',
  standalone: true,
})
export class TruncateWalletAddressPipe {
  transform(walledAddress: string, length = 6, ellipsisBetween = false): string {
    if (!walledAddress) {
      return '';
    }

    if (ellipsisBetween) {
      return walledAddress.substring(0, length) + "..." + walledAddress.substring(walledAddress.length - length);
    }

    return walledAddress.substring(0, length) + "...";
  }
}
