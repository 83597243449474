import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {CollectibleLite} from "../../../protocol/CollectibleLite";
import {DialogCloseButtonComponent} from "@components/dialog/dialog-close-button/dialog-close-button.component";
import {ImageLoadAnimationDirective} from "@directives/image-load-animation.directive";
import { CustomImgUrlPipe } from '@pipes/bg-image.pipe';

@Component({
  selector: 'app-nft-purchase-congrats',
  standalone: true,
  imports: [
    MatDialogModule,
    DialogCloseButtonComponent,
    ImageLoadAnimationDirective,
    CustomImgUrlPipe
  ],
  templateUrl: './nft-purchase-congrats-modal.component.html',
  styleUrls: ['./nft-purchase-congrats-modal.component.scss']
})
export class NftPurchaseCongratsModalComponent {

  constructor(public dialogRef: MatDialogRef<NftPurchaseCongratsModalComponent>,
              @Inject(MAT_DIALOG_DATA) public parentData: { nftDetails: CollectibleLite }) {
  }

}
