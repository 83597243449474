import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AuctionStage, AuctionState } from '../../../protocol/CollectibleLite';
import { GetCollectibleLiteDetailsResponse } from '../../../protocol/GetCollectibleDetailsResponse';
import { Observable, tap } from 'rxjs';
import { DateUtils } from '../../../../../../core/utils/DateUtils';
import { DialogCloseButtonComponent } from '@components/dialog/dialog-close-button/dialog-close-button.component';
import { NftSellerComponent } from '@public-pages/nft/nft-details/nft-seller/nft-seller.component';
import { ImageLoadAnimationDirective } from '@directives/image-load-animation.directive';
import { CustomImgUrlPipe } from '@pipes/bg-image.pipe';
import { DatePipe, NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmusicLoaderSpinnerComponent } from '@components/emusic-loader-spinner/emusic-loader-spinner.component';
import { ModalErrorMessageComponent } from '@components/dialog/modal-error-message/modal-error-message.component';
import { DIALOG_DATA, DialogModule, DialogRef } from '@angular/cdk/dialog';
import { BiddingModalStore } from '@public-pages/nft/nft-details/modals/bidding-modal/store/bidding-modal.store';
import {
  BiddingModalState,
} from '@public-pages/nft/nft-details/modals/bidding-modal/store/bidding-modal.state';
import { LetModule } from '@ngrx/component';

@Component({
  selector: 'app-bidding-modal',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BiddingModalStore],
  imports: [
    DialogModule,
    DialogCloseButtonComponent,
    NftSellerComponent,
    ImageLoadAnimationDirective,
    NgClass,
    NgIf,
    DatePipe,
    FormsModule,
    EmusicLoaderSpinnerComponent,
    ModalErrorMessageComponent,
    CustomImgUrlPipe,
    NgOptimizedImage,
    LetModule,
    ReactiveFormsModule
  ],
  templateUrl: './bidding-modal.component.html',
  styleUrls: ['./bidding-modal.component.scss']
})
export class BiddingModalComponent {
  private readonly biddingModalStore = inject(BiddingModalStore);
  protected readonly timeZone = DateUtils.getTimeZoneName();
  protected readonly dialogRef = inject(DialogRef);
  protected parentData: {
    nftDetails: GetCollectibleLiteDetailsResponse,
    auctionState: AuctionState
  } = inject(DIALOG_DATA);
  protected readonly AuctionStage = AuctionStage;
  protected biddingFiatAmount = new FormControl<number | null>(null);
  protected biddingEthAmount = new FormControl<number | null>(null);
  protected readonly vm$: Observable<BiddingModalState> = this.biddingModalStore.selectViewModel.pipe(
    tap((vm) => {
      // Set the initial minimum fiat and eth bid price to the forms. Only when they're null (only at the beginning)
      if (this.biddingFiatAmount.value === null && this.biddingEthAmount.value === null) {
        this.biddingFiatAmount.setValue(vm.biddingStatus?.auctionState?.nextBidMinimumFiatPrice ?? 0);
        this.biddingEthAmount.setValue(vm.biddingStatus?.auctionState?.nextBidMinimumEthPrice ?? 0);
      }
    })
  );

  constructor() {
    this.biddingModalStore.initializeStoreFx({
      ethForm: this.biddingEthAmount,
      fiatForm: this.biddingFiatAmount,
      nftDetails: this.parentData.nftDetails
    });
  }

  validateBidding(state: BiddingModalState): boolean {
    const biddingStatus = state.biddingStatus!;
    const submittedBid = state.currentCurrency === 'ETH' ? this.biddingEthAmount.value : this.biddingFiatAmount.value;

    if (submittedBid && submittedBid >= this.biddingModalStore.getNextMinimumBidValue(biddingStatus.auctionState!, state.currentCurrency)) {
      return true;
    } else {
      const message = 'Enter minimum of ' + state.nextMinimumBidLabel;

      this.biddingModalStore.patchState({errorMessagesArray: [message]});
      return false;
    }
  }

  clearErrorArray(): void {
    this.biddingModalStore.clearErrors();
  }

  switchCurrency(vm: BiddingModalState): void {
    const currentCurrency = this.biddingModalStore.handleNextCurrency(vm.currentCurrency, vm.locationInfo);
    const nextCurrency = vm.currentCurrency;

    this.biddingEthAmount.setValue(vm.nextBidMinimumEthPrice);
    this.biddingFiatAmount.setValue(vm.nextBidMinimumFiatPrice);
    this.biddingModalStore.patchState({nextCurrency, currentCurrency});
  }

  submitBidding(vm: BiddingModalState): void {
    const {biddingStatus} = vm;
    const {auctionStage} = biddingStatus!;

    if (auctionStage === AuctionStage.POST_BIDDING) {
      return;
    }

    this.clearErrorArray();

    if (this.validateBidding(vm)) {
      this.biddingModalStore.patchState({requestInProgress: true});

      this.biddingModalStore.submitBiddingFx({
        vm,
        biddingEthAmount: this.biddingEthAmount.value ?? 0,
        biddingFiatAmount: this.biddingFiatAmount.value ?? 0,
        dialogRef: this.dialogRef,
      });
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
