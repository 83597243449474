<div id="token__upsell" class="modal-container" *ngVar="vm$ | async as vm">
  <div class="modal-content">
    <dialog-close-button color="white" [dialogRef]="dialogRef"></dialog-close-button>

    <h2>
      {{vm.emuTokenPurchaseAllowed ? 'Join the eMU Token Sale' : 'More than Music'}}
    </h2>

    <h3>
      <ng-container *ngIf="vm.emuTokenPurchaseAllowed; else notAllowed">
        Our eMU Token Sale has started and thousands of members have purchased eMU tokens that will unlock:
      </ng-container>

      <ng-template #notAllowed>
        Coming soon, the eMusic Token will unlock <span class='big__underline'>all-new perks:</span>
      </ng-template>
    </h3>

    <div class="perks-wrapper">
      <article class="perk-container" *ngFor="let perk of perksArray">
        <div class="perk-wrapper">
          <img class="perk-icon price-tag"
               [src]="perk.imageUrl"
               [alt]="'Perk: ' + perk.headline"/>

          <p class="perk-desc">{{perk.headline}}</p>
        </div>
      </article>
    </div>

    <div class="buttons-container">
      <a class="btn red-button" target="_blank" href="https://token.emusic.com/?utm_source=topnavmodal">LEARN MORE</a>
    </div>
  </div>
</div>
