import {GeneralResponse} from "../../protocol/GeneralResponse";
import {Plan, Promotion} from "../../protocol/GetPlanDetailsResponse";

export class GetSubscriptionDetailsResponse extends GeneralResponse {
  subscription: Subscription | null;
  availableCredit: number;
  creditRefreshDate: Date;
  holdAllowed: boolean;
}

export class GetRightListResponse extends GeneralResponse {
  rightList: Right[];
}

export class FreezeSubscriptionResponse extends GeneralResponse {
  unfreezeDate: Date;
}

export interface RightList {
  subscriptionRights: Right[] | null;
  rights: Right[] | null
}

export interface Subscription {
  id: number;
  planId: number;
  currency: string;
  price: number;
  packageId: number;
  nextCreditRenewalDate: Date;
  lastCreditRenewalDate: Date;
  nextBillingAttemptDate: Date;
  lastBillingAttemptDate: Date;
  upcomingCancellationDate: Date;
  upcomingConversionDate: Date;
  upcomingDelinquencyDate: Date;
  freezeDate: Date;
  unfreezeDate: Date;
  suspendedDate: Date;
  creationDate: Date;
  updateDate: Date;
  billingSubscriptionId: string;
  conversionPlan: Plan;
  cycleCount: number;
  retryCount: number;
  plan: Plan;
  creditCycleUnitType: string;
  creditCycleUnitAmount: number;
  billingCycleUnitType: string;
  billingCycleUnitAmount: number;
  status: string;
  discountAmount: number;
  discountCyclesLeft: number;
  addOnAmount: number;
  addOnCyclesLeft: number;
  trial: boolean;
  subscriptionPromotion: SubscriptionPromotion;
}

export interface PlanBenefits {
  type: PlanBenefitsType;
}

export interface BoosterPlanBenefits {
  savingsPercentage: number;
  promotionType: BoosterPromotionType;
  expirationInDays: number;
}

export interface SubscriptionPlanBenefits {
  cloudUnlimited: boolean;
  cloudTrackLimit: number;
  concurrentWebSessionLimit: number;
  deviceLimit: number;
  showAds: boolean;
  holdAllowed: boolean;
  pricingType: PricingType;
  boosterAllowed: boolean;
  estimatedReleasesPerMonth: number;
  estimatedTracksPerMonth: number;
  numberOfFreeReleases: number;
  membershipBonus: MembershipBonus | ExtraCreditsMembershipBonus;
  pitch: PlanPitch;
  bonusAmount: number;
}

export interface ExtraCreditsMembershipBonus {
  amount: string;
  numberOfTracks: number;
}

export interface MembershipBonus {
  type: MembershipBonusType;
}

export interface SubscriptionPromotion {
  promotion: Promotion;
  status: SubscriptionPromotionStatus;
}

export interface Right {
  creationDate: Date;
  expirationDate: Date;
  origin: RightOrigin;
  balanceGranted: number;
  balanceUsed: number;
  balanceRemaining: number;
  currency: Currency;
  status: RightStatus;
}

export enum RightOrigin {
  SUBSCRIPTION = 'SUBSCRIPTION',
  BOOSTER = 'BOOSTER',
  PROMOTIONAL = 'PROMOTIONAL',
  COURTESY = 'COURTESY',
  REFUNDED = 'REFUNDED',
  LOOSE_CHANGE = 'LOOSE_CHANGE',
  CERTIFICATE = 'CERTIFICATE',
  SIGN_UP_BONUS = 'SIGN_UP_BONUS'
}

export enum RightStatus {
  ACTIVE = 'ACTIVE',
  DEPLETED = 'DEPLETED',
  REVOKED = 'REVOKED',
  EXPIRED = 'EXPIRED'
}

export enum SubscriptionPromotionStatus {
  ACTIVE,
  DEPLETED,
  REVOKED,
}

export enum Currency {
  USD = 'USD',
  CAD = 'CAD',
  EUR = 'EUR',
  GBP = 'GBP',
  ETH = 'ETH',
  EMU = 'EMU',
  BTC = 'BTC',
}

export enum PlanFamily {
  TRIAL = 'TRIAL',
  BOOSTER = 'BOOSTER',
  TAF_BONUS = 'TAF_BONUS',
  ANNUAL = 'ANNUAL',
  QUARTERLY = 'QUARTERLY',
  EMPLOYEE = 'EMPLOYEE',
  MONTHLY = 'MONTHLY',
  WINBACK = 'WINBACK',
  SEMI_ANNUAL = 'SEMI_ANNUAL',
  UNKNOWN = 'UNKNOWN',
}

export enum TimeResolutionType {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
  QUARTERLY = 'QUARTERLY',
}

export enum PlanBenefitsType {
  SUBSCRIPTION,
  BOOSTER,
}

export enum PricingType {
  MEMBER_PRICING,
  RETAIL_PRICING
}

export enum PlanPitch {
  MOST_POPULAR,
  BEST_VALUE
}

export enum MembershipBonusType {
  EXTRA_CREDITS
}

export enum BoosterPromotionType {
  REGULAR,
  DOUBLE_CREDITS
}
