import * as moment from 'moment';
import 'moment-timezone';

export class DateUtils {
  private static timeZoneAbvCache: any;

  public static toIsoDate(date: number) {
    return new Date(date).toISOString();
  }

  public static getTimeZoneAbv(): string {
    if (DateUtils.timeZoneAbvCache) {
      return DateUtils.timeZoneAbvCache;
    }

    let timezone = moment().tz(moment.tz.guess()).format('z');

    if (timezone.startsWith('+') || timezone.startsWith('-')) {
      timezone = `UTC${timezone}`;
    }

    DateUtils.timeZoneAbvCache = timezone;

    return timezone;
  }

  public static getTimeZoneName(): string {
    const today = new Date();
    const short = today.toLocaleDateString(undefined);
    const full = today.toLocaleDateString(undefined, {timeZoneName: 'short'});

    // Trying to remove date from the string in a locale-agnostic way
    const shortIndex = full.indexOf(short);
    if (shortIndex >= 0) {
      const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

      // by this time `trimmed` should be the timezone's name with some punctuation -
      // trim it from both sides
      return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');

    } else {
      // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
      return full;
    }
  }
}
