import { Component, Input } from '@angular/core';
import { OnboardingStage } from './protocol/OnboardingStage';
import { CommonModule } from '@angular/common';
import { SignInComponent } from '@account/onboarding/sign-in/sign-in.component';
import { SignUpComponent } from '@account/onboarding/sign-up/sign-up.component';

@Component({
  selector: 'onboarding',
  standalone: true,
  imports: [CommonModule, SignInComponent, SignUpComponent],
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss', '../scss/account.scss']
})
export class OnboardingComponent {
  protected readonly OnboardingStage = OnboardingStage;

  @Input()
  stage!: OnboardingStage;

  @Input()
  redirectAfter = false;

  @Input()
  title!: string | undefined;
}
