import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogRef} from "@angular/cdk/dialog";
import {DialogCloseButtonComponent} from "@components/dialog/dialog-close-button/dialog-close-button.component";

@Component({
  selector: 'email-confirmation-modal',
  standalone: true,
  imports: [CommonModule, DialogCloseButtonComponent],
  templateUrl: './email-confirmation-modal.component.html',
  styleUrls: ['./email-confirmation-modal.component.scss']
})
export class EmailConfirmationModalComponent {
  constructor(public dialogRef: DialogRef<EmailConfirmationModalComponent>) {
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
