import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { Currency } from '@services/protocol/Subscription';

@Pipe({
  name: 'nftPrice',
  standalone: true,
})
export class NftPricePipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {
  }

  transform(pricingMap: any, type: 'FIAT' | 'CRYPTO' = 'FIAT', userCurrency?: string): string {
    if (type === 'CRYPTO') {
      return `${pricingMap['ETH'].toFixed(6)} ETH`;
    }

    const currency = userCurrency ? userCurrency as Currency : Currency.USD;
    const amount = pricingMap[currency];
    let currencyToReturn: string;

    if (currency === 'EMU') {
      currencyToReturn = 'eMU';
    } else {
      currencyToReturn = this.currencyPipe.transform(amount, currency, 'symbol', '1.0-2') ?? '';
    }

    return currencyToReturn;
  }
}
