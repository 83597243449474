import {
  AfterContentInit,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
} from '@angular/core';
import {BehaviorSubject, EMPTY, interval, Observable, Subscription, switchMap, tap} from "rxjs";
import {CarouselSlideDirective} from "@directives/carousel/carousel-slide.directive";
import {CarouselEventsService} from "@directives/carousel/carousel-events.service";

@Directive({
  selector: '[carousel]',
})
export class CarouselDirective implements AfterContentInit, OnInit, OnDestroy {
  @ContentChildren(CarouselSlideDirective)
  carouselSlides: QueryList<CarouselSlideDirective>;

  @Output()
  onSlideChange = new EventEmitter<number>();

  @Input()
  delay = 1000;

  pauseState$ = new BehaviorSubject<boolean>(false);
  slides: HTMLElement[] = [];
  subscriptionsToBeRemoved = new Subscription();
  currentIndex = 1;

  constructor(private elementRef: ElementRef<HTMLElement>, private renderer: Renderer2, private carouselEvents: CarouselEventsService) {
    this.subscriptionsToBeRemoved.add(
      carouselEvents.listenForSliders().subscribe((slide: number) => {
        this.currentIndex = slide;
        this.handleNextSlide();
      })
    );
  }

  ngOnInit() {
    this.renderer.setStyle(this.elementRef.nativeElement, 'transition', 'transform 0.5s linear');
  }

  ngAfterContentInit(): void {
    this.slides = this.carouselSlides.map((item) => item.elementRef.nativeElement);

    this.subscriptionsToBeRemoved.add(this.buildInterval(this.delay).subscribe());
  }

  ngOnDestroy() {
    this.subscriptionsToBeRemoved.unsubscribe();
  }

  @HostListener('mouseenter')
  handleMouseEnter(): void {
    this.pauseState$.next(true);
  }

  @HostListener('mouseleave')
  handleMouseLeave(): void {
    this.pauseState$.next(false);
  }

  buildInterval(delayEx = 5000): Observable<number> {
    return this.pauseState$.asObservable().pipe(switchMap((isPaused) => {
      if (isPaused) {
        return EMPTY;
      }

      return interval((delayEx)).pipe(tap(() => this.handleNextSlide()));
    }));
  }

  handleNextSlide(): void {
    if (this.slides.length === this.currentIndex) {
      this.currentIndex = 0;
    }

    this.onSlideChange.emit(this.currentIndex);
    const nextItem = this.slides[this.currentIndex];
    const nextItemWith = nextItem.getBoundingClientRect().width;

    this.renderer.setStyle(this.elementRef.nativeElement, 'transform', `translateX(-${nextItemWith * this.currentIndex}px)`);
    this.currentIndex += 1;
  }
}
