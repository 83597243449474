import { inject, Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'bgImage',
  standalone: true,
})
export class BgImagePipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {
  }

  transform(url: string, options?: BgImageOptions): SafeUrl {
    if (options) {

      const {width, format, height} = options;

      if (width) url += `&width=${width}`;
      if (height) url += `&height=${height}`;
      if (format) url += `&format=${format}`;
    }

    return this.domSanitizer.bypassSecurityTrustUrl(`url(${url})`);
  }
}

@Pipe({
  name: 'customImgUrl',
  standalone: true,
})
export class CustomImgUrlPipe implements PipeTransform {
  private domSanitizer = inject(DomSanitizer);

  transform(url: string, options?: BgImageOptions): string {
    if (options) {

      const {width, format, height} = options;

      if (width) url += `&width=${width}`;
      if (height) url += `&height=${height}`;
      if (format) url += `&format=${format}`;
    }

    return this.domSanitizer.sanitize(SecurityContext.URL, this.domSanitizer.bypassSecurityTrustUrl(url)) ?? '';
  }
}

export class BgImageOptions {
  width?: number;
  height?: number;
  format?: 'PNG' | 'JPG' | 'GIF';
}
