import {GetPlanDetailsResponse, Plan, Promotion} from "../../protocol/GetPlanDetailsResponse";
import {MapType} from "../../types/types";
import {GetSavedPaymentDetailsResponse} from "@services/protocol/GetSavedPaymentDetailsResponse";

export class BillingState {
  planDetails: PlanDetailsState | undefined;
  promoDetails: PromoDetails = new PromoDetails();
  savedPaymentDetails: SavedPaymentDetailsState | undefined;
  promoBarIsOpen: boolean = false;
}

export class PlanDetailsState extends GetPlanDetailsResponse {
  signUpBonusAmountForFirstPlan: string;
  signUpBonusCurrencyForFirstPlan: string;
  promoPlanList: Plan[];
}

export class PromoDetails {
  promoCode: string | null = null;
  promotionsMap: MapType<PromotionMap | null>;
  /**
   * @description Active Promo is the index (aka active promo object) for the promotionsMap prop
   */
  activePromo: string | null = null;
}

export class SavedPaymentDetailsState extends GetSavedPaymentDetailsResponse {
  isPaymentMethodCC: boolean;
  noPaymentMethodSaved: boolean;
  isPaymentMethodPaypal: boolean;
  paymentMethodString: string;
}

export interface PromotionMap {
  promotion: Promotion | null;
  promoUrl: string | null;
  userSnid: string | null;
  variation: number | null;
}
