import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { BillingService } from '@services/billing.service';
import { AffiliateProgramService } from '@services/affiliate-program.service';
import { PromoService } from '@services/promo.service';
import { CommonModule, DatePipe } from '@angular/common';
import { GoogleAnalyticsService } from '@services/google-analytics.service';
import {
  GetSavedPaymentDetailsResponse,
} from '@services/protocol/GetSavedPaymentDetailsResponse';
import { Store } from '@ngrx/store';
import { BillingDetailsActions } from '@files-ngrx/actions/billing.actions';
import { FormsModule } from '@angular/forms';
import { PaymentService } from '@components/payment-form/payment.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { BillingState, SavedPaymentDetailsState } from '@files-ngrx/states/BillingState';
import { take } from 'rxjs/operators';
import { selectUserLocation } from '@files-ngrx/selectors/user.selectors';
import { NgxTurnstileModule } from '@components/ngx-turnstile/ngx-turnstile.module';
import { environment } from '../../../environments/environment';
import { NgxTurnstileComponent } from '@components/ngx-turnstile/ngx-turnstile.component';

@Component({
  selector: 'payment-form',
  standalone: true,
  imports: [CommonModule, FormsModule, NgxTurnstileModule],
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent implements OnInit, OnDestroy {
  @ViewChild('paypalContainer')
  paypalContainer: ElementRef<HTMLDivElement>;

  @ViewChild(NgxTurnstileComponent)
  turnstile!: NgxTurnstileComponent;

  @Input()
  origin: string;

  @Input()
  hideConfirmButton = false;

  @Input()
  updatePaymentAfterPaypalInserted = false;

  @Input()
  confirmTextButton = 'Continue to Confirm Payment';

  @Output()
  remotePurchaseDone = new EventEmitter<void>();

  @Output()
  loading = new EventEmitter<boolean>();

  @Output()
  purchaseReady = new EventEmitter<boolean>();

  @Output()
  onPaypalChosen = new EventEmitter<boolean>();

  @Output()
  onPaymentUpdate = new EventEmitter<GetSavedPaymentDetailsResponse>();

  @Output()
  onPaymentError = new EventEmitter<string>();

  protected readonly turnstileSiteKey = environment.turnstileSiteKey;
  // Paypal
  paypalChosen = false;
  paypalButtonInitialized = false;
  paypalNonce = null;

  // Braintree
  braintreeClientToken: string;

  // Stripe
  private stripe: any;
  private stripeCard: any;
  stripeClientToken: string;
  stripeFormFilled = false;

  // Errors
  updatePaymentError: string;

  remotePurchaseSubscription = new Subscription();
  private pastPaymentToken = '';

  constructor(private billingService: BillingService,
              private affiliateProService: AffiliateProgramService,
              private promoService: PromoService,
              private datePipe: DatePipe,
              private store: Store<{ billingDetails: BillingState }>,
              private googleAnalyticsService: GoogleAnalyticsService,
              @SkipSelf()
              @Optional()
              private paymentService: PaymentService) {
  }

  async ngOnInit() {
    this.loading.emit(true);

    if (this.paymentService) {
      this.remotePurchaseSubscription.add(
        this.paymentService.observeRemotePurchase().subscribe(() => {
          this.submitPayment();
        }));
    }
  }

  ngOnDestroy(): void {
    this.remotePurchaseSubscription.unsubscribe();
  }

  async submitPayment() {
    this.clearErrors();
    // $scope.$emit('paymentError', paymentCtrl.updatePaymentError);
    this.loading.emit(true);

    if (this.paypalChosen) {
      // Handle Paypal
      await this.handlePaymentNonce(this.paypalNonce);
    } else {
      const result = await this.stripe.confirmCardSetup(
        this.stripeClientToken,
        {
          payment_method: {
            card: this.stripeCard
          }
        }
      );

      if (result.error) {
        // Display error.message in your UI.
        this.loading.emit(false);
        this.displayUpdatePaymentError(result.error.message);
        return;
      }

      await this.handlePaymentNonce(result.setupIntent.payment_method);
    }
  }

  private async initializeBraintree() {
    const braintree = (window as any)['braintree'];

    this.paypalContainer.nativeElement.innerHTML = '';

    braintree.setup(this.braintreeClientToken, 'paypal', {
      container: 'braintree_paypal_button',
      singleUse: false,
      onReady: () => {
        this.paypalButtonInitialized = true;
        this.loading.emit(false);
      },
      onPaymentMethodReceived: (obj: any) => {
        this.paypalChosen = true;
        this.purchaseReady.emit(true);
        this.onPaypalChosen.emit(true);

        if (!this.paypalNonce) {
          this.paypalNonce = obj.nonce;
        }
      },
      onCancelled: () => {
        this.paypalChosen = false;
        this.purchaseReady.emit(false);
        this.purchaseReady.emit(false);
        this.paypalNonce = null;
      }
    });
  }

  private async initializeStripe() {
    const userLocation = await firstValueFrom(this.store.select(selectUserLocation));
    const userCurrency = userLocation ? userLocation.currency : 'USD';
    const stripeKey = this.billingService.getStripePublicKey(userCurrency ?? '');
    this.stripe = (window as any)['Stripe'](stripeKey);

    const elements = this.stripe.elements();

    const elementClasses = {
      focus: 'focused',
      empty: 'empty',
      invalid: 'invalid',
    };

    const style = {
      base: {
        color: '#32325d',
        lineHeight: '18px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    this.stripeCard = elements.create('card', {style: style, classes: elementClasses});
    this.stripeCard.mount('#stripe_container');
    this.stripeCard.addEventListener('change', (event: any) => {
      this.stripeFormFilled = event.complete;
      this.updatePaymentError = event.error ? event.error.message : null;
      this.purchaseReady.emit(event.complete && !event.error);
    });
  }

  displayUpdatePaymentError(code?: string, prefixText?: string, suffixText?: string) {
    this.updatePaymentError = prefixText ? prefixText : 'Unable to save payment details';
    suffixText = suffixText ? suffixText : '';

    if (code) {
      const finalCode = code.toLowerCase().replace(/_/g, ' ').trim().replace(/(^[a-z])/g, function (a) {
        return a.toUpperCase();
      });

      this.updatePaymentError += ' (' + finalCode + ')' + suffixText;
    }

    this.onPaymentError.emit(this.updatePaymentError);
  }

  async handlePaymentNonce(nonce: any) {
    this.loading.emit(true);
    const paymentMethod = this.paypalChosen ? 'PAYPAL_ZERO' : 'CREDIT_CARD_STRIPE';

    try {
      /*Update Payment Method*/
      const savePaymentDetailsResponse = await this.billingService.savePaymentDetails(paymentMethod, nonce, this.origin);

      if (savePaymentDetailsResponse && savePaymentDetailsResponse.responseStatus === 'SUCCESS') {
        this.store.select((state) => state.billingDetails.savedPaymentDetails).pipe(take(2))
          .subscribe(async (savedPaymentDetails: SavedPaymentDetailsState | undefined) => {
            if (!this.pastPaymentToken) {
              this.pastPaymentToken = savedPaymentDetails && savedPaymentDetails?.paymentMethodToken ? savedPaymentDetails?.paymentMethodToken : '';
            }

            if ((!!savedPaymentDetails?.paymentMethod && this.pastPaymentToken !== savedPaymentDetails?.paymentMethodToken) || savedPaymentDetails?.noPaymentMethodSaved) {
              this.onPaymentUpdate.emit(savedPaymentDetails as GetSavedPaymentDetailsResponse);
              this.loading.emit(false);
            }
          });

        this.store.dispatch(BillingDetailsActions.getSavedPaymentDetails());
      } else {
        if (this.paypalChosen) {
          this.turnstile.reset()
        }

        this.loading.emit(false);

        if (savePaymentDetailsResponse.billingError) {
          let prefixText;
          let suffixText = ' Please try again.';

          if (savePaymentDetailsResponse['paymentMethodSaved']) {
            this.store.dispatch(BillingDetailsActions.getSavedPaymentDetails());
            prefixText = 'Payment details updated. ';
          }

          this.displayUpdatePaymentError(savePaymentDetailsResponse.billingError, prefixText, suffixText);
          return;
        }

        switch (savePaymentDetailsResponse.responseText) {
          case 'BILLING_ERROR':
            this.displayUpdatePaymentError(savePaymentDetailsResponse.responseText,
              'Oops, we can\'t process your payment. An error has occurred: ', ' Please try again!');
            break;
          case 'BILLING_PROVIDER_ERROR':
            this.displayUpdatePaymentError(savePaymentDetailsResponse.responseText,
              'We\'ve been notified of an error: ', ' Please try again!');
            break;
          default:
            this.updatePaymentError = this.billingService.getPaymentError(savePaymentDetailsResponse.responseText);
            this.onPaymentError.emit(this.updatePaymentError);
            break;
        }
      }
    } catch (e) {
      this.loading.emit(false);
      this.displayUpdatePaymentError();

      if (this.paypalChosen) {
        this.turnstile.reset()
      }
    }
  }

  clearErrors(): void {
    this.updatePaymentError = '';
    this.onPaymentError.emit('');
  }

  async resetPaymentForm(token: string | null) {
    this.paypalChosen = false;
    this.paypalNonce = null;
    this.paypalButtonInitialized = false;
    this.loading.emit(true);
    const generateTokenResponse = await this.billingService.generateClientToken(token);
    this.braintreeClientToken = generateTokenResponse.btGeneratedClientToken;
    this.stripeClientToken = generateTokenResponse.stGeneratedClientToken;

    await this.initializeBraintree();
    await this.initializeStripe();
    this.loading.emit(false);
  }

  async handleCaptchaToken(token: string | null): Promise<void> {
    await this.resetPaymentForm(token);
  }
}
