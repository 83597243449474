import {Injectable} from '@angular/core';
import {Artist} from '../protocol/Artist';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  currencyToSymbol(currency: string): string {
    switch(currency) {
      case 'USD':
      case 'CAD':
        return '$';
      case 'EUR':
        return '€';
      case 'GBP':
        return '£';
      case 'EMU':
        return 'eMU';
      default:
        return '';
    }
  }

  getArtistPageUrl(artist: Artist): string {
    if (!artist) {
      return '';
    }

    let finalLink: string;

    if (artist.id.indexOf('EMUSIC') > 0) {
      finalLink = '/artist/' + artist.id;
    } else {
      finalLink = '/artist/' + artist.id.replace(/^SEVENDIGITAL_/g, 'rs_');
    }

    if (artist.name) {
      let artistName = this.urlReadyName(artist.name);
      finalLink += '/' + artistName
    }

    return finalLink;
  }

  urlReadyName(name: string) {
    return name == null ? '' : name.trim().replace(/ /g, '-').replace(/[^A-Za-z0-9-]/g, '');
  }
}
