import { ComponentStore } from '@ngrx/component-store';
import { EmailCaptureModalState } from '@components/dialog/email-capture-modal/store/email-capture-modal.state';
import { inject, Injectable } from '@angular/core';
import { exhaustMap, Observable, tap } from 'rxjs';
import { GdprService } from '@services/gdpr.service';
import { DialogRef } from '@angular/cdk/dialog';

@Injectable()
export class EmailCaptureModalStore extends ComponentStore<EmailCaptureModalState> {
  private gdprService = inject(GdprService);

  constructor() {
    super(new EmailCaptureModalState());
  }

  readonly submitRequestFx = this.effect((payload$: Observable<{
    dialogRef: DialogRef,
    email: string
  }>) => payload$.pipe(
    tap(() => this.patchState({requestInProgress: true})),
    exhaustMap(({email, dialogRef}) => this.gdprService.requestPrivacyDataDump(email).pipe(
      tap((response) => {
        if (!response.successful) {
          this.patchState({requestInProgress: false});
          return;
        }

        this.patchState({requestInProgress: false, stage: 'CONFIRMED'});
      }),
    )),
  ));

  readonly selectViewModel = this.select(state => state);
}
