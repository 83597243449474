import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {GetSavedPaymentDetailsResponse} from "@services/protocol/GetSavedPaymentDetailsResponse";
import {DialogCloseButtonComponent} from "@components/dialog/dialog-close-button/dialog-close-button.component";
import {PaymentFormComponent} from "@components/payment-form/payment-form.component";
import { NgClass, NgIf } from "@angular/common";
import {EmusicLoaderSpinnerComponent} from "@components/emusic-loader-spinner/emusic-loader-spinner.component";
import {ModalErrorMessageComponent} from "@components/dialog/modal-error-message/modal-error-message.component";

@Component({
  selector: 'update-payment-modal',
  standalone: true,
  imports: [
    MatDialogModule,
    DialogCloseButtonComponent,
    PaymentFormComponent,
    NgClass,
    NgIf,
    EmusicLoaderSpinnerComponent,
    ModalErrorMessageComponent
  ],
  templateUrl: './update-payment-modal.component.html',
  styleUrls: ['./update-payment-modal.component.scss']
})
export class UpdatePaymentModalComponent {
  requestInProgress = false;
  paymentError: string[] = [];

  constructor(public dialogRef: MatDialogRef<UpdatePaymentModalComponent>,
              @Inject(MAT_DIALOG_DATA) public parentData: { titleText: string, buttonText: string }) {
  }

  loadingInProgress(isRequestInProgress: boolean) {
    this.requestInProgress = isRequestInProgress;
  }

  paymentUpdated(paymentDetails: GetSavedPaymentDetailsResponse) {
    this.dialogRef.close(paymentDetails);
  }

  handlePaymentError(error: string) {
    if (!!error && !this.paymentError.includes(error)) {
      this.paymentError.push(error);
    } else {
      this.paymentError = [];
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
