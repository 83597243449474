<div id="hold__submitted" class="modal-container">
  <div class="modal-content">
    <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

    <div class="modal-body">
      <h2 class="modal-header-title">
        Sure Thing... See You Soon.
      </h2>

      <div class="body">
        <p class="main-message" *ngIf="isMonthly">Your account will be on hold for {{days}} days starting
          on {{holdStartDate | date:'MMMM dd, y'}}. At the end of your hold period, your regular
          subscription will be resumed automatically. However you can login to your account to resume or cancel at
          anytime.
        </p>

        <p class="main-message" *ngIf="!isMonthly">We have sent your request to go on hold for {{days}}
          days starting at the end of your billing cycle {{holdStartDate | date:'MMMM dd, y'}}. A customer
          service representative will be in touch shortly!
        </p>
      </div>

      <div class="buttons-container">
        <button type="button" class="btn red-button" (click)="closeModal()">
          Ok, Got It!
        </button>
      </div>
    </div>
  </div>
</div>
