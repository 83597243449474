import {UserEffects} from "./user.effects";
import {BillingEffects} from "./billing.effects";
import {PublicPageEffects} from "./public-page.effects";
import { Web3Effects } from '@files-ngrx/effects/web3.effects';

export const indexEffects = [
  UserEffects,
  BillingEffects,
  PublicPageEffects,
  Web3Effects,
];
