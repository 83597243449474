<div id="change__plan" class="modal-container mobile-bottom-position">
  <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

  <div class="modal-content">
    <h2 class="modal-header-title">PLAN CHANGE</h2>

    <div class="body">
      <p>
        You are about to change your plan to eMusic Open Access. Any current credit balance will not transfer to
        Open Access.
      </p>
    </div>

    <div class="buttons-container mobile-horizontal">
      <button type="button" class="btn secondary-button cancel">CANCEL</button>

      <a class="btn red-button confirm"
         [routerLink]="['plans/confirmation', 'open access', data.planPricingId]">
        CONFIRM
      </a>
    </div>
  </div>
</div>
