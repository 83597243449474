<div class="st-select" (clickOutside)="clickOutSide()">
  <div class="current-selection option" [ngClass]="{opened: showOptions}" (click)="openSelection()"
       [attr.aria-expanded]="showOptions"
       [attr.aria-labelledby]="labeledBy"
       fakeButton overrideRole="combobox" [attr.aria-controls]="fakeListId">
    {{currentSelection === -1 ? 'Please Describe the Issue' : options[currentSelection].name}}

    <i class="icon triangle-down"></i>
  </div>

  <div class="st-select-options" [id]="fakeListId" *ngIf="showOptions" role="listbox" aria-label="Options">
    <div *ngFor="let option of options; let i = index;" (click)="selectOption(option, i)" class="option"
         fakeButton overrideRole="option" [ngClass]="{active: i === currentSelection}">
      {{propertyToShow ? option[propertyToShow] : option}}
    </div>
  </div>
</div>
