<div id="no__service__modal" class="modal-container mobile-bottom-position">
  <div class="modal-content">
    <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

    <h2 class="modal-header-title">SERVICE UNAVAILABLE</h2>

    <div class="modal-body">
      <div class="body">
        <p>
          Thank you for showing interest in eMusic. We apologize, but our music download service is not yet available
          in your territory. We have taken note of your interest and hope to be able to serve you soon.
        </p>
      </div>

      <div class="buttons-container">
        <button class="btn red-button" type="button" (click)="closeModal()">
          OK, GOT IT!
        </button>
      </div>
    </div>
  </div>
</div>
