import {ChangeDetectionStrategy, Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogModule, DialogRef} from "@angular/cdk/dialog";
import {DialogCloseButtonComponent} from "@components/dialog/dialog-close-button/dialog-close-button.component";
import {UserDetailsState} from "@files-ngrx/states/UserDetailsState";
import {Store} from "@ngrx/store";
import {VarDirective} from "@directives/ng-var.directive";

@Component({
  selector: 'token-upsell-modal',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, DialogModule, DialogCloseButtonComponent, VarDirective],
  templateUrl: './token-upsell-modal.component.html',
  styleUrls: ['./token-upsell-modal.component.scss']
})
export class TokenUpsellModalComponent {
  vm$ = this.store.select('userDetails');
  perksArray: { headline: string, imageUrl: string }[] = [
    {
      headline: '20% off any track or album',
      imageUrl: 'assets/images/on_boarding/token/ic-discount-120-px.svg'
    },
    {
      headline: 'Token holder Sales',
      imageUrl: 'assets/images/on_boarding/token/ic-token-sale-120-px.svg'
    },
    {
      headline: 'Artist Exclusives and Events',
      imageUrl: 'assets/images/on_boarding/token/ic-exclusive-events-120-px.svg'
    }, {
      headline: 'Unlimited Uploads and more...',
      imageUrl: 'assets/images/on_boarding/token/ic-unlimited-cloud-120-px.svg'
    }
  ];

  constructor(public dialogRef: DialogRef<boolean, TokenUpsellModalComponent>,
              private store: Store<{ userDetails: UserDetailsState }>) {
  }
}
