<div id="hold__account" class="modal-container">
  <div class="modal-content">
    <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

    <div class="modal-body">
      <h2 class="modal-header-title">
        Would You Like to Hold<br/>Your Account?
      </h2>

      <div class="body">
        <p>
          Use hold and preserve the plan you have, with no credits or payment until the end of your hold period - up to
          3 months!
        </p>

        <p>
          <b>*NEW*</b> While you are on hold you will be able to still purchase extra credit packs
          to pick up spot purchases.
        </p>

        <div class="hold-duration">
          <h3>Hold Duration:</h3>

          <div class="hold-duration-list">
            <div class="form-group" *ngFor="let duration of holdDurations">
              <radio-button [value]="duration.value" color="BLACK" [formControl]="selectedDuration">
                <b>{{duration.name}}</b>
              </radio-button>
            </div>
          </div>
        </div>
      </div>

      <div class="buttons-container mobile-horizontal">
        <button type="button" (click)="showCancelAccountModal()" class="btn new-secondary cancel">
          Cancel Membership
        </button>

        <button type="button" [disabled]="requestInProgress" class="btn red-button confirm" (click)="submitHoldAccount()">
          Hold Account
        </button>
      </div>
    </div>
  </div>
</div>
