import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from "@angular/common";

@Pipe({
  name: 'currency',
  standalone: true,
})
export class OurCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {
  }

  transform(value: any, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string, showZero?: boolean): string | null {
    if (value === 0 && !showZero) {
      return "FREE";
    }
    return this.currencyPipe.transform(value, currencyCode, display, digitsInfo, locale);
  }
}

@Pipe({
  name: 'currencyToSymbol',
  standalone: true
})
export class CurrencyToSymbolPipe implements PipeTransform {
  transform(currencySymbol: string | undefined): string {
    switch (currencySymbol) {
      case 'USD':
      case 'AD':
        return '$';
      case 'EUR':
        return '€'
      case 'GBP':
        return '£';
      case 'EMU':
        return 'eMU';
      default:
        return '';
    }
  }
}
