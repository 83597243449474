<div id="lite__plan" class="modal-container">
  <div class="modal-content">
    <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

    <div class="modal-body" *ngIf="plan$ | async as plan; else loader">
      <h2 class="modal-header-title">
        Here's How to Enjoy<br/>eMusic for Less...
      </h2>

      <div class="body">
        <p>Keep all your benefits and shop for music at 55% off with our Quarterly Lite Plan.</p>

        <div class="lite-plan-details">
          <div class="lite-plan-bg-text"></div>

          <div class="lite-plan-card">
            <div class="lite-plan-price">
              <span class="symbol">{{ plan.currency | currencyToSymbol }}</span>{{plan.price}}
            </div>
          </div>

          <p class="tracks-received">Get up to 12 tracks</p>

          <p class="price-per-months">
            Only {{ plan.currency | currencyToSymbol }}{{plan.price }} / 3 months
          </p>
        </div>
      </div>

      <div class="buttons-container mobile-horizontal">
        <button type="button" (click)="cancelMembership()" class="btn new-secondary cancel">
          Cancel Membership
        </button>

        <button type="button" class="btn red-button confirm" (click)="selectLitePlan(plan.name, plan.planPricingId)">
          Select Lite Plan!
        </button>
      </div>
    </div>

    <ng-template #loader>
      <emusic-loader-spinner height="220px"></emusic-loader-spinner>
    </ng-template>
  </div>
</div>
