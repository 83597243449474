import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  public set(key: string, data: any): void {
    const stringedData = JSON.stringify(data);

    localStorage.setItem(key, stringedData)
  }

  public get(key: string): any {
    const data = localStorage.getItem(key) ?? '{}';

    return JSON.parse(data);
  }

  setItem(key: string, data: any): void {
    const stringedData = JSON.stringify(data);

    localStorage.setItem(key, stringedData);
  }

  public getItem<T>(key: string): T | null {
    const data = localStorage.getItem(key);

    return data ? JSON.parse(data) : data;
  }

  public itemExists(key: string): boolean {
    return !!localStorage.getItem(key);
  }

  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}
