import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {TokenWallet} from "@services/protocol/TokenWallet";
import {NgClass, NgIf} from "@angular/common";
import {Web3Service} from "@services/web3.service";
import {TruncateWalletAddressPipe} from "@pipes/truncate-wallet-address.pipe";
import {Store} from "@ngrx/store";
import {selectIsSignedInUser} from "@files-ngrx/selectors/user.selectors";
import {combineLatestWith, map, Observable} from "rxjs";
import {LetModule} from "@ngrx/component";
import {selectWalletError} from "@files-ngrx/selectors/web3.selectors";
import { WhichMobile } from "../../core/utils/mobile-utils";

@Component({
  selector: 'token-wallet-state',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './token-wallet-state.component.html',
  styleUrls: ['./token-wallet-state.component.scss'],
  imports: [
    NgIf,
    NgClass,
    TruncateWalletAddressPipe,
    LetModule
  ],
})
export class TokenWalletStateComponent {
  private readonly web3Service = inject(Web3Service);
  private readonly store = inject(Store);
  protected dappBrowserType = this.web3Service.getDappLogoType();
  protected vm$ = this.getViewModel();
  protected readonly isMobile = WhichMobile.any();

  @Input()
  template: 'NO-WALLET' | 'WALLET' | 'NOT-CONNECTED' = 'WALLET';

  @Input()
  notConnectedWalletAddress: string | undefined;

  @Input()
  tokenWallet: TokenWallet | undefined;

  @Input()
  showErrors = false;

  @Input()
  removeBorders = false;

  private getViewModel(): Observable<{ isSignedIn: boolean, errorMessage: null | string }> {
    return this.store.select(selectIsSignedInUser).pipe(combineLatestWith(this.store.select(selectWalletError))).pipe(
      map(([isSignedIn, walletError]) => {
        const errorMessage: string | null = !!walletError ? this.web3Service.getWalletError(walletError.reasonCode) : walletError;

        return {isSignedIn, errorMessage};
      }),
    );
  }

  disconnectWallet(tokenWallet: TokenWallet): void {
    this.web3Service.openDisconnectWalletModal(tokenWallet);
  }

  connectWallet(isSignedIn: boolean): void {
    this.web3Service.connectWallet(isSignedIn).subscribe();
  }
}
