import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {GetTokenWalletListResponse, WalletError} from '@services/protocol/GetTokenWalletListResponse';
import {MetamaskConnectionDetails} from "@public-pages/nft/protocol/CollectibleLite";

export const Web3Actions = createActionGroup({
  source: 'Web3',
  events: {
    // Put
    'Clear Wallet': props<{resetAll?: boolean}>(),
    'Set Has Connected Wallet': props<{ isConnected: boolean }>(),
    'Report Pending Track Token Transaction': props<{
      defaultWalletIdForPayment: string,
      transactionId: string,
      trackId: number
    }>(),
    'Report Pending Release Token Transaction': props<{
      defaultWalletIdForPayment: string,
      transactionId: string,
      releaseId: number
    }>(),
    'Report Pending Booster Token Transaction': props<{
      defaultWalletIdForPayment: string,
      transactionId: string,
      planPricingId: number
    }>(),
    'Set Wallet Error': props<WalletError>(),
    'Set Wallet': props<{ accounts: string[] }>(),
    'Set Default Account': props<{defaultAccount: string | null}>(),
    'Set Nft Transaction List': props<{transactionList: string[]}>(),

    // Effects
    'Set Token Wallet List': props<{ response: GetTokenWalletListResponse }>(),
    'Disconnect Wallet': props<{ walletId: number }>(),
    'Assign Wallet': emptyProps(),
    'Broadcast Wallet Error': props<{ error: WalletError }>(),
    'Refresh Wallet List': props<{ethInWindow?: boolean}>(),
    'Initiate Signature': props<{ connectedAccount: string | null }>(),
    'Check Web3 Connection': props<{directConnect: boolean, metamaskConnectionDetails: MetamaskConnectionDetails}>(),
    'Add Address To Nft Transactions': props<{pendingTransactions: string[]}>(),
  }
})
