<label [for]="id" class="radio-button" fakeButton overrideRole="radio" [attr.aria-checked]="!!finalValue">
  <span class="radio-container">
    <input type="radio" [id]="id" tabindex="-1" [value]="value" [(ngModel)]="finalValue"
           (ngModelChange)="changeValue($event)"/>

    <span class="outer-circle" [style.border-width]="bigOutline ? '2px' : '1.5px'"
          [style.border-color]="_outlineColor"
          [style.width]="bigOutline ? '18px' : '20px'" [style.height]="bigOutline ? '18px' : '20px'">
      <span class="inner-circle" [style.background-color]="_color"
            [style.width]="bigOutline ? '10px' : '8px'" [style.height]="bigOutline ? '10px' : '8px'"
      ></span>
    </span>
  </span>

  <span class="radio-label">
    <ng-content></ng-content>
  </span>
</label>
