import {DeviceType} from "./DeviceType";
import {Platform} from "./Platform";

export class Device {
  name: string;
  deviceType: DeviceType;
  platform: Platform;
  id?: number;
  deviceId?: string;
  creationDate?: Date;
  version?: String;


  constructor(name: string, deviceType: DeviceType, platform: Platform, id?: number, deviceId?: string, creationDate?: Date, version?: String) {
    this.name = name;
    this.deviceType = deviceType;
    this.platform = platform;
    this.id = id;
    this.deviceId = deviceId;
    this.creationDate = creationDate;
    this.version = version;
  }
}
