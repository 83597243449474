import {DurationMap, ShortPrefixDurationMap} from './protocol/common-functions';

export function toMusicShortHHMMSS(durationInSeconds: number, prependZeros?: boolean) {
  let addZeros = false;

  if (prependZeros == null) {
    addZeros = true;
  }

  const sec_num = durationInSeconds; // don't forget the second param
  const hours = Math.floor(sec_num / 3600);
  let minutes: number | string = Math.floor((sec_num - (hours * 3600)) / 60);
  let seconds: number | string = sec_num - (hours * 3600) - (minutes * 60);

  if (minutes < 10 && hours > 0 && (addZeros || hours > 0)) {
    minutes = '0' + minutes;
  }

  if (seconds < 10 && (addZeros || minutes > 0)) {
    seconds = '0' + seconds;
  }

  return hours === 0 ? minutes + ':' + seconds : hours + ':' + minutes + ':' + seconds;
}

export function toMusicScreenReadableDuration(durationInSeconds: number, prependZeros?: boolean) {
  let addZeros = false;

  if (prependZeros == null) {
    addZeros = true;
  }

  const sec_num = durationInSeconds;
  const hours = Math.floor(sec_num / 3600);
  let minutes: string | number = Math.floor((sec_num - (hours * 3600)) / 60);
  let seconds: string | number = sec_num - (hours * 3600) - (minutes * 60);
  const hoursLabel = hours > 1 ? 'hours' : 'hour';
  const minutesLabel = minutes > 1 ? 'minutes' : 'minute';
  const secondsLabel = seconds > 1 ? 'seconds' : 'second';

  if (minutes < 10 && hours > 0 && (prependZeros || hours > 0)) {
    minutes = '0' + minutes;
  }

  if (seconds < 10 && (prependZeros || minutes > 0)) {
    seconds = '0' + seconds;
  }

  if (hours == 0 && seconds == 0) {
    return `${minutes} ${minutesLabel}`;
  }

  if (hours == 0) {
    return `${minutes} ${minutesLabel} ${seconds} ${secondsLabel}`
  }

  if (minutes == 0) {
    return `${hours} ${hoursLabel} ${seconds} ${secondsLabel}`;
  }

  if (seconds == 0) {
    return `${hours} ${hoursLabel} ${minutes} ${minutesLabel}`;
  }

  return `${hours} ${hoursLabel} ${minutes} ${minutesLabel} ${seconds} ${secondsLabel}`;
}

export function getTotalDurationInString(totalDuration: number, shortPreffix: boolean) {
  const durationMap = shortPreffix ? toShortPreffixHHMMSS(totalDuration) : toHHMMSS(totalDuration);

  let stringDuration = '';

  for (const timeMeasure of Object.entries(durationMap)) {
    const [measure, time] = timeMeasure;

    if (time === 0 || measure === 'sec' && shortPreffix) {
      delete (durationMap as any)[measure];
    } else {
      const timeSuffix = time === 1 ? measure.replace(/s$/g, '') : measure;
      stringDuration += ' ' + time + ' ' + timeSuffix;
    }
  }

  return stringDuration;
}

export function toShortPreffixHHMMSS(value: number): ShortPrefixDurationMap {
  const sec_num = value;
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  const seconds = sec_num - (hours * 3600) - (minutes * 60);

  return {hr: hours, min: minutes, sec: seconds};
}

export function toHHMMSS(value: number): DurationMap {
  const sec_num = value;
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  const seconds = sec_num - (hours * 3600) - (minutes * 60);

  return {hours: hours, minutes: minutes, seconds: seconds};
}

export function urlReadyName(name: string) {
  return name === null ? '' : name.trim().replace(/ /g, '-').replace(/[^A-Za-z0-9-]/g, '');
}

export function truncateWalletAddress(address: string, truncateLength?: number): string {
  if (!address) {
    return '';
  }

  return address.substring(0, truncateLength ?? 12) + '...';
}

export function truncNumber(value: number): number {
  if ((value - Math.floor(value)) !== 0) {
    const numberToTruncate = value + '';
    return Number(numberToTruncate.slice(0, numberToTruncate.indexOf('.') + 5));
  } else {
    return value;
  }
}
