import {ChangeDetectionStrategy, Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogModule, DialogRef} from "@angular/cdk/dialog";
import {DialogCloseButtonComponent} from "@components/dialog/dialog-close-button/dialog-close-button.component";
import {NavigationEnd, Router, RouterModule} from "@angular/router";
import {SubscriptionService} from "@services/subscription.service";
import {filter, take} from "rxjs/operators";

@Component({
  selector: 'change-or-cancel-modal',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, DialogModule, DialogCloseButtonComponent, RouterModule],
  templateUrl: './change-or-cancel-modal.component.html',
  styleUrls: ['./change-or-cancel-modal.component.scss']
})
export class ChangeOrCancelModalComponent {

  constructor(public dialogRef: DialogRef<boolean, ChangeOrCancelModalComponent>,
              private router: Router,
              private subscriptionService: SubscriptionService) {
    router.events.pipe(filter(events => events instanceof NavigationEnd), take(1)).subscribe(() => {
      this.dialogRef.close();
    });
  }

  cancelMemberShip(): void {
    this.dialogRef.close();
    this.subscriptionService.openCanWeHelpModal();
  }
}
