import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogModule, DialogRef} from "@angular/cdk/dialog";
import {DialogCloseButtonComponent} from "@components/dialog/dialog-close-button/dialog-close-button.component";
import {SubscriptionService} from "@services/subscription.service";

@Component({
  selector: 'reactivate-modal',
  standalone: true,
  imports: [CommonModule, DialogModule, DialogCloseButtonComponent],
  templateUrl: './reactivate-modal.component.html',
  styleUrls: ['./reactivate-modal.component.scss']
})
export class ReactivateModalComponent {

  constructor(public dialogRef: DialogRef<boolean, ReactivateModalComponent>,
              private subscriptionService: SubscriptionService) {
  }

  cancelMemberShip(): void {
    this.dialogRef.close();
    this.subscriptionService.openCanWeHelpModal();
  }
}
