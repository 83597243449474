import { Component, inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogCloseButtonComponent } from '@components/dialog/dialog-close-button/dialog-close-button.component';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { OnboardingComponent } from '@account/onboarding/onboarding.component';
import { ModalErrorMessageComponent } from '@components/dialog/modal-error-message/modal-error-message.component';
import { EmusicLoaderSpinnerComponent } from '@components/emusic-loader-spinner/emusic-loader-spinner.component';
import { SignUpComponent } from '@account/onboarding/sign-up/sign-up.component';
import { AccountService } from '@account/account.service';
import { OnboardingStage } from '@account/onboarding/protocol/OnboardingStage';
import { OnBoardingService, OnboardingSlide } from '@services/on-boarding.service';
import { SafeHtmlPipe } from '@pipes/safe-html.pipe';
import { NgForRangeDirective } from '@directives/ng-for-range.directive';
import { CarouselModule } from '@directives/carousel/carousel.module';
import { CarouselEventsService } from '@directives/carousel/carousel-events.service';
import { ImageLoadAnimationDirective } from '@directives/image-load-animation.directive';
import { Store } from '@ngrx/store';
import { selectAccessState } from '@files-ngrx/selectors/user.selectors';
import { LetModule } from '@ngrx/component';
import { Observable, Subscription } from 'rxjs';
import { AccessState } from '@files-ngrx/states/UserDetailsState';
import { ReleaseDetailsTrack } from '@services/protocol/Track';
import { BgImagePipe, CustomImgUrlPipe } from '@pipes/bg-image.pipe';

interface ModalData {
  trackToPurchase: ReleaseDetailsTrack;
}

@Component({
  selector: 'paid-onboarding-modal',
  standalone: true,
  imports: [CommonModule, DialogCloseButtonComponent, OnboardingComponent, ModalErrorMessageComponent,
    EmusicLoaderSpinnerComponent, SignUpComponent, SafeHtmlPipe, NgForRangeDirective, CarouselModule, ImageLoadAnimationDirective, LetModule, CustomImgUrlPipe, BgImagePipe],
  templateUrl: './paid-onboarding-modal.component.html',
  styleUrls: ['./paid-onboarding-modal.component.scss', '../../scss/account.scss']
})
export class PaidOnboardingModalComponent implements OnDestroy {
  private readonly store = inject(Store);
  private readonly carouselEvents = inject(CarouselEventsService);
  private readonly accountService = inject(AccountService);
  private readonly onboardingService = inject(OnBoardingService);
  protected readonly data: ModalData = inject(DIALOG_DATA);
  protected readonly dialogRef: DialogRef<PaidOnboardingModalComponent> = inject(DialogRef);

  currentSlide = 0;
  paidSignUpSuccess = false;
  onboardingSignUpSlides: OnboardingSlide[];
  accessState$: Observable<AccessState> = this.store.select(selectAccessState);
  signedInSubs: Subscription;

  constructor() {
    this.onboardingSignUpSlides = [...this.onboardingService.onboardingSignUpSlides];
    this.signedInSubs = this.accountService.observeSignInAction().subscribe(() => {
      this.closeModal();
    });
  }

  ngOnDestroy(): void {
    this.signedInSubs.unsubscribe();
  }

  openSignInModal(): void {
    this.accountService.openOnboardingModal({stage: OnboardingStage.SIGN_IN});
    this.dialogRef.close();
  }

  selectSlide(slide: number): void {
    this.currentSlide = slide;
    this.carouselEvents.changeSlider(slide);
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
