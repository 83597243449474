import {Component, Inject, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DIALOG_DATA, DialogModule, DialogRef} from "@angular/cdk/dialog";
import {DialogCloseButtonComponent} from "@components/dialog/dialog-close-button/dialog-close-button.component";
import {Store} from "@ngrx/store";
import {UserDetailsState} from "@files-ngrx/states/UserDetailsState";
import {skip} from "rxjs";
import {SnackBarService} from "@components/snack-bar/snack-bar.service";
import {take} from "rxjs/operators";
import {UserActions} from "@files-ngrx/actions/user.actions";

@Component({
  selector: 'hold-submitted-modal',
  standalone: true,
  imports: [CommonModule, DialogModule, DialogCloseButtonComponent],
  templateUrl: './hold-submitted-modal.component.html',
  styleUrls: ['./hold-submitted-modal.component.scss']
})
export class HoldSubmittedModalComponent implements OnInit {
  isMonthly = false;
  days!: number;
  holdStartDate!: Date;

  constructor(public dialogRef: DialogRef<boolean, HoldSubmittedModalComponent>,
              private store: Store<{ userDetails: UserDetailsState }>,
              private snackBarService: SnackBarService,
              @Inject(DIALOG_DATA) public data: { pendingHoldDays: number }) {
    this.days = data.pendingHoldDays;
  }

  ngOnInit(): void {
    this.store.select('userDetails').pipe(skip(1), take(1)).subscribe(({subscriptionDetails}: UserDetailsState) => {
      if (subscriptionDetails?.responseStatus !== 'SUCCESS' || !subscriptionDetails?.subscription) {
        this.snackBarService.showErrorNotificationBanner('Active subscription not found', false, 3000);
        this.closeModal();
        return;
      }

      this.isMonthly = subscriptionDetails?.subscription.billingCycleUnitType === 'MONTHLY';

      if (this.isMonthly) {
        this.holdStartDate = subscriptionDetails?.subscription.freezeDate;
      } else {
        this.holdStartDate = subscriptionDetails?.subscription.nextBillingAttemptDate;
      }
    });

    this.store.dispatch(UserActions.getSubscriptionDetails());
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
