<div class="artist_avatar_container" [style.width.px]="avatarSize" [style.height.px]="avatarSize">
  <img class="artist_avatar"
       imageLoadAnimation
       [alt]="altText"
       [ngSrc]="avatarUrl | customImgUrl: { width: imageSize }"
       width="400"
       height="400"
       [style.width.px]="avatarSize"
       [style.height.px]="avatarSize">
</div>
