import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";
import {NgClass} from "@angular/common";
import {SafeHtmlPipe} from "@pipes/safe-html.pipe";

@Component({
  selector: 'snack-bar',
  standalone: true,
  imports: [
    NgClass,
    SafeHtmlPipe
  ],
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
})
export class SnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
              private snackBarRef: MatSnackBarRef<SnackBarComponent>) {
  }

  dismiss() {
    this.snackBarRef.dismiss();
  }
}
