<mat-dialog-content class="dialog-content">
  <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

  <div class="update_payment_modal" [ngClass]="{margin_error: paymentError.length}">
    <h5 class="title"> {{parentData.titleText ? parentData.titleText : 'PAYMENT INFORMATION'}}</h5>

    <payment-form (loading)="loadingInProgress($event)" (onPaymentError)="handlePaymentError($event)"
                  [confirmTextButton]="parentData.buttonText"
                  (onPaymentUpdate)="paymentUpdated($event)"></payment-form>

    <div class="not_now">
      <button class="btn not_now_button" (click)="closeModal()">
        Not Now
      </button>
    </div>

    <emusic-loader-spinner *ngIf="requestInProgress" [height]="'100%'" [enableOpacity]="true"></emusic-loader-spinner>
  </div>

  <modal-error-message *ngIf="paymentError.length" [errorMessages]="paymentError"></modal-error-message>
</mat-dialog-content>
