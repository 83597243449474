import { GetUserDetailsResponse, UserBenefits } from '@account/protocol/GetUserDetailsResponse';
import { GetAvailableRewardResponse } from '../../protocol/GetAvailableRewardResponse';
import { GetSubscriptionDetailsResponse } from '@services/protocol/Subscription';
import { LocationInfo } from '@account/protocol/location-response';
import { GetDeviceListResponse } from '@services/protocol/GetDeviceListResponse';

export class UserDetailsState extends GetUserDetailsResponse {
  location: LocationInfo | null = null;
  availableRewards: AvailableRewardState | null = null;
  hasCurrentRewardsProgram: boolean;
  subscriptionDetails: SubscriptionDetailsState | null = null;
  hasSubscription: boolean;
  eligibleForMembershipBonus: boolean;
  isSignedIn = false;
  firstFetchDone = false;
  creditBalance: string | null;
  isUserDelinquent: boolean;
  deviceDetails: DeviceDetailsState | null = null;
  lastRoute: string = '/';
  pathIntent: string | null = null;
  userFromUK: boolean;
  accessState: AccessState = new AccessState();
  override userBenefits: UserBenefits & { hasCloudStorageLeft: boolean };
  uploadLimitReached: boolean = false;
}

export class AccessState {
  accessError: string | null = null;
  accessInProgress: boolean = false;
  isFreemiumSignUp: boolean = false;
}

export class AvailableRewardState extends GetAvailableRewardResponse {
  maxClaimableRewardAmountInPeriod: string;
}

export class SubscriptionDetailsState extends GetSubscriptionDetailsResponse {
  isOnHold: boolean;
  isChosenPlanOpenAccessMMC: boolean;
}

export class DeviceDetailsState extends GetDeviceListResponse {
  userHasMobileApp: boolean;
}
