import { Component, inject, OnInit, Renderer2 } from '@angular/core';
import { UtmService } from '@account/utm.service';
import { GoogleAnalyticsService } from '@services/google-analytics.service';
import { DOCUMENT_TOKEN } from './core/tokens/browser.tokens';
import { detect } from 'detect-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  private readonly utmService = inject(UtmService);
  private readonly GA = inject(GoogleAnalyticsService);
  private readonly renderer = inject(Renderer2);
  private readonly document = inject(DOCUMENT_TOKEN);
  private readonly currentBrowser = detect();
  private readonly browserVersion = parseInt(this.currentBrowser?.version ?? '0', 10);
  /* Minimum supported browsers (Check .browserslistrc)
  Safari 14
  Firefox 80
  Chrome 84
  Opera 75
  iOS 14  */

  isSupportedBrowser = true;

  constructor() {
    if (this.currentBrowser && this.currentBrowser.type === 'browser') {
      switch (this.currentBrowser.name) {
        case 'safari':
          if (this.browserVersion < 14) {
            this.isSupportedBrowser = false;
          }

          break;
        case 'chrome':
          if (this.browserVersion < 84) {
            this.isSupportedBrowser = false;
          }

          break;
        case 'firefox':
          if (this.browserVersion < 80) {
            this.isSupportedBrowser = false;
          }

          break;
        case 'opera':
          if (this.browserVersion < 75) {
            this.isSupportedBrowser = false;
          }

          break;
        case 'ios':
          if (this.browserVersion < 14) {
            this.isSupportedBrowser = false;
          }

          break;
        case 'ios-webview':
          if (this.browserVersion < 14) {
            this.isSupportedBrowser = false;
          }

          break;
        default:
          this.isSupportedBrowser = true;
      }
    }
  }

  ngOnInit() {
    this.utmService.saveCurrentUtmQueryFromParams().subscribe();
    this.GA.trackPageView(location.href);
    this.setBrowserClass();
  }

  setBrowserClass(): void {
    let is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
    const is_firefox = navigator.userAgent.indexOf('Firefox') > -1;
    const is_opera = navigator.userAgent.toLowerCase().indexOf('op') > -1;
    let is_safari = navigator.userAgent.indexOf('Safari') > -1;
    let browser: string;

    if (is_chrome && is_safari) {
      is_safari = false;
    }

    if (is_chrome && is_opera) {
      is_chrome = false;
    }

    if (is_safari) {
      browser = 'safari';
    } else if (is_firefox) {
      browser = 'firefox';
    } else if (is_chrome) {
      browser = 'chrome';
    } else {
      browser = '';
    }

    const body = this.document.querySelector('body');
    this.renderer.addClass(body, browser);
  }
}
