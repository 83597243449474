import { inject, Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { NftService } from '@public-pages/nft/nft.service';
import { Store } from '@ngrx/store';
import { BillingDetailsActions } from './core/ngrx/actions/billing.actions';
import { UserActions } from './core/ngrx/actions/user.actions';
import { map, Observable, tap } from 'rxjs';
import { UserDetailsState } from '@files-ngrx/states/UserDetailsState';
import { filter } from 'rxjs/operators';
import { Web3Service } from '@services/web3.service';

@Injectable({
  providedIn: 'root'
})
export class AppResolver implements Resolve<boolean> {
  private readonly nftService = inject(NftService);
  private readonly store: Store<{ userDetails: UserDetailsState }> = inject(Store);
  private readonly web3Service = inject(Web3Service);

  resolve(): Observable<boolean> {
    return this.store.select('userDetails').pipe(
      tap((userDetailsState) => {
        if (!userDetailsState.firstFetchDone) {
          this.store.dispatch(UserActions.getUserDetails());
          this.store.dispatch(BillingDetailsActions.getPlanList({planFamilyList: ['ANNUAL', 'MONTHLY', 'QUARTERLY']}));
        }

        if (userDetailsState.successful && userDetailsState.message === 'SUCCESS') {
          // await this.accountService.handleAccessCode(route.queryParamMap.get('ac'));
          this.web3Service.checkForWeb3(userDetailsState.isSignedIn);
          this.nftService.startPendingTransactionPoll();

          // noinspection TypeScriptValidateJSTypes
          /*window['analytics'].identify(response.snid, {
            name: response.fullName,
            email: response.email
          });*/ //TODO Figure out how we do this in eMusic.com

        }
      }),
      // When we have response status it means that the user details request is completed
      filter((userDetails: UserDetailsState) => userDetails.firstFetchDone && !!userDetails.responseStatus),
      map(() => true));
  }
}
