import {createActionGroup, emptyProps, props} from "@ngrx/store";
import {GetPlanDetailsResponse, Promotion} from "../../protocol/GetPlanDetailsResponse";
import {GetSavedPaymentDetailsResponse} from "@services/protocol/GetSavedPaymentDetailsResponse";
import {RouteDetails} from "@files-ngrx/selectors/router.selectors";

export const BillingDetailsActions = createActionGroup({
  source: 'Billing Details',
  events: {
    // Reducer's Actions
    //| Plan List
    'Load Plan List': props<{ planDetailsResponse: GetPlanDetailsResponse, withPromoCode: boolean }>(),

    //| Promos
    'Set Active Promo': props<{ activePromoCode: string | null }>(),
    'Set Promo Map':props<{promoKey: string, promotion: Promotion | null, promoUrl: string | null, userSnid: string | null, variation: number | null}>(),
    'Clear Active Promo': emptyProps(),
    'Set Promo Bar Is Open': props<{ isOpen: boolean }>(),

    //| Payment Details
    'Set Saved Payment Details': props<GetSavedPaymentDetailsResponse>(),

    // Effects Actions
    'Get Plan List With Promotion': props<{promoCode: string, userSnid: string, variation: number, routeDetails: RouteDetails}>(),
    'Get Plan List': props<{ planFamilyList: string[], planName?: string, promoCode?: string }>(),
    'Get Saved Payment Details': emptyProps(),
  }
});
