import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogModule, DialogRef} from "@angular/cdk/dialog";
import {DialogCloseButtonComponent} from "@components/dialog/dialog-close-button/dialog-close-button.component";
import {BillingService} from "@services/billing.service";
import {GetPlanDetailsResponse, Plan} from "../../../../core/protocol/GetPlanDetailsResponse";
import {SnackBarService} from "@components/snack-bar/snack-bar.service";
import {CurrencyToSymbolPipe} from "@pipes/our-currency.pipe";
import {map, Observable} from "rxjs";
import {EmusicLoaderSpinnerComponent} from "@components/emusic-loader-spinner/emusic-loader-spinner.component";
import {SubscriptionService} from "@services/subscription.service";
import {Router} from "@angular/router";

@Component({
  selector: 'lite-plan-modal',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, CommonModule, DialogModule, DialogCloseButtonComponent, CurrencyToSymbolPipe, EmusicLoaderSpinnerComponent],
  templateUrl: './lite-plan-modal.component.html',
  styleUrls: ['./lite-plan-modal.component.scss']
})
export class LitePlanModalComponent implements OnInit {
  plan$!: Observable<Plan | null>;

  constructor(public dialogRef: DialogRef<boolean, LitePlanModalComponent>,
              private billingService: BillingService,
              private subscriptionService: SubscriptionService,
              private router: Router,
              private snackBarService: SnackBarService) { }

  ngOnInit(): void {
    this.plan$ = this.billingService.getPlainPlanList(['QUARTERLY'], 'Lite', null).pipe(
      map((res: GetPlanDetailsResponse) => {
        if (res.responseStatus !== 'SUCCESS') {
          this.snackBarService.showErrorNotificationBanner('Unable to find Lite plan', false, 3000);
          return null;
        }

        const plan = res.planList[0];
        plan.price = parseFloat(plan.price).toString(10);
        return plan;
      })
    );
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  cancelMembership(): void {
    this.subscriptionService.openCancelConfirmationModal();
    this.closeModal();
  }

  selectLitePlan(planName: string, planPricingId: number): void {
    this.closeModal();
    this.router.navigate(['/plans/confirmation/', planName, planPricingId]);
  }
}
