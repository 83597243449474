import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  ga = (window as any)['ga'];

  constructor() {
  }

  trackPageView(url: string) {

    if (!this.ga) {
      return;
    }
    this.ga('set', 'page', url);
    this.ga('send', 'pageview');
  }

  trackEvent(category: string, action: string, label: string, value: any) {
    if (!this.ga) {
      return;
    }

    this.ga('send', 'event', category, action, label, value);
  }
}
