<div id="get__apps__modal" class="modal-container mobile-bottom-position">
  <dialog-close-button [dialogRef]="dialogRef" color="black"></dialog-close-button>

  <div class="modal-content">
    <h2 class="modal-header-title">YOUR MUSIC ON THE GO</h2>

    <div class="wrapper">
      <div class="qr-code">
        <div class="qr">
          <div class="qr-content">
            <h3>Get The App</h3>

            <img src="assets/images/on_boarding/qr-code.svg" alt="QR Code"/>

            <p>Scan this QR code to download the app</p>
          </div>
        </div>
      </div>

      <div class="get-apps-urls">
        <div class="body-wrapper">
          <div class="app-container">
            <a target="_blank"
               href="https://itunes.apple.com/us/app/emusic/id1047229220?mt=8"
               aria-label="Go to eMusic app in the Apple App Store">
              <img src="assets/images/on_boarding/img-appstore-copy.svg" alt="Appstore"/>
            </a>

            <a target="_blank"
               href="https://play.google.com/store/apps/details?id=com.emusic.android"
               aria-label="Go to eMusic app in the Google Play Store">
              <img src="assets/images/on_boarding/img-play-store-copy@2x.png" alt="Play Store"/>
            </a>
          </div>
        </div>

        <button class="btn skip-button" (click)="closeGetAppsModal()">Skip for now</button>
      </div>
    </div>
  </div>
</div>
