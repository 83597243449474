<div id="ready__to__help" class="modal-container">
  <div class="modal-content">
    <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

    <div class="modal-body">
      <h2 class="modal-header-title">We Are Ready To Help!</h2>

      <p id="field-label">Please fill out the form below and our support team will reach out shortly.</p>

      <form [formGroup]="formGroup">
        <div class="form-group">
          <st-select labeledBy="field-label" formControlName="issue" [options]="issues" propertyToShow="name"
                     selectionValueProperty="name"></st-select>
        </div>

        <div class="form-group">
          <textarea aria-labelledby="field-label" formControlName="comments" class="further-comments"
                    placeholder="Further comments">
          </textarea>
        </div>

      </form>

      <div class="buttons-container mobile-horizontal">
        <button type="button" (click)="back()" class="btn new-secondary cancel">
          Back
        </button>

        <button type="button" [disabled]="requestInProgress" class="btn red-button confirm" (click)="submitForm()">
          Submit Form
        </button>
      </div>
    </div>
  </div>
</div>
