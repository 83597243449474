import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SeoService } from '@services/seo.service';

@Component({
  selector: 'unsupported-browser',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './unsupported-browser.component.html',
  styleUrls: ['./unsupported-browser.component.scss']
})
export class UnsupportedBrowserComponent {
  private readonly seoService = inject(SeoService);

  constructor() {
    this.seoService.setDefaultMetaTags(`We're sorry, your browser is not supported`);
  }
}
