import {GeneralResponse} from "./GeneralResponse";
import {PlanFamily, SubscriptionPlanBenefits, TimeResolutionType} from "@services/protocol/Subscription";

export class GetPlanDetailsResponse extends GeneralResponse {
  defaultAnnualPlanId: number;
  defaultMonthlyPlanId: number;
  eligibleForMembershipBonus: boolean;
  promotionDetailsResponse: GetPromotionDetailsResponse;
  planList: Plan[];
}

export class GetBoosterListResponse extends GeneralResponse {
  boosterPromotionEnabled: boolean;
  planList: (Plan & {_price: number, _creditAmount: number})[];
}

export interface GetPlanDetailsResponseProps {
  planFamilyList: string[] | null;
  planName?: string | null;
  promoCode?: string | null;
}

export class Plan {
  billingCycleUnitAmount: number;
  billingCycleUnitType: TimeResolutionType;
  creditAmount: string;
  creditBased: boolean;
  currency: string;
  description: string;
  family: PlanFamily;
  hidden: boolean;
  name: string;
  planId: number;
  planPricingId: number;
  price: string;
  benefits: Benefits | SubscriptionPlanBenefits;
}

export interface Benefits {
  bonusAmount: number | null;
  boosterAllowed: boolean;
  cloudTrackLimit: number | null;
  cloudUnlimited: boolean;
  concurrentWebSessionLimit: number;
  deviceLimit: number;
  estimatedReleasesPerMonth: number;
  estimatedTracksPerMonth: number;
  numberOfFreeReleases: number;
  pitch: any | null;
  pricingType: string;
  showAds: boolean;
  type: string;
  membershipBonus: MembershipBonus;
}

export interface MembershipBonus {
  amount: string;
  numberOfTracks: number;
  type: string;
}

export interface GetPromotionDetailsResponse extends GeneralResponse {
  promotion: Promotion;
}

export interface Promotion {
  promoCode: string;
  name: string;
  type: PromotionType;
  promotionDetails: ExtendedSignupBonusPromoDetails | FreeMonthsPromoDetails;
  planIdList: number[];
}

export interface ExtendedSignupBonusPromoDetails {
  signupBonusAmount: number;
  ignoreOriginalSignupBonus: boolean;
  creditCycles: number;
  keepFutureBonusOnFreeze: boolean;
}

export interface FreeMonthsPromoDetails {
  freeMonths: number;
}

export enum PromotionType {
  FREE_MONTHS = 'FREE_MONTHS',
  DOUBLE_SIGN_UP_BONUS = 'DOUBLE_SIGN_UP_BONUS',
  EXTENDED_SIGN_UP_BONUS = 'EXTENDED_SIGN_UP_BONUS',
}
