<mat-dialog-content class="dialog-content">
  <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

  <div class="fiat_verification_modal" [ngClass]="{error_margin: paymentError.length}">
    <h4 class="title">Verify your Payment Information</h4>

    <div class="modal_body">
      Please insert a payment method before bidding. You will not be charged at this point. Upon winning your card
      will automatically be charged the winning amount. For more information check our
      <a href="https://www.emusic.com/terms" target="_blank">terms</a> and
      <a href="https://emusic.zendesk.com/hc/en-us/sections/1500002519862-eMusic-NFTs" target="_blank">FAQs</a>
    </div>

    <payment-form origin="auctionBid"
                  (loading)="handleOnLoading($event)"
                  confirmTextButton="Confirm Payment Information"
                  (onPaymentError)="handleOnPaymentError($event)"
                  (onPaymentUpdate)="handleOnPaymentUpdate()"></payment-form>

  <emusic-loader-spinner *ngIf="requestInProgress"
                         height="100%"
                         [enableOpacity]="true"></emusic-loader-spinner>
  </div>

  <modal-error-message *ngIf="paymentError.length" [errorMessages]="paymentError"></modal-error-message>
</mat-dialog-content>
