import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { GetCollectibleLiteDetailsResponse } from '../../../protocol/GetCollectibleDetailsResponse';
import { PurchaseStep, PurchaseType } from '../../../protocol/PurchaseFlow';
import { BillingService } from '@services/billing.service';
import { NftService } from '../../../nft.service';
import { DialogCloseButtonComponent } from '@components/dialog/dialog-close-button/dialog-close-button.component';
import { AsyncPipe, NgClass, NgIf, NgOptimizedImage, NgSwitch, NgSwitchCase } from '@angular/common';
import { NftSellerComponent } from '@public-pages/nft/nft-details/nft-seller/nft-seller.component';
import { ImageLoadAnimationDirective } from '@directives/image-load-animation.directive';
import { BgImagePipe, CustomImgUrlPipe } from '@pipes/bg-image.pipe';
import {
  CurrentFiatPaymentMethodComponent
} from '@components/current-fiat-payment-method/current-fiat-payment-method.component';
import { TruncateWalletAddressPipe } from '@pipes/truncate-wallet-address.pipe';
import { EmusicLoaderSpinnerComponent } from '@components/emusic-loader-spinner/emusic-loader-spinner.component';
import { ModalErrorMessageComponent } from '@components/dialog/modal-error-message/modal-error-message.component';
import { NftPricePipe } from '@pipes/nft-price.pipe';
import { LetModule } from '@ngrx/component';
import { TokenWalletStateComponent } from '@components/token-wallet-state/token-wallet-state.component';
import { FakeButtonEventHandlerDirective } from '@directives/fake-button-event-handler.directive';
import {
  NftPurchaseModalStore
} from '@public-pages/nft/nft-details/modals/nft-purchase-modal/store/nft-purchase-modal.store';
import {
  NftPurchaseModalViewModel
} from '@public-pages/nft/nft-details/modals/nft-purchase-modal/store/nft-purchase-modal.state';

interface ParentData {
  nftDetails: GetCollectibleLiteDetailsResponse;
  purchaseStep?: PurchaseStep;
  purchaseType?: PurchaseType;
}

@Component({
  selector: 'nft-purchase-modal',
  standalone: true,
  providers: [NftPurchaseModalStore],
  imports: [
    DialogCloseButtonComponent,
    NgClass,
    NftSellerComponent,
    ImageLoadAnimationDirective,
    MatDialogModule,
    BgImagePipe,
    NgSwitch,
    NftPricePipe,
    NgSwitchCase,
    CurrentFiatPaymentMethodComponent,
    NgIf,
    TruncateWalletAddressPipe,
    AsyncPipe,
    EmusicLoaderSpinnerComponent,
    ModalErrorMessageComponent,
    CustomImgUrlPipe,
    LetModule,
    NgOptimizedImage,
    TokenWalletStateComponent,
    FakeButtonEventHandlerDirective
  ],
  templateUrl: 'nft-purchase-modal.component.html',
  styleUrls: ['nft-purchase-modal.component.scss'],
})
export class NftPurchaseModalComponent {
  private readonly nftService = inject(NftService);
  private readonly billingService = inject(BillingService);
  private readonly nftPurchaseModalStore = inject(NftPurchaseModalStore);
  protected readonly PurchaseStep = PurchaseStep;
  protected readonly vm$ = this.nftPurchaseModalStore.selectViewModel;

  dialogRef: MatDialogRef<NftPurchaseModalComponent> = inject(MatDialogRef<NftPurchaseModalComponent>);
  parentData: ParentData = inject(MAT_DIALOG_DATA);

  constructor() {
    this.billingService.updateSavedPaymentDetails();
    const purchasePending = this.nftService.getPendingNftPurchaseMap(this.parentData.nftDetails.collectible.id);

    this.nftPurchaseModalStore.initializeStore({
      ...this.parentData,
      purchasePending,
    }, this.dialogRef);
  }

  selectCurrentStep(purchaseStep: PurchaseStep | undefined, nftDetails: GetCollectibleLiteDetailsResponse, purchaseType: PurchaseType | undefined): void {
    if (!purchaseStep || !nftDetails || !purchaseType) {
      return;
    }

    this.nftPurchaseModalStore.selectCurrentStepFx({purchaseStep, purchaseType, nftDetails});
  }

  purchaseCollectible(vm: NftPurchaseModalViewModel, nftDetails: GetCollectibleLiteDetailsResponse): void {
    if (!vm.paymentDetails) {
      return;
    }

    this.nftPurchaseModalStore.purchaseCollectibleFx({vm, nftDetails});
  }

  refreshPurchaseAddress(nftDetails: GetCollectibleLiteDetailsResponse): void {
    this.nftPurchaseModalStore.refreshPurchaseAddressFx(nftDetails);
  }

  sendEthTransaction(vm: NftPurchaseModalViewModel, nftDetails: GetCollectibleLiteDetailsResponse): void {
    this.nftPurchaseModalStore.sendEthTransactionFx({vm, nftDetails});
  }
}
