import {createReducer, on} from "@ngrx/store";
import {BillingState, PromotionMap} from "../states/BillingState";
import {BillingDetailsActions} from "../actions/billing.actions";
import {ExtraCreditsMembershipBonus} from "@services/protocol/Subscription";
import {MembershipBonus, Plan} from "../../protocol/GetPlanDetailsResponse";
import {MapType} from "../../types/types";

export const initialState: BillingState = new BillingState();

export const billingReducers = createReducer(initialState,
  // Plan List
  on(BillingDetailsActions.loadPlanList, (state, {planDetailsResponse, withPromoCode}) => {
    const plan = planDetailsResponse.planList[0];
    const signUpBonusAmountForFirstPlan = plan && plan.benefits ? (plan.benefits.membershipBonus as ExtraCreditsMembershipBonus | MembershipBonus).amount + '' : '';
    const signUpBonusCurrencyForFirstPlan = plan ? plan.currency : '';
    let promoPlanList: Plan[] = [];

    if (withPromoCode) {
      promoPlanList = planDetailsResponse.planList;
    }

    return {
      ...state,
      planDetails: {
        ...planDetailsResponse,
        signUpBonusAmountForFirstPlan,
        signUpBonusCurrencyForFirstPlan,
        promoPlanList
      },
    };
  }),

  // Promos
  on(BillingDetailsActions.clearActivePromo, (state: BillingState) => {
    const promotionsMap: MapType<PromotionMap | null> = {...state.promoDetails.promotionsMap};
    const activePromo = state.promoDetails.activePromo;

    if (activePromo) {
      promotionsMap[activePromo] = null;
    }

    return {
      ...state,
      promoDetails: {...state.promoDetails, promotionsMap},
    };
  }),

  on(BillingDetailsActions.setPromoBarIsOpen, (state, {isOpen}) => ({...state, promoBarIsOpen: isOpen})),

  on(BillingDetailsActions.setActivePromo, (state, {activePromoCode}) => ({
    ...state,
    promoDetails: {...state.promoDetails, promoCode: activePromoCode}
  })),

  on(BillingDetailsActions.setPromoMap, (state, {promoKey, promotion, promoUrl, variation, userSnid}) => {
    const promotionsMap = {...state.promoDetails.promotionsMap};

    promotionsMap[promoKey] = {
      promotion,
      promoUrl,
      userSnid,
      variation
    };

    return {...state, promoDetails: {...state.promoDetails, promotionsMap}};
  }),

  // Payment Details
  on(BillingDetailsActions.setSavedPaymentDetails, (state, savedPaymentDetails) => {
    const isPaymentMethodCC = savedPaymentDetails.paymentMethod !== 'PAYPAL';
    const noPaymentMethodSaved = savedPaymentDetails.paymentMethod === null;
    const isPaymentMethodPaypal = savedPaymentDetails.paymentMethod === 'PAYPAL';
    const paymentMethodString = isPaymentMethodPaypal ? 'PayPal: ' + savedPaymentDetails.paypalEmail : savedPaymentDetails.creditCardDetails?.maskedNumber;

    return {
      ...state,
      savedPaymentDetails: {
        ...savedPaymentDetails,
        isPaymentMethodCC,
        noPaymentMethodSaved,
        isPaymentMethodPaypal,
        paymentMethodString
      }
    };
  }),
);
