// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: "https://qa.emusic.com/eMusic/rest/",
  googleClientId: '530578558121-llrfhp3bt8dtsgneoha3keanfnkfjelp.apps.googleusercontent.com',
  googleAppPackageId: 'com.emusic',
  googleScope: 'email profile',
  chromecastReceiverAppId: '1CF4F4E9',
  appleClientId: 'com.emusic.app',
  appleScopes: 'email name',
  webUrl: "https://qa.emusic.com",
  cstURL: "https://qa.cst.triplay.com",
  emusicWebUrl: "https://qa.emusic.com",
  turnstileSiteKey: '0x4AAAAAAAW4rzLOoqkLLdnC',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
