import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'st-input',
  standalone: true,
  imports: [CommonModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: StandardInputComponent,
      multi: true,
    }
  ],
  templateUrl: './standard-input.component.html',
  styleUrls: ['./standard-input.component.scss']
})
export class StandardInputComponent implements ControlValueAccessor {
  @Input()
  fieldName!: string;

  @Input()
  forceToShowErrorMessageWithNoValue = false;

  @Input()
  type = 'text';

  @Input()
  errorText!: string | undefined | null;

  @Input()
  markAsInvalid = false;

  @Input()
  maxlength: number;

  @Input()
  tabIndex = 0;

  @Input()
  autoComplete: string | 'off' | 'on' = 'on';

  @Input()
  errorMessageId: string | null = null;

  fakeErrorMessageId = new Date().getDate() + Math.random();
  fakeId = new Date().getDate() + Math.random();
  value = null;
  isDisabled = false;

  onChange = (value: any) => {
  };
  onTouch = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
