<mat-dialog-content class="dialog-content">
  <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

  <div class="transaction-in-progress-modal">
    <h5 class="title"> Transaction In Progress</h5>

    <p>
      Your transaction is being processed. Stay tuned - we will notify you when your NFT has been added to your account
    </p>

    <button class="btn red-button full-width nft_button" (click)="closeModal()">OK</button>

  </div>
</mat-dialog-content>
