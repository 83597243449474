import { Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { CollectibleToken } from "../../../../protocol/CollectibleToken";
import { SnackBarService } from "@components/snack-bar/snack-bar.service";
import { TokenWallet } from "@services/protocol/TokenWallet";
import { NftService } from "../../../../nft.service";
import { WithdrawStage } from "./protocol/WithdrawStage";
import { DialogCloseButtonComponent } from "@components/dialog/dialog-close-button/dialog-close-button.component";
import { NgClass, NgIf, NgOptimizedImage, NgSwitch, NgSwitchCase } from "@angular/common";
import { NftSellerComponent } from "@public-pages/nft/nft-details/nft-seller/nft-seller.component";
import { CustomImgUrlPipe } from '@pipes/bg-image.pipe';
import { Store } from "@ngrx/store";
import {
  selectHasConnectedWallet,
  selectNotConnectedWallet,
  selectWallet,
} from "@files-ngrx/selectors/web3.selectors";
import { LetModule } from "@ngrx/component";
import { combineLatestWith, map, Observable, tap } from "rxjs";
import { selectIsSignedInUser } from "@files-ngrx/selectors/user.selectors";
import { ImageLoadAnimationDirective } from "@directives/image-load-animation.directive";
import { TokenWalletStateComponent } from "@components/token-wallet-state/token-wallet-state.component";

@Component({
  selector: 'nft-withdraw-modal',
  standalone: true,
  imports: [
    DialogCloseButtonComponent,
    NgSwitch,
    NgSwitchCase,
    NftSellerComponent,
    NgClass,
    NgIf,
    MatDialogModule,
    CustomImgUrlPipe,
    LetModule,
    NgOptimizedImage,
    ImageLoadAnimationDirective,
    TokenWalletStateComponent
  ],
  templateUrl: './nft-withdraw-modal.component.html',
  styleUrls: ['./nft-withdraw-modal.component.scss']
})
export class NftWithdrawModalComponent {
  private readonly store = inject(Store);
  WithdrawStage = WithdrawStage;
  nftItem: CollectibleToken;
  withdrawStage = WithdrawStage.PRE_WITHDRAW;
  vm$ = this.getViewModel();

  constructor(@Inject(MAT_DIALOG_DATA) private data: {
                nftItem: CollectibleToken,
                stage: WithdrawStage
              },
              public dialogRef: MatDialogRef<NftWithdrawModalComponent>,
              private snackBarService: SnackBarService,
              private nftService: NftService) {
    this.nftItem = data.nftItem;
    this.withdrawStage = data.stage;
  }

  private getViewModel(): Observable<{
    wallet: TokenWallet | null,
    hasConnectedWallet: boolean,
    notConnectedWallet: string | null,
    isSignedIn: boolean
  }> {
    return this.store.select(selectWallet).pipe(
      combineLatestWith(
        this.store.select(selectHasConnectedWallet),
        this.store.select(selectNotConnectedWallet),
        this.store.select(selectIsSignedInUser)
      ),
      map(([wallet, hasConnectedWallet, notConnectedWallet, isSignedIn]) => ({
        wallet,
        hasConnectedWallet,
        notConnectedWallet,
        isSignedIn
      })),
    );
  }

  async withdrawNft() {
    try {
      const mintResponse = await this.nftService.withdrawNft(this.nftItem);
      if (mintResponse.successful) {
        this.snackBarService.showBlueNotificationBanner(`${this.nftItem.collectible.name} is being minted to your connected wallet`);
        this.withdrawStage = WithdrawStage.IN_PROGRESS;
        this.nftService.addCollectibleToMintingTransactionsArray([this.nftItem]);
        this.nftService.notifyRefreshOwedCollectibleList();
      } else {
        this.snackBarService.showErrorNotificationBanner('We have encountered an issue minting ' +
          this.nftItem.collectible.name + ' to your wallet. If this issue persists let us know at' +
          ' <a href="mailto:support@emusic.com">support@emusic.com</a>', true);
      }
    } catch (e) {
      this.snackBarService.showErrorNotificationBanner(e as string, false);
    }
  }

  addContractToWallet(): void {
    this.nftService.addContractToWallet(this.nftItem);
  }

  addEthereumChain() {
    this.nftService.addEthereumChain(this.nftItem);
  }

  copyContractAddressToClipboard(): void {
    this.nftService.copyContractAddressToClipboard(this.nftItem.collectible.contractAddress);
  }
}
