import { userReducers } from "./user.reducers";
import {billingReducers} from "./billing.reducers";
import {routerReducer} from "@ngrx/router-store";
import {Web3Reducers} from "@files-ngrx/reducers/web3.reducers";

export const indexReducers = {
  userDetails: userReducers,
  billingDetails: billingReducers,
  router: routerReducer,
  web3: Web3Reducers,
};
