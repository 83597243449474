import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms";
import { FakeButtonEventHandlerDirective } from "@directives/fake-button-event-handler.directive";

@Component({
  selector: 'radio-button',
  standalone: true,
  imports: [CommonModule, FormsModule, FakeButtonEventHandlerDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RadioButtonComponent,
      multi: true
    }
  ]
})
export class RadioButtonComponent implements ControlValueAccessor {
  @Input()
  value: number | string = '';

  @Input()
  bigOutline = false;

  @Input()
  set outlineColor(color: 'GRAY' | 'BLACK' | 'WHITE') {
    switch (color) {
      case "BLACK":
        this._outlineColor = '#262626';
        break;
      case 'WHITE':
        this._outlineColor = '#FFFFFF';
        break;
      case "GRAY":
      default:
        this._outlineColor = '#929292';
    }
  }

  @Input()
  set color(color: 'RED' | 'GOLDEN' | 'BLACK' | 'WHITE') {
    switch (color) {
      case "BLACK":
        this._color = '#000000';
        break;
      case "GOLDEN":
        this._color = '#887242';
        break;
      case "RED":
        this._color = '#ef3e28';
        break;
      case 'WHITE':
        this._color = '#FFFFFF';
        break;
      default:
        this._color = '#ef3e28';
        break;
    }
  }

  _color = '#ef3e28';
  _outlineColor = '#929292';

  finalValue: any = null;
  isDisabled = false;

  id = new Date().getTime() + Math.random();

  onChange = (evt: any) => {
  };
  onTouch = (evt: any) => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.finalValue = obj;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  changeValue(event: any): void {
    if (this.isDisabled) {
      return;
    }

    this.onChange(event);
  }
}
