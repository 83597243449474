import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogModule, DialogRef} from "@angular/cdk/dialog";
import {DialogCloseButtonComponent} from "@components/dialog/dialog-close-button/dialog-close-button.component";
import {Observable} from "rxjs";
import {UserDetailsState} from "@files-ngrx/states/UserDetailsState";
import {Store} from "@ngrx/store";
import {VarDirective} from "@directives/ng-var.directive";
import {UserActions} from "@files-ngrx/actions/user.actions";
import {SubscriptionService} from "@services/subscription.service";
import {take} from "rxjs/operators";

@Component({
  selector: 'cancel-confirmation-modal',
  standalone: true,
  imports: [CommonModule, DialogModule, DialogCloseButtonComponent, VarDirective],
  templateUrl: './cancel-confirmation-modal.component.html',
  styleUrls: ['./cancel-confirmation-modal.component.scss']
})
export class CancelConfirmationModalComponent {
  userDetailsState$!: Observable<UserDetailsState>;

  constructor(public dialogRef: DialogRef<boolean, CancelConfirmationModalComponent>,
              private subscriptionService: SubscriptionService,
              private store: Store<{ userDetails: UserDetailsState }>) {
    this.userDetailsState$ = store.select('userDetails');

    store.select('userDetails').pipe(take(1)).subscribe(({subscriptionDetails}: UserDetailsState) => {
      if (subscriptionDetails && subscriptionDetails.subscription && !subscriptionDetails.subscription.upcomingCancellationDate) {
        subscriptionService.cancelSubscriptionLater(subscriptionDetails.subscription.id, 'VOLUNTARY_USER_CANCEL').subscribe(((res) => {
          if (res.responseStatus === 'SUCCESS') {
            store.dispatch(UserActions.getSubscriptionDetails());
          }
        }));
      }
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
