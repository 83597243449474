<mat-dialog-content class="dialog-content" *ngrxLet="vm$ as vm">
  <dialog-close-button [dialogRef]="dialogRef"></dialog-close-button>

  <div class="nft_purchase_modal"
       [ngClass]="{'reset_padding_bottom': vm.currentPurchaseStep === PurchaseStep.FIAT_PURCHASE, 'margin_error': vm.errorMessagesArray.length}">
    <h5 class="title">
      {{parentData.purchaseStep !== PurchaseStep.SELECTION ? 'SELECT PAYMENT TYPE' : 'PURCHASE CONFIRMATION'}}
    </h5>

    <div class="nft_name dotted">{{parentData.nftDetails.collectible.name}}</div>

    <nft-seller [artistName]="parentData && parentData.nftDetails.artist.name"
                [artistImage]="parentData && parentData.nftDetails.artist.imageUrl">
    </nft-seller>

    <div class="nft_image">
      <img imageLoadAnimation class="cover" [alt]="parentData.nftDetails.collectible.imageList[0].altText"
           height="217"
           width="217"
           [ngSrc]="parentData.nftDetails.collectible.imageList[0].imageUrl | customImgUrl: {format: 'GIF'}"/>
    </div>

    <ng-container [ngSwitch]="vm.currentPurchaseStep">
      <div class="payment_selection" *ngSwitchCase="PurchaseStep.SELECTION">
        <button class="btn red-button nft_button primary_button"
                (click)="selectCurrentStep(PurchaseStep.FIAT_PURCHASE, parentData.nftDetails, parentData.purchaseType)">
          Buy For {{parentData.nftDetails.collectible.pricingMap | nftPrice:'FIAT':vm.location?.currency}}
        </button>

        <button class="btn red-button nft_button primary_button"
                (click)="selectCurrentStep(PurchaseStep.CRYPTO_PURCHASE, parentData.nftDetails, parentData.purchaseType)">
          Buy For {{parentData.nftDetails.collectible.pricingMap | nftPrice:'CRYPTO'}}
        </button>

        <div class="nft_notice">Requires Metamask to redeem.
          <a class="learn_more" target="_blank"
             href="https://emusic.zendesk.com/hc/en-us/articles/4403723414035">Learn more</a>
        </div>
      </div>

      <div class="fiat_purchase" *ngSwitchCase="PurchaseStep.FIAT_PURCHASE">
        <current-fiat-payment-method
          [price]="parentData.nftDetails.collectible.pricingMap | nftPrice:'FIAT':vm.location?.currency">
        </current-fiat-payment-method>

        <button class="btn red-button nft_button primary_button"
                [disabled]="!vm.paymentDetails"
                (click)="purchaseCollectible(vm, parentData.nftDetails)">
          Buy For {{parentData.nftDetails.collectible.pricingMap | nftPrice:'FIAT':vm.location?.currency}}
        </button>
      </div>

      <div class="crypto_purchase" *ngSwitchCase="PurchaseStep.CRYPTO_PURCHASE">
        <div class="crypto_price_wrapper" *ngIf="!vm.cryptoCountdownZeroed && vm.purchaseAddressDetails?.address">
          <div class="eth_symbol"></div>

          <div class="price_details_wrapper">
            <div class="blockchain_name">Ethereum(ETH)</div>

            <div class="crypto_price">{{vm.finalNftCryptoPrice}}
              ETH
            </div>
          </div>

          <div class="timer_wrapper">
            <div class="clock_icon"></div>

            <div class="countdown" *ngIf="vm.countdown">
              {{vm.countdown.m}}:{{vm.countdown.s}}
            </div>
          </div>

        </div>

        <div class="crypto_price_wrapper" *ngIf="vm.cryptoCountdownZeroed">
          <div class="price_details_refresh">
            This price is no longer available. Click refresh to see an updated price.
          </div>

          <div class="timer_wrapper refresh" fakeButton (click)="refreshPurchaseAddress(parentData.nftDetails)">
            <div class="refresh_icon"></div>

            <div class="refresh_text">
              Refresh
            </div>
          </div>
        </div>

        <hr class="divider"/>

        <!--No Connected Wallet on server State-->
        <token-wallet-state *ngIf="vm.notConnectedWallet"
                            template="NOT-CONNECTED"
                            [removeBorders]="true"
                            [notConnectedWalletAddress]="vm.notConnectedWallet"></token-wallet-state>

        <!--Connected Wallet State-->
        <token-wallet-state *ngIf="vm.wallet"
                            template="WALLET"
                            [removeBorders]="true"
                            [tokenWallet]="vm.wallet"></token-wallet-state>

        <hr class="divider"/>

        <p class="crypto_purchase_text">
          Make sure you send enough to cover the transaction fees! The transaction fee does not go to eMusic.
          <a href="https://emusic.zendesk.com/hc/en-us/sections/1500002519862-eMusic-NFTs" target="_blank">Learn
            More</a>
        </p>

        <button class="btn red-button nft_button primary_button"
                (click)="!(vm.cryptoCountdownZeroed || vm.notConnectedWallet) && sendEthTransaction(vm, parentData.nftDetails)"
                [disabled]="vm.notConnectedWallet || vm.cryptoCountdownZeroed || vm.purchasePending">
          {{vm.purchasePending ? 'Pending...' : 'Buy For ' + vm.finalNftCryptoPrice + 'ETH'}}
        </button>
      </div>
    </ng-container>

    <emusic-loader-spinner *ngIf="vm.requestInProgress" [height]="'100%'"
                           [enableOpacity]="true"></emusic-loader-spinner>
  </div>

  <modal-error-message *ngIf="vm.errorMessagesArray.length"
                       [errorMessages]="vm.errorMessagesArray"></modal-error-message>
</mat-dialog-content>
