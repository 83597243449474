import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ArtistAvatarComponent } from "@components/artist-avatar/artist-avatar.component";

@Component({
  selector: 'nft-seller',
  standalone: true,
  imports: [ArtistAvatarComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './nft-seller.component.html',
  styleUrls: ['./nft-seller.component.scss']
})
export class NftSellerComponent {
  @Input()
  artistName = '';

  @Input()
  artistImage = '';
}
