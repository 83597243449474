export enum DeviceType {
  IPAD = 'IPAD',
  IPHONE = 'IPHONE',
  IPOD = 'IPOD',
  ANDROID = 'ANDROID',
  ANDROID_TABLET = 'ANDROID_TABLET',
  WINDOWS_PHONE = 'WINDOWS_PHONE',
  WINDOWS_TABLET = 'WINDOWS_TABLET',
  BLACKBERRY = 'BLACKBERRY',
  WINDOWS_SYNC_AGENT = 'WINDOWS_SYNC_AGENT',
  MAC_SYNC_AGENT = 'MAC_SYNC_AGENT',
  ROKU = 'ROKU',
  APPLE_TV = 'APPLE_TV',
  SAMSUNG_TV = 'SAMSUNG_TV',
  LG_TV = 'LG_TV',
  PANASONIC_TV = 'PANASONIC_TV',
  SONY_TV = 'SONY_TV',
  VIZIO_TV = 'VIZIO_TV',
  OPERA_TV = 'OPERA_TV',
  J2ME = 'J2ME',
  WEB = 'WEB',
  WINDOWS_STORE = 'WINDOWS_STORE',
  ALEXA = 'ALEXA'
}
