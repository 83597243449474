import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { AccountService } from '@account/account.service';

@Component({
  selector: 'welcome-back-modal',
  standalone: true,
  templateUrl: './welcome-back-modal.component.html',
  styleUrls: ['./welcome-back-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeBackModalComponent {
  private readonly dialogRef = inject(DialogRef);
  private readonly accountService = inject(AccountService);

  agreeToTerms(): void {
    this.accountService.agreeToNewTerms().subscribe(response => {
      if (response && response.responseStatus === 'SUCCESS') {
        this.dialogRef.close();
      }
    });
  }
}
