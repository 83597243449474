import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Web3Actions } from '@files-ngrx/actions/web3.actions';
import { combineLatestWith, EMPTY, from, interval, map, of, skip, switchMap, takeWhile, tap } from 'rxjs';
import { Web3Service } from '@services/web3.service';
import { SnackBarService } from '@components/snack-bar/snack-bar.service';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { NftService } from '@public-pages/nft/nft.service';
import { PendingNftTransaction } from '@public-pages/nft/protocol/GetCollectibleMintingTransactionListResponse';
import { selectIsSignedInUser } from '@files-ngrx/selectors/user.selectors';

@Injectable()
export class Web3Effects {
  private readonly web3Service = inject(Web3Service);
  private readonly actions$ = inject(Actions);
  private readonly snackBarService = inject(SnackBarService);
  private readonly nftService = inject(NftService);
  private readonly store = inject(Store);

  readonly refreshWalletList = createEffect(() => this.actions$.pipe(
    ofType(Web3Actions.refreshWalletList),
    switchMap(({ethInWindow}) => this.web3Service.getWalletList().pipe(
      map(response => {
        if (!response.successful) {
          this.web3Service.broadCastWalletError({reasonCode: response.responseText});
        }

        if (!!ethInWindow) {
          this.web3Service.assignWallet();
        }

        return Web3Actions.setTokenWalletList({response});
      }),
    )),
  ));

  readonly broadcastWalletError = createEffect(() => this.actions$.pipe(
    ofType(Web3Actions.broadcastWalletError),
    map(({error}) => {
      return Web3Actions.setWalletError(error);
    })
  ));

  readonly assignWallet = createEffect(() => this.actions$.pipe(
    ofType(Web3Actions.assignWallet),
    switchMap(() => from(this.web3Service.web3Instance().eth.requestAccounts())),
    map((accounts: string[]) => {
      return Web3Actions.setWallet({accounts});
    }),
  ));

  readonly disconnectWallet = createEffect(() => this.actions$.pipe(
    ofType(Web3Actions.disconnectWallet),
    switchMap(({walletId}) => this.web3Service.disconnectWalletServerCall(walletId).pipe(
      tap(response => {
        if (!response.successful) {
          this.web3Service.broadCastWalletError({reasonCode: response.responseText});
        }

        this.web3Service.refreshWalletList();
        this.web3Service.clearWallet();
        this.web3Service.setHasConnectedWallet(false);
        // $rootScope.$broadcast('refreshTokenStates');
      }),
    )),
  ), {dispatch: false});

  readonly initiateSignature = createEffect(() => this.actions$.pipe(
    ofType(Web3Actions.initiateSignature),
    switchMap(({connectedAccount}) => {
      if (!connectedAccount) {
        return from(this.web3Service.web3Instance().eth.requestAccounts()).pipe(map(accounts => accounts[0] ?? null));
      }

      return of(connectedAccount);
    }),
    switchMap((connectedAccount: string | null) => {
      if (!connectedAccount) {
        return EMPTY;
      }

      this.web3Service.setDefaultAccount(connectedAccount);

      return this.web3Service.initiateWalletSignature().pipe(
        switchMap(response => {
          try {
            if (!response.successful) {
              this.web3Service.broadCastWalletError({reasonCode: response.responseText});

              return EMPTY;
            }

            return this.web3Service.web3PersonalSign(response.messageToSign, connectedAccount).pipe(
              switchMap(signedMessage => this.web3Service.web3PersonalEcRecover(response.messageToSign, signedMessage).pipe(
                switchMap((recoveredAddress) => {
                  if (recoveredAddress && recoveredAddress.toLowerCase() !== connectedAccount) {
                    throw Error('SIGNATURE_MISMATCH');
                  }

                  return this.web3Service.addWalletUsingSignature(signedMessage, connectedAccount, response.salt);
                }),
              )),
              tap(response => {
                if (!response.successful) {
                  this.web3Service.broadCastWalletError({reasonCode: response.responseText});
                  this.snackBarService.showErrorNotificationBanner(this.web3Service.getWalletError(response.responseText));
                  return;
                }


                this.web3Service.reportConnectedWallet();
                this.web3Service.checkForAlbumRedirect();
                this.web3Service.refreshWalletList();
              }),
            );

          } catch (e: any) {
            this.web3Service.broadCastWalletError({reasonCode: e});
            throw e;
          }
        }),
      );
    })
  ), {dispatch: false});

  readonly checkWeb3ConnectionFx = createEffect(() => this.actions$.pipe(
    ofType(Web3Actions.checkWeb3Connection),
    switchMap(({
                 directConnect,
                 metamaskConnectionDetails
               }) => this.web3Service.checkWeb3ConnectionObs(directConnect, metamaskConnectionDetails))
  ), {dispatch: false});

  readonly addAddressToNftTransactionsFx = createEffect(() => this.actions$.pipe(
    ofType(Web3Actions.addAddressToNftTransactions),
    combineLatestWith(this.store.select(selectIsSignedInUser).pipe(skip(1))),
    takeWhile(([_, isSignedIn]) => isSignedIn),
    switchMap(([{pendingTransactions}, isSignedIn]) => {
      if (!pendingTransactions || pendingTransactions.length === 0 || !isSignedIn) {
        return EMPTY;
      }

      this.store.dispatch(Web3Actions.setNftTransactionList({transactionList: pendingTransactions}));

      this.nftService.updateLocalStorageNftTransactions(pendingTransactions);

      return interval(10000).pipe(
        take(50),
        switchMap(() => this.nftService.getNftPurchaseTransactionListRequest(pendingTransactions)),
        map(response => {
          if (!response.collectibleTransactionList.length) {
            return [];
          }

          response.collectibleTransactionList.forEach((transaction) => {
            if (transaction.status === 'PENDING') {
              return;
            }

            const foundAddress = !!pendingTransactions.find(i => i === transaction.address);

            if (foundAddress) {
              this.nftService.getCompletedNftTransactionMessaging(transaction);
            }
          });

          const pendingNftTransactions = this.removeNotPendingTransactions(response.collectibleTransactionList);
          this.nftService.updateLocalStorageNftTransactions(pendingNftTransactions.map(item => item.address));

          return pendingNftTransactions;
        }),
        takeWhile((notPendingTransactions: PendingNftTransaction[]) => notPendingTransactions.length > 0)
      );
    }),
  ), {dispatch: false});

  private removeNotPendingTransactions(arrayOfAddressesToRemove: PendingNftTransaction[]): PendingNftTransaction[] {
    if (arrayOfAddressesToRemove.length === 0) {
      return [];
    }

    return arrayOfAddressesToRemove.filter((item) => item.status === 'PENDING');
  }
}
